import React from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/

/*** ROUTER  ***/

/*** STYLE  ***/
import styled from 'styled-components';
/*** UI LIBS  ***/
import Button from '@material-ui/core/Button';
/*** ACTIONS & APIs ***/

/*** UTILS & HELPERS ***/
import { color } from '../../styles/style';

const BtnRed = styled(Button)`
  &&&& {
    background-color: ${color.danger};
    color: ${color.white};
    :hover {
      background-color: ${color.dangerDark};
    }
    :disabled {
      background-color: #cfdae6;
      color: #8595a6;
    }
  }
`;
const BtnReset = styled(Button)`
  &&&& {
    border-width: 1px;
    border-style: solid;
    border-color: ${color.dangerDark};
    background-color: ${color.white};
    color: ${color.darkest};
    :hover {
      color: ${color.dangerDark};
    }
  }
`;
const BtnDef = styled(Button)`
  &&&& {
    background-color: ${color.white};
    color: ${color.darkest};
  }
`;

const BtnPrimary = styled(Button)`
  &&&& {
    background-color: ${color.primary};
    color: ${color.white};
    :hover {
      background-color: ${color.primaryDarker};
    }
    :disabled {
      background-color: ${color.blueLightest};
      color: ${color.blueDarkest};
    }
  }
`;

const BtnSecondary = styled(Button)`
  &&&& {
    background-color: ${color.secondary};
    color: ${color.white};
    :hover {
      background-color: ${color.secondaryDarker};
    }
    :disabled {
      background-color: ${color.blueLightest};
      color: ${color.blueDarkest};
    }
  }
`;
const SimpleBtn = ({ type, ...other }) => {
  if (type === 'remove') {
    return <BtnRed {...other} />;
  }
  if (type === 'preview') {
    return <BtnDef {...other} />;
  }
  if (type === 'default') {
    return <BtnDef {...other} />;
  }
  if (type === 'primary') {
    return <BtnPrimary {...other} />;
  }
  if (type === 'secondary') {
    return <BtnSecondary {...other} />;
  }
  if (type === 'reset') {
    return <BtnReset {...other} />;
  }
  return <BtnDef {...other} />;
};

// <Btn
//   {...other}
//   variant="contained"
//   color="inherit"
//   classes={{ label: 'label' }}
// />

SimpleBtn.propTypes = {
  type: PropTypes.string,
};

export default SimpleBtn;
