import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import subjectTopics from '../../_CONS/subjectTopics';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 1000 - theme.spacing(2),
  },
  textField: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
});

const listSubjects = Object.keys(subjectTopics);
const topicObject = {
  topicCode: '',
  subTopicCode: '',
  subSubTopicCode: '',
  subSubSubTopicCode: '',
};
class SelectClassification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectCode: props.subjectCode ? props.subjectCode : '',
      subject: props.subjectCode ? subjectTopics[props.subjectCode].label : '',
      classification: props.classification,
      mainClassification: '',
      ...topicObject,
      topicCodes: [],
      subTopicCodes: [],
      subSubTopicCodes: [],
      subSubSubTopicCodes: [],
    };
  }
  componentDidMount() {
    const { subjectCode, classification } = this.props;

    if (classification === '') {
      const onlyTopicCodes = this.listTopics(subjectCode);
      this.setState({ classification: '', topicCodes: onlyTopicCodes });
      return;
    }

    const classificationArray = classification.split('-');

    if (classificationArray) {
      if (classificationArray.length === 4) {
        const topicCodes = this.listTopics(subjectCode);
        const topicCode = classificationArray[0];
        const subTopicCodes = this.listSubTopics(subjectCode, topicCode);

        const subTopicCode = classificationArray[1];
        const subSubTopicCodes = this.listSubSubTopics(
          subjectCode,
          topicCode,
          subTopicCode
        );

        const subSubTopicCode = classificationArray[2];
        const subSubSubTopicCodes = this.listSubSubSubTopics(
          subjectCode,
          topicCode,
          subTopicCode,
          subSubTopicCode
        );
        const subSubSubTopicCode = classificationArray[3];

        this.setState({
          topicCodes,
          topicCode,
          subTopicCodes,
          subTopicCode,
          subSubTopicCodes,
          subSubTopicCode,
          subSubSubTopicCodes,
          subSubSubTopicCode,
        });
      }
      if (classificationArray.length === 3) {
        const topicCodes = this.listTopics(subjectCode);

        const topicCode = classificationArray[0];
        const subTopicCodes = this.listSubTopics(subjectCode, topicCode);

        const subTopicCode = classificationArray[1];
        const subSubTopicCodes = this.listSubSubTopics(
          subjectCode,
          topicCode,
          subTopicCode
        );
        const subSubTopicCode = classificationArray[2];

        this.setState({
          topicCodes,
          topicCode,
          subTopicCodes,
          subTopicCode,
          subSubTopicCodes,
          subSubTopicCode,
        });
      }
      if (classificationArray.length === 2) {
        const topicCodes = this.listTopics(subjectCode);

        const topicCode = classificationArray[0];
        const subTopicCodes = this.listSubTopics(subjectCode, topicCode);

        const subTopicCode = classificationArray[1];

        this.setState({
          topicCodes,
          topicCode,
          subTopicCodes,
          subTopicCode,
        });
      }
      if (classificationArray.length === 1) {
        const topicCodes = this.listTopics(subjectCode);

        const topicCode = classificationArray[0];

        this.setState({
          topicCodes,
          topicCode,
        });
      }
    }
  }

  handleSubjectCodeChange = event => {
    const code = event.target.value;
    const label = subjectTopics[code].label;
    const topicCodes = this.listTopics(code);
    const classification = '';
    const uobj = {
      subjectCode: code,
      subject: label,
      classification,
    };
    this.props.handleUpdate(uobj);
    this.setState({
      subjectCode: event.target.value,
      subject: label,
      topicCodes: topicCodes,
      ...topicObject,
      classification: classification,
    });
  };
  listTopics(subjectCode) {
    const topics = subjectTopics[subjectCode].topics;
    const topicList = Object.keys(topics).map(topic => {
      return Object.assign({ key: topic, label: topics[topic].label }, {});
    });

    return topicList;
  }
  handleChangeTopicCode = event => {
    const { subjectCode, subject } = this.state;
    const newcode = event.target.value;
    const subTopicCodes = this.listSubTopics(subjectCode, newcode);
    const classification = newcode;
    const uobj = {
      subjectCode,
      subject,
      classification,
    };
    this.props.handleUpdate(uobj);

    this.setState({
      topicCode: newcode,
      classification: classification,
      subTopicCodes: subTopicCodes,
      subTopicCode: '',
      subSubTopicCode: '',
      subSubSubTopicCode: '',
    });
  };
  listSubTopics(subjectCode, topicCode) {

    const getSubtopics = subjectTopics[subjectCode].topics[topicCode];
    let subtopics;
    if (getSubtopics === undefined) {
      return [];
    } else {
      subtopics = subjectTopics[subjectCode].topics[topicCode].subtopics;
    }
    if (subtopics) {
      const subtopicsList = Object.keys(subtopics).map(topic => {
        return Object.assign({ key: topic, label: subtopics[topic].label }, {});
      });

      return subtopicsList;
    }
    return [];
  }
  handleChangeSubTopicCode = event => {
    const newcode = event.target.value;
    const { subjectCode, subject, topicCode } = this.state;

    const subSubTopicCodes = this.listSubSubTopics(
      subjectCode,
      topicCode,
      newcode
    );

    const classification = `${topicCode}-${newcode}`;

    const uobj = {
      subjectCode,
      subject,
      classification,
    };
    this.props.handleUpdate(uobj);
    this.setState({
      subTopicCode: newcode,
      classification: classification,
      subSubTopicCodes: subSubTopicCodes,
      subSubTopicCode: '',
      subSubSubTopicCode: '',
    });
  };

  listSubSubTopics(subjectCode, topicCode, subTopicCode) {

    const topicObj = subjectTopics[subjectCode].topics[topicCode];
    if (topicObj.hasOwnProperty('subtopics')) {
      const subTopicObj = topicObj.subtopics[subTopicCode];
      if (subTopicObj.hasOwnProperty('subsubtopics')) {
        const subSubTopics = subTopicObj.subsubtopics;
        const subsubtopicsList = Object.keys(subSubTopics).map(topic => {
          return Object.assign(
            { key: topic, label: subSubTopics[topic].label },
            {}
          );
        });
        return subsubtopicsList;
      }
    }
    return [];
  }
  handleChangeSubSubTopicCode = event => {
    const newcode = event.target.value;
    const { subjectCode, subject, topicCode, subTopicCode } = this.state;
    const subSubSubTopicCodes = this.listSubSubSubTopics(
      subjectCode,
      topicCode,
      subTopicCode,
      newcode
    );
    const classification = `${topicCode}-${subTopicCode}-${newcode}`;

    const uobj = {
      subjectCode,
      subject,
      classification,
    };
    this.props.handleUpdate(uobj);

    this.setState({
      subSubTopicCode: newcode,
      classification,
      subSubSubTopicCodes,
      subSubSubTopicCode: '',
    });
  };

  listSubSubSubTopics(subjectCode, topicCode, subTopicCode, subSubTopicCode) {
    const subTopicObject =
      subjectTopics[subjectCode].topics[topicCode].subtopics[subTopicCode];
    if (subTopicObject.hasOwnProperty('subsubtopics')) {
      const subsubtopicObj = subTopicObject.subsubtopics[subSubTopicCode];
      if (subsubtopicObj.hasOwnProperty('subsubsubtopics')) {
        const subsubsubtopics = subsubtopicObj.subsubsubtopics;
        const subsubsubtopicsList = Object.keys(subsubsubtopics).map(code => {
          return Object.assign(
            { key: code, label: subsubsubtopics[code].label },
            {}
          );
        });

        return subsubsubtopicsList;
      }
    }

    return [];
  }
  handleChangeSubSubSubTopicCode = event => {
    const newcode = event.target.value;
    const {
      subjectCode,
      subject,
      topicCode,
      subTopicCode,
      subSubTopicCode,
    } = this.state;
    const classification = `${topicCode}-${subTopicCode}-${subSubTopicCode}-${newcode}`;
    const uobj = {
      subjectCode,
      subject,
      classification,
    };
    this.props.handleUpdate(uobj);

    this.setState({ subSubSubTopicCode: event.target.value, classification });
  };

  render() {
    const { classes, type } = this.props;
    const {
      subjectCode,
      classification,
      subject,
      topicCode,
      subTopicCode,
      subSubTopicCode,
      subSubSubTopicCode,
      topicCodes,
      subTopicCodes,
      subSubTopicCodes,
      subSubSubTopicCodes,
    } = this.state;

    return (
      <>
        <Grid container justify='space-between' alignItems='center'>
          <Grid item>
            {type === 'study' ? (
              <FormControl className={classes.formControl}>
                <InputLabel shrink={true} htmlFor='show-subject-code'>
                  Subject Code
                </InputLabel>
                <TextField
                  id='show-subject-code'
                  value={subjectCode}
                  className={classes.textField}
                  margin='normal'
                  InputProps={{
                    readOnly: true,
                    shrink: 'true',
                  }}
                />
              </FormControl>
            ) : (
              <FormControl className={classes.formControl}>
                <InputLabel shrink={true} htmlFor='select-subject-code'>
                  Subject Code
                </InputLabel>
                <Select
                  value={subjectCode}
                  onChange={this.handleSubjectCodeChange}
                >
                  <MenuItem value='' disabled>
                    Select...
                  </MenuItem>
                  {listSubjects.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel shrink={true} htmlFor='show-subject'>
                Subject
              </InputLabel>
              <TextField
                id='show-subject'
                value={subject}
                className={classes.textField}
                margin='normal'
                InputProps={{
                  readOnly: true,
                  shrink: 'true',
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <InputLabel shrink={true} htmlFor='classification'>
                Classification
              </InputLabel>
              <TextField
                id='classification'
                value={classification}

                margin='normal'
                InputProps={{
                  readOnly: true,
                  shrink: 'true',
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justify='flex-start' alignItems='center'>
          <Grid item>
            {subjectCode && (
              <>
                {topicCodes.length > 0 && (
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor='select-multiple'>
                      Topic Code
                    </InputLabel>
                    <Select
                      value={this.state.topicCode}
                      onChange={this.handleChangeTopicCode}
                    >
                      <MenuItem value='' disabled>
                        Select...
                      </MenuItem>
                      {topicCodes.map(item => (
                        <MenuItem key={item.key} value={item.key}>
                          {`(${item.key}) ${item.label}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
          </Grid>
          <Grid item>
            {topicCode && (
              <>
                {subTopicCodes.length > 0 && (
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor='select-multiple'>
                      Sub Topic Code
                    </InputLabel>
                    <Select
                      value={this.state.subTopicCode}
                      onChange={this.handleChangeSubTopicCode}
                    >
                      <MenuItem value='' disabled>
                        Select...
                      </MenuItem>
                      {subTopicCodes.map(item => (
                        <MenuItem key={item.key} value={item.key}>
                          {`(${item.key}) ${item.label}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
          </Grid>
          <Grid item>
            {subTopicCode && (
              <>
                {subSubTopicCodes.length > 0 && (
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor='select-multiple'>
                      Sub Sub Topic Code
                    </InputLabel>
                    <Select
                      value={this.state.subSubTopicCode}
                      onChange={this.handleChangeSubSubTopicCode}
                    >
                      <MenuItem value='' disabled>
                        Select...
                      </MenuItem>
                      {subSubTopicCodes.map(item => (
                        <MenuItem key={item.key} value={item.key}>
                          {`(${item.key}) ${item.label}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
          </Grid>
          <Grid item>
            {subSubTopicCode && (
              <>
                {subSubSubTopicCodes.length > 0 && (
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor='select-multiple'>
                      Sub Sub Sub Topic Code
                    </InputLabel>
                    <Select
                      value={subSubSubTopicCode}
                      onChange={this.handleChangeSubSubSubTopicCode}
                    >
                      <MenuItem value='' disabled>
                        Select...
                      </MenuItem>
                      {subSubSubTopicCodes.map(item => (
                        <MenuItem key={item.key} value={item.key}>
                          {`(${item.key}) ${item.label}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

SelectClassification.propTypes = {
  classes: PropTypes.object.isRequired,
  subjectCode: PropTypes.string.isRequired,
  classification: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(SelectClassification);
