import React from 'react';
import msToStringHuman from '../../utils/msToStringHuman';
import styled from 'styled-components/macro';

const Text = styled.span`
  white-space: nowrap;
`;

const SimpleDuration = ({ children, text = '' }) => {
  return <Text>{`${text} ${msToStringHuman(children)}`}</Text>;
};

SimpleDuration.propTypes = {};

export default SimpleDuration;
