import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import DialogModal from '../../components/DialogModal';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import RowMenu from './RowMenu';
import { setQuestion, publishQuestion } from '../../store/actions/questionActions';

const Truncate = styled.div`
  text-overflow: ellipsis;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
`;

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function filterData(array, term) {
  return array.filter(item => includes(item.key, term));
}

const rows = [
  {
    id: 'key',
    align: 'left',
    disablePadding: false,
    label: 'id',
  },
  {
    id: 'value.subjectCode',
    align: 'center',
    disablePadding: true,
    label: 'Subject Code',
  },
  {
    id: 'classification',
    align: 'center',
    disablePadding: true,
    label: 'Classification',
  },
  {
    id: 'value.correctAnswer',
    align: 'center',
    disablePadding: true,
    label: 'Answer',
  },
  { id: 'question', align: 'left', disablePadding: false, label: 'Question' },
];

const styles = {
  root: {
    width: '100%',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableCell: {
    fontSize: '10px',
  },
  tableCellError: {
    color: '#ff0000',
  },
};

class EnhancedTable extends React.Component {
  state = {
    dialogStatus: 'ready',
    dialogType: 'preview',
    isDialogOpen: false,
    order: 'desc',
    orderBy: 'key',
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 5,
    qid: '',
    qsearch: '',
    isFilterOn: false,
  };
  static getDerivedStateFromProps(props) {
    return { data: props.data || [] };
  }

  handleMenuActionPublish = (event, qid) => {
    const newSelected = [];
    newSelected.push(qid);

    this.setState({
      isDialogOpen: true,
      dialogStatus: 'ready',
      dialogType: 'publish',
      qid: qid,
      selected: newSelected,
    });
  };
  handleMenuActionDelete = (event, qid) => {
    this.setState({
      isDialogOpen: true,
      dialogStatus: 'ready',
      dialogType: 'delete',
      qid: qid,
    });
  };
  handleMenuActionUnpublish = (event, qid) => {
    this.setState({
      isDialogOpen: true,
      dialogStatus: 'ready',
      dialogType: 'unpublish',
      qid: qid,
    });
  };

  handleToolbarAction = (event, option) => {
    const { location, history, section, level, qbank, isDraft } = this.props;
    const { selected } = this.state;

    const view = option.toLowerCase();

    const qObj = {
      section: section,
      level: level,
      view: view,
      qbank: qbank,
      qid: selected[0],
      questions: selected,
      isDraft: isDraft,
    };
    if (option === 'publish') {
      this.setState({
        isDialogOpen: true,
        dialogStatus: 'ready',
        dialogType: 'publish',
        qid: selected[0],
      });
    }

    if (option === 'preview') {
      const url = `${location.pathname}/${view}`;
      this.props.setQuestion(qObj).then(() => {
        history.push(url);
      });
    }
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.key) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangeSearch = event => {
    this.setState({ qsearch: event.target.value });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDialogCancel = () => {
    this.setState({ isDialogOpen: false });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  handleDialogDone = () => {
    this.setState({ isDialogOpen: false });
  };

  handleDialogAction = () => {
    this.gotoNewQuestionScreen();
  };

  gotoNewQuestionScreen = () => {
    const { section, history } = this.props;
    const url = `/admin/${section}/new`;
    history.push(url);
  };

  handleToggleFilter = () => {
    this.setState(state => ({
      isFilterOn: !state.isFilterOn,
    }));
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, section, level, qbank, isDraft, superAdmin } = this.props;
    const {
      dialogType,
      dialogStatus,
      isDialogOpen,
      qid,
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      qsearch,
      isFilterOn,
    } = this.state;
    const filteredData =
      isFilterOn && qsearch.length > 0 ? filterData(data, qsearch) : data;

    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, filteredData.length - page * rowsPerPage);

    return (
      <>
        <DialogModal
          type={dialogType}
          open={isDialogOpen}
          qid={qid}
          questions={selected}
          status={dialogStatus}
          handleClose={this.handleDialogClose}
          handleCancel={this.handleDialogCancel}
          handleAction={this.handleDialogAction}
          handleDone={this.handleDialogDone}
        />

        <div className={classes.root}>
          <EnhancedTableToolbar
            superAdmin={superAdmin}
            isDraft={isDraft}
            numSelected={selected.length}
            action={this.handleToolbarAction}
            title={qbank.label}
            filterOn={isFilterOn}
            toggleFilter={this.handleToggleFilter}
            qsearch={qsearch}
            handleChange={this.handleChangeSearch}
          />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby='tableTitle'>
              <EnhancedTableHead
                rows={rows}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={filteredData.length}
              />
              <TableBody>
                {stableSort(filteredData, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.key);
                    const ca = n.value.correctAnswer
                      ? n.value.correctAnswer
                      : 'na';

                    const caIsValid = ca.length > 1 ? false : true;

                    const cs = n.value.classification
                      ? n.value.classification
                      : [''];
                    const csString = Array.isArray(cs) ? cs[0] : '';
                    const regex = /^([IV]+)(-?)([A-Z^/]?)(-?)([0-9]?)(-?)([a-z]?)$/;
                    const csIsValid = regex.test(csString);

                    const qIsValid = csIsValid && caIsValid;

                    return (
                      <TableRow
                        hover
                        onClick={event => this.handleClick(event, n.key)}
                        role='checkbox'
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.key}
                        selected={isSelected}
                      >
                        <TableCell padding='checkbox'>
                          <Checkbox checked={isSelected} />
                        </TableCell>
                        <TableCell
                          component='th'
                          scope='row'
                          className={classes.tableCell}
                        >
                          {n.key}
                        </TableCell>

                        <TableCell>{n.value.subjectCode}</TableCell>
                        {!csIsValid ? (
                          <TableCell className={classes.tableCellError}>
                            {JSON.stringify(cs)}
                          </TableCell>
                        ) : (
                          <TableCell>{JSON.stringify(cs)}</TableCell>
                        )}

                        {!caIsValid ? (
                          <TableCell className={classes.tableCellError}>
                            {n.value.correctAnswer}
                          </TableCell>
                        ) : (
                          <TableCell>{n.value.correctAnswer}</TableCell>
                        )}

                        <TableCell>
                          <Truncate>{n.value.question}</Truncate>
                        </TableCell>
                        <TableCell>
                          {
                            <RowMenu
                              qid={n.key}
                              section={section}
                              level={level}
                              isDraft={isDraft}
                              qbank={qbank}
                              qIsValid={qIsValid}
                              menuActionPublish={event =>
                                this.handleMenuActionPublish(event, n.key)
                              }
                              menuActionDelete={event =>
                                this.handleMenuActionDelete(event, n.key)
                              }
                              menuActionUnpublish={event =>
                                this.handleMenuActionUnpublish(event, n.key)
                              }
                            />
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  section: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  qbank: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setQuestion: qObj => dispatch(setQuestion(qObj)),
  publishQuestion: (level, qbank, qid) =>
    dispatch(publishQuestion(level, qbank, qid)),
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(EnhancedTable);
