export const breakpoints = {
  xs: 0,
  sm: 425,
  md: 768,
  lg: 960,
  xl: 1280,
};

export const deviceWidth = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: `${breakpoints.sm}px`,
  tablet: `${breakpoints.md}px`,
  tabletL: `${breakpoints.lg}px`,
  laptop: '1024px',
  laptopL: `${breakpoints.xl}px`,
  desktop: '2560px',
};

export const device = {
  mobileS: `(min-width: ${deviceWidth.mobileS})`,
  mobileM: `(min-width: ${deviceWidth.mobileM})`,
  mobileL: `(min-width: ${deviceWidth.mobileL})`,
  tablet: `(min-width: ${deviceWidth.tablet})`,
  tabletL: `(min-width: ${deviceWidth.tabletL})`,
  laptop: `(min-width: ${deviceWidth.laptop})`,
  laptopL: `(min-width: ${deviceWidth.laptopL})`,
  desktop: `(min-width: ${deviceWidth.desktop})`,
  desktopL: `(min-width: ${deviceWidth.desktop})`,
};

export const deviceMin = {
  mobileS: `(min-width: ${deviceWidth.mobileS})`,
  mobileM: `(min-width: ${deviceWidth.mobileM})`,
  mobileL: `(min-width: ${deviceWidth.mobileL})`,
  tablet: `(min-width: ${deviceWidth.tablet})`,
  tabletL: `(min-width: ${deviceWidth.tabletL})`,
  laptop: `(min-width: ${deviceWidth.laptop})`,
  laptopL: `(min-width: ${deviceWidth.laptopL})`,
  desktop: `(min-width: ${deviceWidth.desktop})`,
  desktopL: `(min-width: ${deviceWidth.desktop})`,
};
export const deviceMax = {
  mobileS: `(max-width: ${deviceWidth.mobileS})`,
  mobileM: `(max-width: ${deviceWidth.mobileM})`,
  mobileL: `(max-width: ${deviceWidth.mobileL})`,
  tablet: `(max-width: ${deviceWidth.tablet})`,
  tabletL: `(max-width: ${deviceWidth.tabletL})`,
  laptop: `(max-width: ${deviceWidth.laptop})`,
  laptopL: `(max-width: ${deviceWidth.laptopL})`,
  desktop: `(max-width: ${deviceWidth.desktop})`,
  desktopL: `(max-width: ${deviceWidth.desktop})`,
};

export const mediaMinDevice = {
  mobileS: `@media (min-width: ${deviceWidth.mobileS})`,
  mobileM: `@media (min-width: ${deviceWidth.mobileM})`,
  mobileL: `@media (min-width: ${deviceWidth.mobileL})`,
  tablet: `@media (min-width: ${deviceWidth.tablet})`,
  tabletL: `@media (min-width: ${deviceWidth.tabletL})`,
  laptop: `@media (min-width: ${deviceWidth.laptop})`,
  laptopL: `@media (min-width: ${deviceWidth.laptopL})`,
  desktop: `@media (min-width: ${deviceWidth.desktop})`,
  desktopL: `@media (min-width: ${deviceWidth.desktop})`,
};
export const mediaMaxDevice = {
  mobileS: `@media (max-width: ${deviceWidth.mobileS})`,
  mobileM: `@media (max-width: ${deviceWidth.mobileM})`,
  mobileL: `@media (max-width: ${deviceWidth.mobileL})`,
  tablet: `@media (max-width: ${deviceWidth.tablet})`,
  tabletL: `@media (max-width: ${deviceWidth.tabletL})`,
  laptop: `@media (max-width: ${deviceWidth.laptop})`,
  laptopL: `@media (max-width: ${deviceWidth.laptopL})`,
  desktop: `@media (max-width: ${deviceWidth.desktop})`,
  desktopL: `@media (max-width: ${deviceWidth.desktop})`,
};
// export function mediaTag(rule, device) {
//   return `'@media (max-width: ${size[device]})'`;
// }
