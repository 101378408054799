import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { TableHead, Tooltip } from '@material-ui/core';
import { TableSortLabel } from '@material-ui/core';
import { sortObjects } from '../../utils/sort';
import MoreMenu from '../MoreMenu';
import { LgTableCell, MdTableCell, SmTableCell, XlTableCell, EmptyDataMessage } from './StyledGenericTable';
// @ts-nocheck
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return (a, b) => sortObjects(a, b, orderBy, order)
}

type TSortOrder = 'desc' | 'asc';

type TProps = {
  data: any[],
  colHeaders: {
    id: string,
    align?: string,
    label: string,
    numeric?: boolean,
    disablePadding?: boolean,
    selector: string,
    size?: 'sm' | 'md' | 'lg' | 'xl',
  }[],
  menuItems?: string[] | {
    label: string,
    value: string,
  }[],
  selectable?: boolean,
  onMenuAction?: (e: any, id: string, action: string) => void,
}

const SizedTableCell = {
  'sm': SmTableCell,
  'md': MdTableCell,
  'lg': LgTableCell,
  'xl': XlTableCell,
}

const GenericTable: React.FC<TProps> = ({
  colHeaders,
  menuItems,
  onMenuAction,
  selectable = true,
  ...props
}) => {
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState<TSortOrder>('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);

  useEffect(() => {
    if (props.data) {
      setData(props.data)
    }
  }, [props.data])

  const onRequestSort = (property) => () => {
    const orderBy = property;
    let order: TSortOrder = 'desc';

    if (orderBy === property && order === 'desc') {
      order = 'asc';
    }

    setOrder(order);
    setOrderBy(orderBy);
  };

  const onRowClick = (id) => () => {
    if (selectable) {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    }
  };

  const onMoreMenuAction = (id) => (e, action) => {
    onMenuAction(e, id, action);
  }

  const onChangePage = (event, page) => {
    setPage(page);
  };

  const onChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const isSelected = (id) => selected.includes(id);

  return (
    <>
      <div style={{ overflowX: 'auto' }}>
        {
          data.length ? (
            <Table aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  {colHeaders.map(row => {
                    const TableCell = SizedTableCell[row.size] || MuiTableCell;

                    return (
                      <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                      >
                        <Tooltip
                          title="Sort"
                          placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={onRequestSort(row.id)}
                          >
                            {row.label}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    );
                  })}

                  {
                    menuItems && (
                      <MuiTableCell />
                    )
                  }
                </TableRow>
              </TableHead>

              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((val, index) => {
                    const isChecked = selectable && isSelected(val.id);

                    return (
                      <TableRow
                        hover
                        onClick={onRowClick(val.id)}
                        role="checkbox"
                        aria-checked={isChecked}
                        tabIndex={-1}
                        key={val.id + '' + index}
                        selected={isChecked}
                      >
                        {colHeaders.map((row) => {
                          const TableCell = SizedTableCell[row.size] || MuiTableCell;

                          return (
                            <TableCell key={row.selector} align="left" component="th" scope="row">
                              {val[row.selector]}
                            </TableCell>
                          );
                        })}

                        {
                          menuItems && (
                            <MuiTableCell align="center">
                              <MoreMenu
                                items={menuItems}
                                onMenuAction={onMoreMenuAction(val.id)} />

                            </MuiTableCell>
                          )
                        }
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          ) : (
            <EmptyDataMessage>There is no data to display</EmptyDataMessage>
          )
        }

      </div>

      {
        !!data.length && (
            //@ts-ignore
          <TablePagination
            rowsPerPageOptions={[5, 10, 30]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        )
      }
    </>
  );
}

export default GenericTable;
