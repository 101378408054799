import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Loading from '../../../../components/Loading';
import QuestionTitle from './QuestionTitle';
import QuestionCopy from './QuestionCopy';
import AnswerItem from './AnswerItem';
import FeedbackCard from './FeedbackCard';
import FeedbackTitle from './FeedbackTitle';
import FeedbackCopy from './FeedbackCopy';

const AnswersArea = styled.div`
  padding: 3rem 0;
`;
const FeedbackArea = styled.div`
  margin: 2rem 0 0;
`;

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerItems: ['A', 'B', 'C', 'D'],
      correctAnswer: props.content.correctAnswer,
    };
  }

  render() {
    const { content, qnum } = this.props;
    const { answerItems } = this.state;

    const qobj = content !== null ? content : null;
    if (qobj) {
      return (
        <>
          <QuestionTitle>{`Question #${qnum}`}</QuestionTitle>
          <QuestionCopy dangerouslySetInnerHTML={{ __html: qobj.question }} />

          <FeedbackArea>
            <FeedbackCard>
              <FeedbackTitle>Explanation</FeedbackTitle>
              <FeedbackCopy
                className='correctFeedback'
                dangerouslySetInnerHTML={{
                  __html: qobj.correctFeedback,
                }}
              />
              <FeedbackCopy
                className='feedback1'
                dangerouslySetInnerHTML={{
                  __html: qobj.feedback1,
                }}
              />
              {qobj.feedback2 && (
                <FeedbackCopy
                  className='feedback2'
                  dangerouslySetInnerHTML={{
                    __html: qobj.feedback2,
                  }}
                />
              )}
              {qobj.feedback3 && (
                <FeedbackCopy
                  className='feedback3'
                  dangerouslySetInnerHTML={{
                    __html: qobj.feedback3,
                  }}
                />
              )}
            </FeedbackCard>
          </FeedbackArea>

          <AnswersArea>
            {answerItems.map(itemKey => (
              <AnswerItem
                key={itemKey}
                itemLetter={itemKey}
                itemCopy={qobj[itemKey]}
                isAnswer={qobj.correctAnswer === itemKey}
              />
            ))}
          </AnswersArea>
        </>
      );
    }
    return <Loading />;
  }
}

Question.propTypes = {
  content: PropTypes.object,
  qnum: PropTypes.number,
};

export default Question;
