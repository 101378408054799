import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent } from '../../containers/Card';
import { Avatar, UploadArea } from './StyledAvatarCard';
import Uploader from '../Uploader';

const AvatarCard = ({
  withUploader = false,
  avatarSrc = '',
  ...props
}) => {
  const { user } = props;
  let initials = '';

  if (user && user.initials) {
    initials = user.initials;
  } else if (user && user.firstName && user.lastName) {
    initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
  }

  const onChange = e => {
    if (props.onUpload) {
      props.onUpload(e.target.files[0]);
    }
  };

  return (
    <Card>
      <CardContent>
        {
          withUploader ? (
            <UploadArea>
              <Uploader name={'avatar'} onChange={onChange}>
                <Avatar src={avatarSrc}>{initials ? initials : null}</Avatar>
              </Uploader>
            </UploadArea>
          ) : (
            <UploadArea>
              <Avatar src={avatarSrc} >{initials}</Avatar>
            </UploadArea>
          )
        }

        <Typography align='center' variant='h6'>
          {user.name ? user.name : `${user.firstName} ${user.lastName}`}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AvatarCard;
