export const LANDING = '/';
export const ADMIN = '/admin';
export const STUDENT = '/student';
export const SETUP = '/setup';

export const AdminRoutes = {
  DASHBOARD: '/admin',
  USERS: '/admin/users',
  STUDENTS: '/admin/students',
  VIEW_STUDENT: '/admin/students/view',
  STUDY: '/admin/study',
  FREE: '/admin/free',
  TEST: '/admin/test',
  PROFILE: '/admin/profile',
};
