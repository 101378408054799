import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';

import SimpleBtn from '../../../../elements/SimpleBtn';
import BaseDialog from '../../../../components/BaseDialog';
import { question as dialogTypes } from '../../../../_CONS/modalTypes';
import { PreviewFooterWrapper, PreviewFooterAreaControls, ToolbarControls, Btn } from './StyledPreviewFooter';
import { publishQuestions, removeQuestion, setQuestionView, unpublishQuestion } from '../../../../store/actions/questionActions';

class PreviewFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      dialog: null,
    };
  }

  goToNewQuestionScreen = () => {
    const { section, history } = this.props;

    const url = `/admin/${section}/new`;
    history.push(url);
  };

  goToEditQuestionScreen = () => {
    const { section, history } = this.props;
    const url = `/admin/${section}/edit`;

    this.props.setQuestionView('edit').then(() => {
      history.push(url);
    });
  };

  onFooterAction = type => () => {
    const { qid } = this.props;
    const dialog = dialogTypes[`${type.toUpperCase()}_QUESTION`];

    switch (type) {
      case 'prev':
      case 'close':
        this.props.postGuideAction();
        break;
      case 'end':
      case 'next':
        this.props.nextQuestion();
        break;
      case 'edit':
        this.goToEditQuestionScreen();
        break;
      case 'unpublish':
      case 'publish':
      case 'delete':
        this.setState({
          dialog,
          qid: qid,
          selected: [qid],
        });
        break;
      default:
        break;
    }
  }

  handleDialogCancel = () => {
    this.setState({
      dialog: null,
    });
  }

  handleDialogConfirm = type => {
    const {
      qid,
      level,
      qbank,
      section,
      questions,
      deleteQuestion,
      publishQuestions,
      unpublishQuestion,
    } = this.props;

    switch (type) {
      case 'delete-question':
        deleteQuestion(level, qbank, qid).then(() => {
          this.props.history.push(`/admin/${section}`);
        });
        break;
      case 'publish-question':
        publishQuestions(level, qbank, questions).then(() => {
          this.setState({ dialog: dialogTypes.PUBLISH_QUESTION_SUCCESS });
        });
        break;
      case 'publish-question-success':
        this.setState({ dialog: null }, this.goToNewQuestionScreen);
        break;
      case 'unpublish-question':
        unpublishQuestion(level, qbank, qid).then(() => {
          this.setState({ dialog: dialogTypes.UNPUBLISH_QUESTION_SUCCESS });
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { questions, questionNumber, isDraft, qStatus } = this.props;
    const { dialog } = this.state;
    const totalQuestions = questions.length;
    const isFirst = questionNumber === 1;
    const isLast = totalQuestions === questionNumber;

    return (
      <>
        <BaseDialog
          {...dialog}
          open={!!dialog && dialog.open}
          isLoading={qStatus === 'loading'}
          onCancelAction={this.handleDialogCancel}
          onConfirmAction={this.handleDialogConfirm}
        />

        <PreviewFooterWrapper>
          <PreviewFooterAreaControls>
            {isFirst ? (
              <Button
                variant='text'
                color='default'
                onClick={this.onFooterAction('close')}
              >
                Close
              </Button>
            ) : (
              <Button
                variant='text'
                color='default'
                onClick={this.onFooterAction('prev')}
              >
                <ArrowBack /> PREVIOUS
              </Button>
            )}

            {isDraft ? (
              <ToolbarControls>
                <Btn
                  variant='contained'
                  type='remove'
                  onClick={this.onFooterAction('delete')}
                >
                  DELETE
                </Btn>

                <Btn
                  variant='contained'
                  type='primary'
                  onClick={this.onFooterAction('edit')}
                >
                  EDIT
                </Btn>
                <SimpleBtn
                  variant='contained'
                  type='secondary'
                  onClick={this.onFooterAction('publish')}
                >
                  PUBLISH
                </SimpleBtn>
              </ToolbarControls>
            ) : (
              <ToolbarControls>
                <SimpleBtn
                  variant='contained'
                  type='remove'
                  onClick={this.onFooterAction('unpublish')}
                >
                  UNPUBLISH
                </SimpleBtn>
              </ToolbarControls>
            )}

            {isLast ? (
              <Button
                variant='text'
                type='default'
                onClick={this.onFooterAction('end')}
              >
                END
              </Button>
            ) : (
              <Button
                variant='text'
                type='default'
                onClick={this.onFooterAction('next')}
              >
                NEXT
                <ArrowForward />
              </Button>
            )}
          </PreviewFooterAreaControls>
        </PreviewFooterWrapper>
      </>
    );
  }
}

PreviewFooter.propTypes = {
  isDraft: PropTypes.bool.isRequired,
  section: PropTypes.string.isRequired,
  qid: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  questionNumber: PropTypes.number.isRequired,
  postGuideAction: PropTypes.func.isRequired,
  nextQuestion: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  qbank: state.questionStatus.qbank,
  level: state.questionStatus.level,
  view: state.questionStatus.view,
  qStatus: state.questionStatus.status,
});

const mapDispatchToProps = dispatch => ({
  setQuestionView: view => dispatch(setQuestionView(view)),
  publishQuestions: (level, qbank, questions) => dispatch(publishQuestions(level, qbank, questions)),
  deleteQuestion: (level, qbank, qid) => dispatch(removeQuestion(level, qbank, qid)),
  unpublishQuestion: (level, qbank, qid) => dispatch(unpublishQuestion(level, qbank, qid)),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(PreviewFooter);
