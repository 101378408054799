import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { FirebaseReducer, firebaseReducer, FirestoreReducer } from 'react-redux-firebase';
import authReducer from './authReducer';
import questionStatus from './questionStatus';
import userStatus from './userStatus';
import appReducer from './appReducer';
import guideStatus from './guideStatus';
import setupStatus from './setupStatus';
import promoReducer from './promoReducer';
import faqReducer from './faqReducer';
import alertReducer from './alertReducer';

export type TRootState = {
  firebase: FirebaseReducer.Reducer<any>,
  firestore: FirestoreReducer.Reducer,
  [key: string]: any
}

const rootReducer = combineReducers<TRootState>({
  auth: authReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  questionStatus: questionStatus,
  userStatus,
  appReducer,
  guideStatus,
  setupStatus,
  promoReducer,
  faqReducer,
  alertReducer,
});

export default rootReducer;
