// @ts-nocheck
import React, { Component } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import StudentTableHead from './StudentTableHead';
import { TRow } from './types';
import { color, fontWeight } from '../../styles/style';
import { Checkbox } from '@material-ui/core';
import { removeStudent, resetStudentView } from '../../store/actions/studentActions';
import { connect, ConnectedProps } from 'react-redux';
import modalTypes from '../../_CONS/modalTypes';
import { TDialog } from '../../../types';
import { RootState } from '../../store/createReduxStore';
import Dialog from '../Dialog';
import MoreMenu from '../MoreMenu';
import { RouteComponentProps } from 'react-router';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

type TStudentTableProps = RouteComponentProps & PropsFromRedux & {
  section: string,
  colHeaders: TRow[],
  classes: { [key: string]: string },
  selected: string[],
  withCheckbox?: boolean,
  onSelect: (arg: string) => void,
}

type TStudentTableState = {
  requestConfirmation: boolean,
  order: "desc" | "asc",
  dialog: TDialog | null,
  orderBy: string,
  data: any[],
  page: number,
  rowsPerPage: number,
  qid: string,
  studentId: string,
  checkedStudents: any[],
}

class StudentTable extends Component<TStudentTableProps, TStudentTableState> {
  constructor(props) {
    super(props);

    this.state = {
      dialog: null,
      requestConfirmation: false,
      order: "desc",
      orderBy: 'id',
      data: [],
      page: 0,
      rowsPerPage: 30,
      qid: '',
      studentId: '',
      checkedStudents: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    return { data: props.data || [] };
  }

  viewStudent = (uid: string) => {
    const { location, history, } = this.props;
    const url = `${location.pathname}/view/${uid}`;

    history.push(url);
  };

  deleteStudentRequest = (uid: string) => {
    this.setState({
      studentId: uid,
      requestConfirmation: true
    });
  };

  onMenuAction = (uid: string) => (e: MouseEvent, action: string) => {
    if (action === 'view') {
      this.viewStudent(uid);
    } else if (action === 'delete') {
      this.deleteStudentRequest(uid);
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order: 'desc' | 'asc' = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDialogCancel = () => {
    this.props.resetStudentView();
    this.setState({
      requestConfirmation: false,
    })
  }

  handleDialogConfirm = (type) => {
    this.setState({ requestConfirmation: false })

    this.props.removeStudent(this.state.studentId);
  }

  isSelected = (id: string) => this.props.selected.indexOf(id) !== -1;

  render() {
    const { classes, section, colHeaders, status, profile } = this.props;
    const {
      data,
      order,
      orderBy,
      rowsPerPage,
      page,
      requestConfirmation
    } = this.state;

    return (
      <>
        <Dialog
          {...modalTypes.DELETE_STUDENT}
          type={'confirm'}
          buttonLabels={['Cancel', 'Delete']}
          open={requestConfirmation}
          onCancel={this.handleDialogCancel}
          onConfirm={this.handleDialogConfirm}
        />

        <div className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle" data-testid="studentsTable">
              <StudentTableHead
                rows={colHeaders}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((val, indx) => {
                    const isSelected = this.isSelected(val.uid);
                    return (
                      <TableRow
                        hover
                        onClick={event => this.props.onSelect(val.uid)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={val.uid}
                        selected={isSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isSelected} />
                        </TableCell>

                        <TableCell className={classes.tableCell} align="left" scope="row">
                          {val.lastName}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">{val.firstName}</TableCell>

                        <TableCell className={classes.tableCell} align="left">{val.email}</TableCell>

                        {section === 'new' && (
                          <>
                            <TableCell className={classes.tableCell} align="left" data-status={val.isActive ? 'active' : 'inactive'}>
                              {val.isActive ? (
                                <DoneIcon color="secondary" />
                              ) : (
                                <ClearIcon color="error" />
                              )}
                            </TableCell>
                          </>
                        )}
                        {section !== 'new' && (
                          <>
                            <TableCell className={classes.tableCell} align="left">
                              {val.isActive ? (
                                <DoneIcon color="secondary" />
                              ) : (
                                <ClearIcon color="error" />
                              )}
                            </TableCell>

                            {val.all ? (
                              <TableCell className={classes.tableCell} align="right">{`${val.all.total} - ${val.all.correct
                                } `}</TableCell>
                            ) : (
                              <TableCell className={classes.tableCell} align="left">no data</TableCell>
                            )}
                          </>
                        )}
                        <TableCell className={classes.tableCell} align="center">
                          {
                            <MoreMenu
                              items={[
                                { label: 'VIEW', value: 'view' },
                                { label: 'DELETE', value: 'delete' },
                              ]}
                              onMenuAction={this.onMenuAction(val.uid)}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[]}
            labelRowsPerPage={''}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
          />
        </div>
      </>
    );
  }
}

const styles = theme => createStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableHeadCell: {
    color: color.blueDarkest,
    fontWeight: 700,
  },
  tableCell: {
    color: color.dark,
    fontWeight: fontWeight.semiBold,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  moreVert: {
    textAlign: 'right',
    color: color.blueDarkest,
  },
});

const mapStateToProps = ({ userStatus, firebase, firestore }: RootState) => ({
  status: userStatus.status,
  error: userStatus.error,
  profile: firebase.profile,
  organization: firestore.data.currentOrganization,
});

const mapDispatchToProps = {
  removeStudent,
  resetStudentView,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(
  connector,
  withRouter,
  withStyles(styles))
  (StudentTable);
