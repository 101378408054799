export const resendEmail = {
  RESEND_EMAIL_SUCCESS: {
    title: 'Success',
    content: 'The email was successfully sent',
  },
  RESEND_EMAIL_ERROR: {
    title: 'Error',
    content: 'Unknown error happened',
  },
};

export const updateStudentDialog = {
  UPDATE_EMAIL_ADMIN: {
    title: 'Warning',
    content: 'You are going to update student\'s email. The student will be logged out after that. Do you want to proceed?',
  },
  UPDATE_EMAIL_STUDENT: {
    title: 'Warning',
    content: 'You are going to update your email. You will be logged out after that. Do you want to proceed?',
  },
  UPDATE_SUCCESS: {
    title: 'Success',
    content: 'The profile was successfully updated!',
  },
  UPDATE_ERROR: {
    title: 'Error',
    content: 'Error happened during update. Please, try later.',
  },
};

export const resetGuideScores = {
  RESET_SCORE: {
    type: 'reset-score',
    open: true,
    title: '',
    content: 'Are you sure you want to reset your Study Aid score? Please be aware that these scores will be permanently removed from your Student Profile.',
    buttons: [
      { label: 'Cancel', color: 'default', action: 'cancel' },
      { label: 'OK', color: 'secondary', action: 'confirm' },
    ],
  },
};

export const question = {
  UNPUBLISH_QUESTION: {
    type: 'unpublish-question',
    open: true,
    title: 'Unpublish Question',
    content: 'Are you sure you want to UNPUBLISH the Question?',
    buttons: [
      { label: 'Cancel', color: 'default', action: 'cancel' },
      { label: 'Unpublish', color: 'secondary', action: 'confirm' },
    ],
  },
  UNPUBLISH_QUESTION_SUCCESS: {
    type: 'unpublish-question-success',
    open: true,
    title: 'Unpublish Question',
    content: 'You have successfully UNPUBLISHD the question.',
    buttons: [
      { label: 'Done', color: 'default', action: 'cancel' },
    ],
  },
  PUBLISH_QUESTION: {
    type: 'publish-question',
    open: true,
    title: 'Publish Question',
    content: 'Are you sure you want to PUBLISH the Question?',
    buttons: [
      { label: 'Cancel', color: 'default', action: 'cancel' },
      { label: 'Publish', color: 'secondary', action: 'confirm' },
    ],
  },
  PUBLISH_QUESTION_SUCCESS: {
    type: 'publish-question-success',
    open: true,
    title: 'Publish Question',
    content: 'You have successfully PUBLISHED the question. What would you like to do now?',
    buttons: [
      { label: 'Done', color: 'default', action: 'cancel' },
      { label: 'Add Another', color: 'secondary', action: 'confirm' },
    ],
  },
  DELETE_QUESTION: {
    type: 'delete-question',
    open: true,
    title: 'Delete Question',
    content: 'Are you sure you want to DELETE the Question?',
    buttons: [
      { label: 'Cancel', color: 'default', action: 'cancel' },
      { label: 'Delete', color: 'secondary', action: 'confirm' },
    ],
  },
  DELETE_QUESTION_SUCCESS: {
    type: 'delete-question-success',
    open: true,
    title: 'Delete Question',
    content: 'You have successfully DELETED the question.',
    buttons: [
      { label: 'Done', color: 'default', action: 'cancel' },
    ],
  },
};

const modalTypes = {
  DELETE_STUDENT: {
    type: 'delete-student',
    open: true,
    title: 'Delete Student',
    content: 'Are you sure you want to DELETE the Student?',
    buttons: [
      { label: 'Cancel', color: 'default', action: 'cancel' },
      { label: 'Delete', color: 'secondary', action: 'confirm' },
    ],
  },
  DELETE_STUDENT_ERROR: {
    title: 'Error happened',
    content: 'The unknown error happened. Please, reload the page and try again.',
  },
  CREATE_STUDENT_ERROR: {
    typeId: 'create-student-error',
    title: 'Error happened',
    content: 'The unknown error happend. Please, reload the page and try again.',
  },
  DELETE_USER: {
    type: 'delete-user',
    open: true,
    title: 'Delete user',
    content: 'Are you sure you want to DELETE the User?',
    buttons: [
      { label: 'Cancel', color: 'default', action: 'cancel' },
      { label: 'Delete', color: 'secondary', action: 'confirm' },
    ],
  },
  DELETE_USER_ERROR: {
    type: 'delete-user-error',
    open: true,
    title: 'Error happened',
    content: 'The unknown error happend. Please, reload the page and try again.',
    buttons: [
      { label: 'Ok', color: 'default', action: 'confirm' },
    ],
  },
  CREATE_USER_ERROR: {
    type: 'create-user-error',
    open: true,
    title: 'Error happened',
    content: 'The unknown error happend. Please, reload the page and try again.',
    buttons: [
      { label: 'Ok', color: 'default', action: 'confirm' },
    ],
  },

  FIREBASE_ERROR: {
    type: 'error',
    open: true,
    title: 'Connection Error',
    content: 'We\'ve run into an issue with database connection, please try again later.',
    buttons: [
      { label: 'Ok', color: 'default', action: 'confirm' },
    ],
  },

  COMPLETE_TESTGUIDES: {
    type: 'complete-test',
    title: 'Are you sure you want to complete?',
    content:
      'Click RESUME to return to the test. Click COMPLETE to end progress. By clicking Complete, this test is completed and cannot be retaken.',
    buttons: [
      { label: 'Resume', color: 'default', action: 'cancel' },
      { label: 'Complete', color: 'secondary', action: 'confirm' },
    ],
  },

  COMPLETE_STUDYGUIDES: {
    type: 'complete-study',
    title: 'Are you sure you want to complete?',
    content:
      'Click RESUME to return to the study aid. Click COMPLETE to end progress. By clicking Complete, this study aid is completed and cannot be retaken.',
    buttons: [
      { label: 'Resume', color: 'default', action: 'cancel' },
      { label: 'Complete', color: 'secondary', action: 'confirm' },
    ],
  },
  PAUSE_TESTGUIDES: {
    type: 'pause-test',
    title: 'Pause and save progress?',
    content:
      'Click RESUME to return to the test. Click PAUSE to pause and save your progress for later.',
    buttons: [
      { label: 'Resume', color: 'default', action: 'cancel' },
      { label: 'Pause', color: 'secondary', action: 'confirm' },
    ],
  },
  PAUSE_STUDYGUIDES: {
    type: 'pause-study',
    title: 'Pause and save progress?',
    content:
      'Click RESUME to return to the study aid. Click PAUSE to pause and save your progress for later.',
    buttons: [
      { label: 'Resume', color: 'default', action: 'cancel' },
      { label: 'Pause', color: 'secondary', action: 'confirm' },
    ],
  },
  TIMESUP_TESTGUIDES: {
    type: 'timesup',
    title: 'Times Up',
    content:
      'Click RESUME to return to the test. Click COMPLETE to end progress. By clicking Complete, this test is completed and cannot be retaken. All unanswered practice test questions will be marked incorrect for scoring purposes. Please note that Official MBE scores are based upon the number of answered correctly, there are no penalties for incorrectly answered questions (or guesses).',
    buttons: [
      { label: 'Resume', color: 'default', action: 'cancel' },
      { label: 'Complete', color: 'secondary', action: 'confirm' },
    ],
  },
  TIMESUP_STUDYGUIDES: {
    type: 'timesup',
    title: 'Times Up',
    content:
      'Click RESUME to return to the test. Click COMPLETE to end progress. By clicking Complete, this test is completed and cannot be retaken. All unanswered practice test questions will be marked incorrect for scoring purposes. Please note that Official MBE scores are based upon the number of answered correctly, there are no penalties for incorrectly answered questions (or guesses).',
    buttons: [
      { label: 'Resume', color: 'default', action: 'cancel' },
      { label: 'Complete', color: 'secondary', action: 'confirm' },
    ],
  },
  RESET_TESTGUIDES: {
    type: 'reset-test',
    title: 'Are you sure you want to Reset this test?',
    content: '',
    buttons: [
      { label: 'Cancel', color: 'default', action: 'cancel' },
      { label: 'Reset', color: 'secondary', action: 'confirm' },
    ],
  },
  RESET_STUDYGUIDES: {
    type: 'reset-study',
    title: 'Are you sure you want to Reset this Study Aid?',
    content: '',
    buttons: [
      { label: 'Cancel', color: 'default', action: 'cancel' },
      { label: 'Reset', color: 'secondary', action: 'confirm' },
    ],
  },
  STUDENT_CREATED: {
    type: 'student-created',
    title: 'Email Sent',
    content: 'You have successfully CREATED a new Student and an Email has been sent to them. What would you like to do now?',
    buttons: [
      { label: 'DONE', color: 'default', action: 'cancel' },
      { label: 'ADD ANOTHER', color: 'secondary', action: 'confirm' },
    ],
  },
  USER_CREATED: {
    type: 'admin-created',
    title: 'Email Sent',
    content: 'You have successfully CREATED a new Admin User and an Email has been sent to them. What would you like to do now?',
    buttons: [
      { label: 'DONE', color: 'default', action: 'cancel' },
      { label: 'ADD ANOTHER', color: 'secondary', action: 'confirm' },
    ],
  },

  PAYMENT_ERROR: {
    type: 'payment-error',
    title: 'Payment error',
    content: '',
    buttons: [
      { label: 'Ok', color: 'default', action: 'cancel' },
    ],
  },
};
export default modalTypes;
