import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent } from '../../containers/Card';
import AvatarCard from '../../components/AvatarCard';

function TabContainer(props) {
  return <>{props.children}</>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class Profile extends Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { user } = this.props;
    const { value } = this.state;

    return (
      <>
        <Grid item xs={4}>
          <Grid container spacing={3} direction={'column'}>
            <Grid item>
              <AvatarCard user={user} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Card>
            <Tabs value={value} onChange={this.handleChange}>
              <Tab label='Settings' />
            </Tabs>
            {value === 0 && (
              <TabContainer>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label='First Name'
                          value={user.firstName}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label='First Name'
                          value={user.lastName}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label='Email'
                          value={user.email}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                </CardContent>
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <CardContent>
                  <Typography variant='h6'>Settings</Typography>
                  graph
                </CardContent>
              </TabContainer>
            )}
          </Card>
        </Grid>
      </>
    );
  }
}

Profile.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Profile;
