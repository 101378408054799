import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import Button from '../../elements/Button';
import { Card, CardContent } from '../../containers/Card';
import DurationText from '../../elements/DurationText';
import ProgressCircle from '../../elements/ProgressCircle';
import DateTitle from '../../elements/DateTitle';
import ResultBox from '../../elements/ResultBox';
import { color, fontWeight } from '../../styles/style';
import { mediaMinDevice } from '../../styles/responsive';
import { MainScreen } from '../../containers/Main';

const OverviewText = styled.p`
  font-size: 1rem;
  margin-bottom: 0.5em;
  letter-spacing: 0.5px;
  color: ${color.blueDarkest};
  text-align: left;
  font-weight: ${fontWeight.regular};
`;

const Buttons = styled(Button)`
  &&&&& {
    margin-top: 1rem;
    ${mediaMinDevice.tabletL} {
      margin-top: 3rem;
    }
  }
`;

const QuestionEnd = props => {
  const { guide, guideAction } = props;
  const title = `${guide.name} Complete`;
  const { questionsTotal, questionsCorrect } = guide;
  const questionsWrong = questionsTotal - questionsCorrect;
  const percentResult = (questionsCorrect / questionsTotal) * 100;
  const statColor = percentResult >= 65 ? color.primary : color.redMain;
  const guideType = guide.type === 'test' ? 'Test' : 'Study Aid';
  return (
    <MainScreen title={title}>
      <Grid item xs={12} sm={8}>
        <DateTitle>{guide.completedDate}</DateTitle>
        <DurationText textAlign='left'>{guide.duration}</DurationText>
        <OverviewText>
          Congratulations! You have completed the {guideType}. Click the
          &#8216;Review&#8216; Button to review this {guideType}, or click &#8216;Done&#8216; to return
          to the Dashboard.
        </OverviewText>
      </Grid>
      <Grid item xs={6} sm={3}>
        <ProgressCircle stat={percentResult} fontsize={9} clr={statColor} />
      </Grid>

      <Grid item xs={12} sm>
        <Card>
          <CardContent>
            <ResultBox stat={questionsTotal} label='Questions' />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm>
        <Card>
          <CardContent>
            <ResultBox stat={questionsCorrect} label='Correct' type='correct' />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm>
        <Card>
          <CardContent>
            <ResultBox stat={questionsWrong} label='Incorrect' type='wrong' />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          justify='space-between'
          alignItems='center'
          spacing={24}
        >
          <Grid item>
            <Buttons variant='outlined' component={Link} to={`/`}>
              DONE
            </Buttons>
          </Grid>
          <Grid item>
            <Buttons color='secondary' onClick={guideAction}>
              REVIEW
            </Buttons>
          </Grid>
        </Grid>
      </Grid>
    </MainScreen>
  );
};

QuestionEnd.propTypes = {
  guide: PropTypes.object,
  guideAction: PropTypes.func,
};

export default QuestionEnd;
