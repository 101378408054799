import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../elements/Button';
import PromoImageFile from '../../assets/images/PromoImage.png';
import {
  PromoWrapper,
  PromoCopy,
  PromoText,
  PromoTitle,
  PromoButtonArea,
  PromoImageArea,
  PromoImage,
} from './StyledBasicBanner';

const BasicBanner = props => {
  const { isCol, promo } = props;

  return (
    <PromoWrapper isCol={isCol}>
      {!isCol && (
        <PromoImageArea>
          <PromoImage alt="Additional MBE Resources" src={PromoImageFile} />
        </PromoImageArea>
      )}

      <PromoCopy isCol={isCol}>
        <PromoTitle isCol={isCol}>{promo.title}</PromoTitle>
        <PromoText>{promo.content}</PromoText>
      </PromoCopy>
      <PromoButtonArea isCol={isCol}>
        <Button
          href={promo.btnUrl}
          color='secondary'
          variant='contained'
          target='_blank'
        >
          {promo.btnLabel}
        </Button>
      </PromoButtonArea>
    </PromoWrapper>
  );
};

BasicBanner.propTypes = {
  promo: PropTypes.object.isRequired,
  isCol: PropTypes.bool.isRequired,
};
export default BasicBanner;
