import styled from 'styled-components/macro';
import { color, fontWeight } from '../../../../../styles/style';

export const QTWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: ${color.blueDarkest};
  text-align: left;
  font-weight: ${fontWeight.bold};
`;

export const Value = styled.div`
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  text-align: right;
  font-weight: ${fontWeight.bold};
`;
