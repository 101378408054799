import React from 'react';
import MuiCardContent from '@material-ui/core/CardContent';
import styled from 'styled-components/macro';

const Content = styled(({ paddingVertical, ...props }) => <MuiCardContent {...props} />)`
  ${({ paddingVertical }) => paddingVertical >= 0 && `
  padding-top: ${paddingVertical};
  padding-bottom: ${paddingVertical};
  `}
`;

const CardContent = ({ children, ...others }) => {
  const { paddingVertical, ...props } = others;

  return <Content {...props} paddingVertical={paddingVertical}>{children}</Content>;
};

export default CardContent;
