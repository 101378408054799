import { TableCell } from '@material-ui/core';
import styled from 'styled-components/macro';
import { color } from '../../styles/style';

export const SmTableCell = styled(TableCell)`
  min-width: 6rem
`;

export const MdTableCell = styled(TableCell)`
  min-width: 8rem
`;

export const LgTableCell = styled(TableCell)`
  min-width: 10rem
`;

export const XlTableCell = styled(TableCell)`
  min-width: 12rem
`;

export const EmptyDataMessage = styled.div`
  text-align: center;
  padding: 2rem;
  color: ${color.blueLight};
  font-weight: 700;
`;