import styled from 'styled-components/macro';
import { mediaMinDevice } from '../../../styles/responsive';
import { Typography, Grid } from '@material-ui/core';

export const SetupScreen = styled.div`
  top: 5%;
  position: relative;
  width: 100%;
  margin: 0 1rem;
  padding-bottom: 2rem;
  ${mediaMinDevice.tabletL} {
    width: 80%;
    max-width: 1000px;
  }
`;

export const Logo = styled.img`
  margin: 0 0 2rem;
  width: 40%;
  max-width: 180px;
  ${mediaMinDevice.tabletL} {
    width: auto;
  }
`;

export const DataContainer = styled(Grid)`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const SectionTitle = styled(Typography)`
  margin-bottom: 1.5rem;
`;

export const ReadOnlyValue = styled.span`
  display: inline-block;
  margin-top: .5rem;
  font-size: 1rem
`;
