import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import QuestionTotal from './QuestionTotal';
import SubjectSelector from './SubjectSelector';
import { study } from '../../../../_CONS/qbanks';
import Loading from '../../../../components/Loading';
import { MainScreen } from '../../../../containers/Main';
import { Card, CardContent } from '../../../../containers/Card';
import { clearGuide } from '../../../../store/actions/guideActions';
import { createStudyGuide } from '../../../../store/actions/builderActions';
import {
  styles,
  StepHeader,
  StepHeaderIcon,
  StepHeaderLabel,
  StepSvg,
  StepSvgCircle,
  StepSvgTxt,
  CancelBtn,
  SubmitBtn,
} from './StyledStudyGuideBuilder';
import ErrorBoundary from '../../../../components/ErrorBoundary';

class StudyGuideBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectCodes: {},
      selectedSubjects: [],
      questionsTotal: 0,
      showFeedback: true,
      showNotes: false,
      showTimer: false,
      showQuestionNavigator: true,
      studyGuideConfig: {},
      newStudyGuide: true,
      isBuilding: false,
    };
  }

  componentDidMount() {
    this.props.clearGuide();
    const subjectCodes = study.reduce((obj, item) => ({
      ...obj,
      [item.value]: { ...item, num: 0, topics: [] },
    }), {});

    this.setState({
      subjectCodes,
      questionsTotal: 0,
      isBuilding: false,
    });
  }

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  changeSubjectNum = data => {
    const { subject, number, topics } = data;
    const { subjectCodes } = this.state;

    const newSubjectCodes = {
      ...subjectCodes,
      [subject]: {
        ...subjectCodes[subject],
        num: Number(number),
        topics,
      },
    };

    const questionsTotal = Object.keys(newSubjectCodes).reduce((sum, code) => (
      sum + newSubjectCodes[code].num
    ), 0);

    this.setState({
      subjectCodes: newSubjectCodes,
      questionsTotal,
    });
  }

  submitStudyGuideBuild = event => {
    this.setState({ isBuilding: true });
    const {
      studyGuideConfig,
      subjectCodes,
      showFeedback,
      showTimer,
      showNotes,
    } = this.state;
    const newConfig = {
      ...studyGuideConfig,
      subjectCodes,
      showNotes,
      showFeedback,
      showTimer,
    };

    const gObj = {
      section: 'study',
      guideType: 'studyguides',
      status: 'getting',
    };

    event.preventDefault();
    this.props.createStudyGuide(newConfig, gObj).then(() => {
      this.setState({ studyGuideConfig: newConfig, isBuilding: false });
    });
  };

  render() {
    const { isBuilding, showTimer, showFeedback, questionsTotal } = this.state;
    const { classes, newGuideId, buildStatus, levelQbank, auth } = this.props;

    const isInvalid = questionsTotal < 1;

    if (buildStatus === 'success')
      return (
        <Redirect
          to={{
            pathname: '/student/study/guide',
            state: { id: `${newGuideId}` },
          }}
        />
      );
    return (
      <MainScreen title='Study Aid Builder'>
        {isBuilding && (
          <>
            <Loading />
          </>
        )}
        {buildStatus === '' ? (
          <>
            <Grid item xs={12} sm={8}>
              <StepHeader>
                <StepHeaderIcon>
                  <StepSvg viewBox='0 0 24 24'>
                    <StepSvgCircle cx='12' cy='12' r='12' />
                    <StepSvgTxt x='12' y='16' textAnchor='middle'>
                      1
                    </StepSvgTxt>
                  </StepSvg>
                </StepHeaderIcon>
                <StepHeaderLabel>
                  Select subject(s) and number of questions
                </StepHeaderLabel>
              </StepHeader>

              <ErrorBoundary>
                <Grid
                  container
                  direction='column'
                  justify='flex-start'
                  spacing={2}
                >
                  {study.map(item => (
                    <SubjectSelector
                      key={item.value}
                      studentId={auth.uid}
                      collection={levelQbank}
                      data={item}
                      changeNum={this.changeSubjectNum}
                    />
                  ))}
                </Grid>
              </ErrorBoundary>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Grid container>
                <StepHeader>
                  <StepHeaderIcon>
                    <StepSvg viewBox='0 0 24 24'>
                      <StepSvgCircle cx='12' cy='12' r='12' />
                      <StepSvgTxt x='12' y='16' textAnchor='middle'>
                        2
                      </StepSvgTxt>
                    </StepSvg>
                  </StepHeaderIcon>
                  <StepHeaderLabel>Choose preferences</StepHeaderLabel>
                </StepHeader>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={showFeedback}
                              onChange={this.handleChange('showFeedback')}
                              value='showFeedback'
                              classes={{
                                switchBase: classes.iOSSwitchBase,
                                checked: classes.iOSChecked,
                              }}
                              disableRipple
                            />
                          }
                          label={
                            showFeedback
                              ? 'Show Explanation'
                              : 'Hide Explanation'
                          }
                        />
                      </FormGroup>

                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={showTimer}
                              onChange={this.handleChange('showTimer')}
                              value='showTimer'
                              color='secondary'
                              classes={{
                                switchBase: classes.iOSSwitchBase,
                                checked: classes.iOSChecked,
                              }}
                              disableRipple
                            />
                          }
                          label={showTimer ? 'Show Timer' : 'Hide Timer'}
                        />
                      </FormGroup>
                    </CardContent>
                    <Divider />
                    <CardContent>
                      <QuestionTotal total={questionsTotal} />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='space-between' spacing={3}>
                <CancelBtn
                  variant='outlined'
                  container='default'
                  onClick={this.handleCancel}
                >
                  CANCEL
                </CancelBtn>
                <SubmitBtn
                  disabled={isInvalid}
                  onClick={this.submitStudyGuideBuild}
                >
                  START
                </SubmitBtn>
              </Grid>
            </Grid>
          </>
        ) : (
          <Loading />
        )}
      </MainScreen>
    );
  }
}

const mapStateToProps = ({ guideStatus, firebase, firestore }) => ({
  auth: firebase.auth,
  student: firestore.data.student,
  levelQbank: firebase.profile.studentBank,
  buildStatus: guideStatus.status,
  newGuideId: guideStatus.guideId ? guideStatus.guideId : null,
});

const mapDispatchToProps = {
  clearGuide,
  createStudyGuide,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(StudyGuideBuilder);
