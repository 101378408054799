import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ReviewDash from './ReviewDash';
import GuideOverview from './GuideOverview';
import GuideReview from '../../../screens/GuideReview';
import StudyAidInfo from './StudyAidInfo';

const Review = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/studyguides/overview/guide`}
      component={GuideReview}
    />
    <Route
      path={`${match.url}/studyguides/overview`}
      component={GuideOverview}
    />
    <Route path={`${match.url}/studyguides/review`} component={GuideReview} />
    <Route
      path={`${match.url}/testguides/overview/guide`}
      component={GuideReview}
    />
    <Route
      path={`${match.url}/testguides/overview`}
      component={GuideOverview}
    />
    <Route
      path={`${match.url}/study-aid-info`}
      component={StudyAidInfo}
    />
    <Route path={`${match.url}`} component={ReviewDash} />
  </Switch>
);

export default Review;
