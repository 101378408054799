import styled from 'styled-components/macro';

export const MainContent = styled.main`
  flex: 1;
  width: 80%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
`;
MainContent.propTypes = {};

export default MainContent;
