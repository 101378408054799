import styled from 'styled-components/macro';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { color } from '../../../styles/style';

export const PagerButton = styled.button`
  display: flex;
  justift-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1rem;
  color: ${props => (props.disabled ? color.blueLightest : color.dark)};
  border: 0 none;
  background: transparent;
  cursor: pointer;
  outline: none;
  transition: color 200ms;
  &:hover {
    color: ${props => (props.disabled ? color.blueLightest : color.primary)};
  }
`;

export const IconPageLeft = styled(KeyboardArrowLeft)`
  &&&& {
    width: 30px;
    height: 30px;
  }
`;
export const IconPageRight = styled(KeyboardArrowRight)`
  &&&& {
    width: 30px;
    height: 30px;
  }
`;

export const QiBoxPager = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PagerDots = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
