import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { ReactNode } from 'react';
import Loading from '../Loading';
import styled from 'styled-components/macro';

type TProps = {
  typeId?: string,
  title?: string,
  open: boolean,
  isLoading?: boolean,
  content?: string | ReactNode,
  buttonLabels?: string[],
  payload?: any,
  fullWidth?: boolean,
  type: 'alert' | 'confirm',
  maxWidth?: 'xs' | 'md' | 'lg' | 'xl',
  onConfirm?: (type: string, payload?: any) => void,
  onCancel?: (type: string) => void
}

// @ts-ignore
const Container = styled(({ isLoading, ...props }: TProps) => <MuiDialog {...props} />)`
  & .MuiDialog-paper {
    min-width: 10rem;
    min-height: ${({ isLoading }) => isLoading ? '10rem' : 'unset'} 
  }
`;

const Dialog: React.FC<TProps> = ({
  typeId,
  title = '',
  content = '',
  isLoading,
  open,
  type = 'alert',
  payload,
  buttonLabels = [],
  onConfirm,
  onCancel,
  ...props
}) => {
  const handleAction = (action: string) => {
    if (action === 'cancel') {
      onCancel(typeId);
    } else if (action === 'confirm' && onConfirm) {
      onConfirm(typeId, payload);
    }
  }

  if (!open) {
    return null;
  }

  return (
// @ts-ignore
    <Container
      open={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      isLoading={isLoading}
      role="dialog"
      data-dialog-type={type}
      {...props}
    >
      {
        isLoading ? (
          <Loading />
        ) : (
          <>
            <DialogTitle> {title}</DialogTitle>

            <DialogContent>
              <DialogContentText>{content}</DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                variant='text'
                key='ok'
                color='default'
                aria-label={buttonLabels[0] || 'OK'}
                data-testid={'cancel'}
                onClick={() => handleAction('cancel')}
              >
                {buttonLabels[0] || 'OK'}
              </Button>

              {
                !!buttonLabels[1] && type === "confirm" && (
                  <Button
                    variant='text'
                    key='confirm'
                    color='secondary'
                    aria-label={buttonLabels[1]}
                    data-testid={'confirm'}
                    onClick={() => handleAction('confirm')}
                  >
                    {buttonLabels[1]}
                  </Button>
                )
              }
            </DialogActions>
          </>
        )
      }
    </Container>
  )
}

export default Dialog;
