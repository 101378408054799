// @ts-nocheck
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { signOut } from '../../store/actions/authActions';
import Divider from '@material-ui/core/Divider';

const menuItems = [
  {
    name: 'profile',
    label: 'PROFILE',
  },
  {
    name: 'users',
    label: 'USERS',
    role: 'admin',
  },
];

class UserMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  onClickItem = name => () => {
    const { history, match } = this.props;
    history.push(`${match.url}/${name}`);
    this.setState({ anchorEl: null });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const open = !!anchorEl;
    const { initials } = this.props;

    return (
      <div>
        <IconButton
          aria-owns={open ? 'menu-appbar' : null}
          aria-haspopup='true'
          onClick={this.handleMenu}
          color='inherit'
          data-testid='navbarAvatar'
        >
          <Avatar>{initials}</Avatar>
        </IconButton>

        <Menu
          id='menu-appbar'
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={this.handleClose}
        >
          {menuItems.map(({ name, label, role }) => (
            (!role || role === this.props.role) && (
              <MenuItem key={name} onClick={this.onClickItem(name)}>{label}</MenuItem>
            )
          ))}
          <Divider />
          <MenuItem onClick={this.props.signOut} data-testid='logOut'>LOG OUT</MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  initials: state.firebase.profile.initials,
  role: state.firebase.profile.role,
});

const mapDispatchToProps = { signOut };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserMenu)
);
