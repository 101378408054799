export const AlertActions = {
  SHOW: 'alert/showed',
  HIDE: 'alert/hidden',
};

type TAlertData = {
  title: string,
  message: string,
}

export const showAlert = (data: TAlertData) => ({
  type: AlertActions.SHOW,
  payload: data,
});

export const hideAlert = () => ({
  type: AlertActions.HIDE,
});
