import styled from 'styled-components/macro';
import { color, fontWeight } from '../../styles/style';
const AuthErrorTxt = styled.div`
  color: ${color.redMain};
  font-weight: ${fontWeight.semiBold};
  font-size: 1rem;
  text-align: center;
`;

export default AuthErrorTxt;
