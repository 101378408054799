import styled from 'styled-components/macro';
import SimpleBtn from '../../../../elements/SimpleBtn';
import { color } from '../../../../styles/style';

export const PreviewFooterWrapper = styled.footer`
  background: ${color.blueLightest};
  width: 100%;
`;

export const PreviewFooterAreaControls = styled.div`
  display: flex;
  justify-content: space-between;
  height: 6rem;
  align-items: center;
  max-width: 1000px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

export const ToolbarControls = styled.div`
  display: flex;
  flex-basis: 50%;
  max-width: 420px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

export const Btn = styled(SimpleBtn)`
  &&& {
    margin-right: 10px;
  }
`;
