import React from 'react';
import styled from 'styled-components/macro';
import { color, fontWeight } from '../../styles/style';
import { mediaMinDevice } from '../../styles/responsive';
import format from 'date-fns/format';
import isDate from 'date-fns/is_date';

const DateStyle = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: ${color.blueDarkest};
  text-align: right;
  font-weight: ${fontWeight.bold};

  ${mediaMinDevice.mobileL} {
    font-size: 1.2rem;
  }
`;
const DateTime = props => {
  if (props.children === undefined) {
    return <DateStyle>&#8216;error&#8216;</DateStyle>;
  }
  const raw = props.children;
  let time;
  if (isDate(raw)) {
    time = raw;
  } else {
    time = raw.toDate();
  }
  return <DateStyle>{format(time, 'MM/DD/YY - h:mmA')}</DateStyle>;
};

DateTime.propTypes = {};

export default DateTime;
