import React from 'react';
import styled from 'styled-components/macro';
import { util, color } from '../../../styles/style';

const Qi = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;
  cursor: pointer;
  transition: all 300ms;
  &:hover {
    opacity: 0.85;
  }
`;

const QiActive = styled(Qi)`
  color: ${color.primary};
  border: ${util.borderWidth} solid ${color.primary};
  background-color: ${color.white};
`;

const QiUnanswered = styled(Qi)`
  color: ${color.white};
  border: ${util.borderWidth} solid ${color.dark};
  background-color: ${color.dark};
`;

const QiAnswered = styled(Qi)`
  color: ${color.white};
  border: ${util.borderWidth} solid ${color.primary};
  background-color: ${color.primary};
`;

const QiCorrect = styled(Qi)`
  color: ${color.white};
  border: ${util.borderWidth} solid ${color.secondary};
  background-color: ${color.secondary};
`;

const QiWrong = styled(Qi)`
  color: ${color.white};
  border: ${util.borderWidth} solid ${color.dangerDark};
  background-color: ${color.dangerDark};
`;

const QiLabel = styled.p`
  font-size: 16px;
`;

const QuestionIndicator = props => {
  const { isActive, question, label, showFeedback } = props;
  const { answered, isCorrect } = question;

  if (isActive) {
    return (
      <QiActive>
        <QiLabel>{label}</QiLabel>
      </QiActive>
    );
  } else {
    if (answered) {
      if (showFeedback && isCorrect) {
        return (
          <QiCorrect>
            <QiLabel>{label}</QiLabel>
          </QiCorrect>
        );
      }
      if (showFeedback && !isCorrect) {
        return (
          <QiWrong>
            <QiLabel>{label}</QiLabel>
          </QiWrong>
        );
      }
      return (
        <QiAnswered>
          <QiLabel>{label}</QiLabel>
        </QiAnswered>
      );
    }
  }

  return (
    <QiUnanswered>
      <QiLabel>{label}</QiLabel>
    </QiUnanswered>
  );
};

export default QuestionIndicator;
