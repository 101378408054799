// @ts-nocheck
import styled, { css } from 'styled-components/macro';
import { color, util, fontWeight, fontType } from '../../styles/style';

export const PromoWrapper = styled.div`
  background-color: ${color.blueDarkest};
  padding: ${util.padding};
  border-radius: ${util.radiusSmall};
  font-size: ${fontType.copy};
  color: ${color.blueLightest};
  display: flex;
  flex-grow: 1;
  flex-direction: ${props => (props.isCol ? 'column' : 'row')};
  justify-content: space-between;
`;

export const PromoCopy = styled.div`
  ${props => !props.isCol && css`
    flex-basis: 50%;
  `};
`;
export const PromoText = styled.p`
  font-weight: ${fontWeight.semiBold};
`;

export const PromoTitle = styled.div`
  color: white;
  font-size: ${fontType.subTitle};
  font-weight: ${fontWeight.bold};
  margin: 0;

  ${props => !props.isCol && css`
    margin-bottom: 5px;
  `};
`;
export const PromoButtonArea = styled.div`
  ${props => !props.isCol && css`
    flex-basis: 30%;
    text-align: center;
    align-self: center;
  `};
`;

export const PromoImageArea = styled.div`
  flex-basis: 20%;
  max-width: 100px;
`;

export const PromoImage = styled.img`
  width: 100%;
  border-radius: .25rem;
  border: 2px solid #eee;
`;
