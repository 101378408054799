import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Logo from '../../assets/logos/JD-Advising_Logo.svg';
import UserMenu from '../UserMenu';
import Help from './Help';
import { Nav, NavRight, LogoImage } from './StyledNavBar';

const NavAppBar = props => {
  return (
    <AppBar position='relative' color='default'>
      <Toolbar variant='regular'>
        <LogoImage src={Logo} alt='logo' />

        <Nav>{props.children}</Nav>

        <NavRight>
          <Help />
          {props.auth && <UserMenu />}
        </NavRight>
      </Toolbar>
    </AppBar>
  );
};

NavAppBar.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default NavAppBar;
