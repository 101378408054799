import React from 'react';
import PagerDot from '../../../assets/icons/PagerDot';
import {
  PagerButton,
  IconPageLeft,
  IconPageRight,
  QiBoxPager,
  PagerDots,
} from './StyledQiPager';

const QiPager = props => {
  const nextPage = () => {
    props.navAction('next');
  };

  const prevPage = () => {
    props.navAction('prev');
  };

  const { pages, pageIndex } = props;
  return (
    <QiBoxPager>
      <PagerButton disabled={pageIndex === 0} onClick={prevPage} variant='text'>
        <IconPageLeft />
        BACK
      </PagerButton>
      <PagerDots>
        {pages.map((dots, index) => (
          <PagerDot key={index} isActive={index === pageIndex} />
        ))}
      </PagerDots>

      <PagerButton
        disabled={pageIndex + 1 >= pages.length}
        variant='text'
        onClick={nextPage}
      >
        NEXT <IconPageRight />
      </PagerButton>
    </QiBoxPager>
  );
};

export default QiPager;
