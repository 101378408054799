import { FormControlLabel, Typography } from "@material-ui/core";
import styled from 'styled-components/macro';
import { fontSize } from "../../styles/style";

export const TimerCheckbox = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-size: ${fontSize.questionCopy};
  }
`;

export const TimeLabel = styled(Typography)`
  line-height: 1.1876em;
  margin-right: 1em
`;
