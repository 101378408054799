import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import IconButton from '@material-ui/core/IconButton';
import IconEdit from '@material-ui/icons/Edit';
import BasicBanner from '../../../promos/BasicBanner';
import EditBanner from '../../../promos/EditBanner';

const EditPromoWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const EditIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const EditIcon = styled(IconEdit)`
  color: #fff;
`;

class EditPromo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      isDirty: false,
    };
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.savePromo = this.savePromo.bind(this);
  }

  toggleEditMode() {
    this.setState(state => ({
      editMode: !state.editMode,
    }));
  }

  savePromo() {
    this.setState({ editMode: false });
  }

  render() {
    const { editMode } = this.state;
    const { promo, pid } = this.props;

    return editMode ? (
      <EditPromoWrapper>
        <EditBanner
          pid={pid}
          promo={promo}
          onCancel={this.toggleEditMode}
          onUpdate={this.savePromo}
        />
      </EditPromoWrapper>
    ) : (
      <EditPromoWrapper>
        <EditIconWrapper>
          <IconButton
              color='default'
              aria-label='Add'
              onClick={this.toggleEditMode}
            >
            <EditIcon />
          </IconButton>
        </EditIconWrapper>
        <BasicBanner isCol={false} promo={promo} />
      </EditPromoWrapper>
      );
  }
}

EditPromo.propTypes = {
  pid: PropTypes.string.isRequired,
  promo: PropTypes.object.isRequired,
};

export default EditPromo;
