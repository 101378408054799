import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import IconEdit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardDirty,
} from '../../containers/Card';
import Button from '../../elements/Button';
import { color, fontWeight } from '../../styles/style';

const styles = {
  card: {
    display: 'flex',
    width: '100%',
  },
  content: {
    paddingTop: 0,
    fontSize: 14,
    fontWeight: fontWeight.semiBold,
    color: color.blueDarkest,
    height: '100%',
  },
  questionCopy: {
    backgroundColor: color.grey,
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: 14,
    lineHeight: 'initial',
    fontWeight: fontWeight.regular,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

class CardFieldNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: true,
      oldContent: props.content,
      newContent: props.content,
    };
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.saveFieldValue = this.saveFieldValue.bind(this);
    this.cancelFieldChange = this.cancelFieldChange.bind(this);
  }

  toggleEditMode() {
    this.setState(state => ({
      editMode: !state.editMode,
    }));
  }
  cancelFieldChange() {
    const { oldContent } = this.state;
    this.setState({ newContent: oldContent, editMode: true });
  }
  saveFieldValue() {
    const { newContent } = this.state;

    this.props.cardAction(newContent);

    this.setState(state => ({
      oldContent: newContent,
      editMode: !state.editMode,
    }));
  }
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const {
      classes,
      label,
      isMultiline,
      ...other
    } = this.props;
    const { editMode, newContent, oldContent } = this.state;
    return (
      <Grid item xs={12}>
        {editMode ? (
          <Card>
            <CardDirty isDirty={newContent !== oldContent}>
              <CardHeader
                action={
                  <IconButton
                    onClick={this.toggleEditMode}
                    disabled={newContent !== oldContent}
                  >
                    <IconEdit />
                  </IconButton>
                }
                title={label}
              />

              <CardContent className={classes.content}>
                <Input
                  id='standard-multiline-flexible'
                  {...other}
                  value={this.state.newContent}
                  disableUnderline
                  multiline={isMultiline}
                  onChange={this.handleChange('newContent')}
                  className={classes.questionCopy}
                  fullWidth
                />
              </CardContent>
              {oldContent !== newContent && (
                <CardContent className={classes.actions}>
                  <Button
                    color='default'
                    variant='text'
                    onClick={this.cancelFieldChange}
                  >
                    CANCEL
                  </Button>
                  <Button
                    color='primary'
                    variant='text'
                    onClick={this.saveFieldValue}
                    disabled={newContent === oldContent}
                  >
                    SAVE
                  </Button>
                </CardContent>
              )}
            </CardDirty>
          </Card>
        ) : (
          <Card>
            <CardHeader
              action={
                <IconButton onClick={this.toggleEditMode}>
                  <IconEdit />
                </IconButton>
              }
              title={label}
            />

            <CardContent
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html: this.props.content,
              }}
            />
          </Card>
        )}
      </Grid>
    );
  }
}

CardFieldNew.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CardFieldNew);
