import { get } from 'lodash';

export function sortObjects(a: any, b: any, orderBy: string | number, order: 'desc' | 'asc' = 'asc') {
  if (order === 'asc') {
    if (!a || get(a, orderBy) === undefined) {
      return -1
    } else if (!b || get(b, orderBy) === undefined) {
      return 1
    } else if (get(b, orderBy) < get(a, orderBy)) {
      return 1;
    } else if (get(b, orderBy) > get(a, orderBy)) {
      return -1;
    }

    return 0;
  } else {
    if (!a || get(a, orderBy) === undefined) {
      return 1
    } else if (!b || get(b, orderBy) === undefined) {
      return -1
    } else if (get(b, orderBy) < get(a, orderBy)) {
      return -1;
    } else if (get(b, orderBy) > get(a, orderBy)) {
      return 1;
    }

    return 0;
  }
}
