// @ts-nocheck
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import React from 'react';

type TProps = {
  [key: string]: any,
}

type TState = {
  hasError: boolean
}

export const withErrorBoundary = (Child: typeof React.Component) => (props: any) => (
  <ErrorBoundary>
    <Child {...props} />
  </ErrorBoundary>
)

class ErrorBoundary extends React.Component<TProps, TState> {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(err: any) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant={'body1'}>
                The error happened inside the component. Try to reload the page.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary
