import { AlertActions } from "../actions/alertActions";
import { TStateAction } from "../types";

type TAlertState = {
  open: boolean,
  title: string,
  message: string,
}

const initState: TAlertState = {
  open: false,
  title: '',
  message: '',
};

const alertReducer = (state: TAlertState = initState, action: TStateAction) => {
  switch (action.type) {
    case AlertActions.SHOW:
      return {
        ...action.payload,
        open: true,
      };

    case AlertActions.HIDE:
      return {
        open: false,
      };

    default:
      return state;
  }
};

export default alertReducer;
