import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Loading from '../../components/Loading';
import Button from '../../elements/Button';
import { addPromo } from '../../store/actions/promoActions';
import {
  EditBannerWrapper,
  EditBannerContainer,
  EditBannerActions,
  BannerImageArea,
  BannerImage,
  BannerContentArea,
  BannerButtonArea,
  ButtonLabel,
  ButtonUrl,
} from './StyledNewBanner';

import PromoImage from '../../assets/images/PromoImage.png';

class NewBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDirty: true,
      title: 'Need Extra Help? No problem.',
      content: 'We offer one-on-one tutoring packages to suit your personal study needs.',
      btnLabel: 'Read More',
      btnUrl: 'https://www.jdadvising.com/product/law-school-tutoring/',
      type: 'default',
    };
    this.addPromo = this.addPromo.bind(this);
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
      isDirty: true,
    });
  };

  addPromo() {
    const { title, content, btnLabel, btnUrl, type } = this.state;
    const uObj = {
      title,
      content,
      btnLabel,
      btnUrl,
      type,
    };

    this.props.addPromo('default', uObj);
  }

  render() {
    const { isDirty, title, content, btnLabel, btnUrl } = this.state;
    const isLoading = this.props.status === 'loading';

    if (isLoading) {
      return (
        <EditBannerWrapper>
          <Loading />
        </EditBannerWrapper>
      );
    }

    return (
      <form>
        <EditBannerWrapper>
          <EditBannerContainer>
            <BannerImageArea>
              <BannerImage alt="Additional MBE Resources" src={PromoImage} />
            </BannerImageArea>
            <BannerContentArea>
              <FormControl margin='none' fullWidth>
                <InputLabel htmlFor='title'>Title</InputLabel>
                <Input
                  type='text'
                  name='title'
                  value={title}
                  id='title'
                  onChange={this.handleChange}
                />
              </FormControl>
              <FormControl margin='normal' fullWidth>
                <InputLabel htmlFor='content'>Content</InputLabel>
                <Input
                  type='content'
                  name='content'
                  value={content}
                  id='content'
                  multiline
                  onChange={this.handleChange}
                />
              </FormControl>
              <BannerButtonArea>
                <ButtonLabel>
                  <FormControl margin='normal'>
                    <InputLabel htmlFor='btnLabel'>Button Label</InputLabel>

                    <Input
                      type='btnLabel'
                      name='btnLabel'
                      value={btnLabel}
                      id='btnLabel'
                      onChange={this.handleChange}
                    />
                  </FormControl>
                </ButtonLabel>
                <ButtonUrl>
                  <FormControl margin='normal' fullWidth>
                    <InputLabel htmlFor='btnUrl'>URL</InputLabel>

                    <Input
                      type='btnUrl'
                      name='btnUrl'
                      value={btnUrl}
                      id='btnUrl'
                      onChange={this.handleChange}
                    />
                  </FormControl>
                </ButtonUrl>
              </BannerButtonArea>
            </BannerContentArea>
          </EditBannerContainer>
          <EditBannerActions>
            <Button
              color='primary'
              variant='text'
              onClick={this.addPromo}
              disabled={!isDirty}
            >
              SAVE
            </Button>
          </EditBannerActions>
        </EditBannerWrapper>
      </form>
    );
  }
}

NewBanner.propTypes = {};

const mapStateToProps = state => ({
  status: state.promoReducer.status,
});

const mapDispatchToProps = { addPromo };

export default connect(mapStateToProps, mapDispatchToProps)(NewBanner);
