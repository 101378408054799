import styled from 'styled-components/macro';
import { color, fontWeight } from '../../../../styles/style';
import { mediaMinDevice } from '../../../../styles/responsive';

export const OverviewTableWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  width: 102%;
  
  ${mediaMinDevice.tablet} {
    overflow-x: hidden;
  }
`;

export const TableTitles = styled.p`
  margin: 0;
  color: ${color.blueDarkest};
  font-weight: ${fontWeight.bold};
  font-size: 0.8rem;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  text-transform: uppercase;
`;

export const TableSubjectTitle = styled.p`
  margin: 0;
  color: ${color.blueDarkest};
  font-weight: ${fontWeight.bold};
  font-size: 0.8rem;
  text-align: left;
  text-transform: uppercase;
`;

export const DurationText = styled.p`
  margin: 0;
  font-size: 1rem;
  color: ${color.blueDarkest};
  text-align: right;
  font-weight: 700;
  ${mediaMinDevice.tablet} {
    font-size: 1.2rem;
  }
`;

export const TableFooter = styled.div`
  display: block;
  ${mediaMinDevice.mobileL} {
    display: flex;
    justify-content: space-between;
  }
`;

export const styles = {
  root: {
    width: '100%',
  },
  buttonRed: {
    color: '#ffffff',
    backgroundColor: color.redMain,
    '&:hover': {
      backgroundColor: color.redDarkest,
    },
    marginBottom: 10,
    [mediaMinDevice.tablet]: {
      marginBottom: 0,
    },
  },
  tableTitleRow: {
    borderTop: `1px solid ${color.blueLightest}`,
    borderBottom: `1px solid ${color.blueLightest}`,
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 20,
    paddingRight: 20,
    flexWrap: 'nowrap',
    minWidth: 720,
  },
  container: {
    flexWrap: 'nowrap',
  },
  topRow: {
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: 'center',
  },
};
