import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import subjectTopics from '../../_CONS/subjectTopics';
import { CopyTopic, CopyTopicTitle, styles } from './StyledPanelGuideTopics';

const PanelGuideTopics = props => {
  const { classes, qObj } = props;
  let hasError = false;
  const classErrors = [];
  const { classification, subjectCode } = qObj;
  if (classification === undefined) {
    return (
      <Card className={classes.card}>
        <CardContent>
          <CopyTopicTitle>ERROR</CopyTopicTitle>
          <CopyTopic>No Classification</CopyTopic>
        </CardContent>
      </Card>
    );
  }
  const classRaw = classification[0].split('-');
  const subjectLabel = subjectTopics[subjectCode].label;

  const topicCode = classRaw[0].trim();
  const getTopicLabel = subjectTopics[subjectCode].topics[topicCode];
  let topicLabel;
  if (getTopicLabel === undefined) {
    return (
      <Card className={classes.card}>
        <CardContent>
          <CopyTopicTitle>ERROR</CopyTopicTitle>
          <CopyTopic>No Topic Code</CopyTopic>
        </CardContent>
      </Card>
    );
  } else {
    topicLabel = subjectTopics[subjectCode].topics[topicCode].label;
  }

  let subTopicCode;
  let subTopicLabel = '';
  if (classRaw.length > 1) {
    subTopicCode = classRaw[1].trim();
    const getSubTopics =
      subjectTopics[subjectCode].topics[topicCode].subtopics[subTopicCode];
    if (getSubTopics === undefined) {
      hasError = true;
      classErrors.push(' ---- no sub topic code');
    } else {
      subTopicLabel = `• ${subjectTopics[subjectCode].topics[topicCode].subtopics[subTopicCode]
        .label
        } `;
    }
  }

  let subSubTopicCode;
  let subSubTopicLabel = '';
  if (classRaw.length > 2) {
    subSubTopicCode = classRaw[2].trim();
    const getSubSubTopic =
      subjectTopics[subjectCode].topics[topicCode].subtopics[subTopicCode]
        .subsubtopics[subSubTopicCode];
    if (getSubSubTopic === undefined) {
      hasError = true;
      classErrors.push(' ---- no sub sub topic code');
    } else {
      subSubTopicLabel = `• ${subjectTopics[subjectCode].topics[topicCode].subtopics[subTopicCode]
        .subsubtopics[subSubTopicCode].label
        }`;
    }
  }
  let subSubSubTopicCode;
  let subSubSubTopicLabel = '';
  if (classRaw.length > 3) {
    subSubSubTopicCode = classRaw[3].trim();
    const getSubSubSubTopic =
      subjectTopics[subjectCode].topics[topicCode].subtopics[subTopicCode]
        .subsubtopics[subSubTopicCode].subsubsubtopics[subSubSubTopicCode];
    if (getSubSubSubTopic === undefined) {
      hasError = true;
      classErrors.push(' ---- no sub sub sub topic code');
    } else {
      subSubSubTopicLabel = `• ${subjectTopics[subjectCode].topics[topicCode].subtopics[subTopicCode]
        .subsubtopics[subSubTopicCode].subsubsubtopics[subSubSubTopicCode]
        .label
        }`;
    }
  }

  if (hasError) {
    return (
      <Card className={classes.card}>
        <CardContent>
          <CopyTopicTitle>ERROR</CopyTopicTitle>
          <CopyTopic>{classErrors.map(err => `${err}`)}</CopyTopic>
        </CardContent>
      </Card>
    );
  } else {
    const topicstring = `${topicLabel} ${subTopicLabel} ${subSubTopicLabel} ${subSubSubTopicLabel}`;

    return (
      <Card className={classes.card}>
        <CardContent>
          <CopyTopicTitle>Subject</CopyTopicTitle>
          <CopyTopic>{subjectLabel}</CopyTopic>
          <hr />
          <CopyTopicTitle>Topic</CopyTopicTitle>
          <CopyTopic>{topicstring}</CopyTopic>
        </CardContent>
      </Card>
    );
  }
};

PanelGuideTopics.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PanelGuideTopics);
