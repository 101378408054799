import React from 'react';
/*** ui and style ***/
import styled from 'styled-components/macro';
import { mediaMinDevice } from '../../../styles/responsive';

const drawerWidth = 300;
const contentShift = {
  width: `calc(100% - ${drawerWidth}px)`,
  transition: `all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms`,
};
const GuideQuestionArea = styled.div`
  width: 100%;
  ${props => props.desktopOpen && contentShift}
`;
const GuideQuestionContent = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 8rem;
  ${mediaMinDevice.tabletL} {
    padding-bottom: ${props => (props.isReview ? `6rem` : `9rem`)};
    padding-top: 6rem;
    max-width: 1000px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`;
const GuideQuestion = ({ children, isDesktop, isToolDrawerOpen, isReview }) => (
  <GuideQuestionArea desktopOpen={isDesktop && isToolDrawerOpen}>
    <GuideQuestionContent isDesktop={isDesktop} isReview={isReview}>
      {children}
    </GuideQuestionContent>
  </GuideQuestionArea>
);

export default GuideQuestion;
