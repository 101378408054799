// @ts-nocheck
import React, { Component, MouseEvent } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { TRow } from '../types';

type TTableMainHeadProps = {
  rows: TRow[],
  order: 'desc' | 'asc',
  orderBy: string,
  onRequestSort: (e: MouseEvent, arg: string) => void,
  onSelectAll?: (flag: boolean) => void
}

class TableMainHead extends Component<TTableMainHeadProps> {
  createSortHandler = (property: string) => (event: MouseEvent) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { rows, order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                align={row.align}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={
                    row.align === 'right' ? 'bottom-end' : 'bottom-start'
                  }
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default TableMainHead;
