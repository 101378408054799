import React from 'react';
// import PropTypes from 'prop-types';

import styled, { css } from 'styled-components/macro';
import { color, fontWeight, fontType } from '../../styles/style';

const StatWrapper = styled.div`
  text-align: center;
`;

const Stat = styled.div`
  color: ${color.dark};
  font-size: 1.5rem;
  font-weight: ${fontWeight.bold};

  ${props =>
    props.type === 'correct' &&
    css`
      color: ${color.secondary};
    `};
  ${props =>
    props.type === 'wrong' &&
    css`
      color: ${color.redMain};
    `};
    @media (min-width: 768px) {
      font-size: 3rem;
    }
`;

const Label = styled.div`
  color: ${color.blueLight};
  font-size: 11px;
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;
  margin-bottom: 10px;
  ${props =>
    props.type === 'correct' &&
    css`
      color: ${color.secondary};
    `};
  ${props =>
    props.type === 'wrong' &&
    css`
      color: ${color.redMain};
    `};
    @media (min-width: 768px) {
      font-size: ${fontType.copy};
    }
`;
const ResultBox = props => {
  const stattext = props.percent ? `${props.stat}%` : `${props.stat}`;
  return (
    <>
      <StatWrapper>
        <Stat type={props.type}>{stattext}</Stat>
        <Label type={props.type}>{props.label}</Label>
        {props.children}
      </StatWrapper>
    </>
  );
};

ResultBox.propTypes = {};

export default ResultBox;
