import styled from 'styled-components/macro';
import { color, fontWeight, fontType } from '../../styles/style';

export const CopyTopic = styled.p`
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: ${fontType.copy};
  color: ${color.white};
  letter-spacing: 0;
  vertical-align: baseline;
  line-height: 1.5;
`;

export const CopyTopicTitle = styled(CopyTopic)`
  font-weight: ${fontWeight.semiBold};
`;

export const styles = {
  card: {
    background: `${color.blueDarkest}`,
    boxShadow: 'none',
    width: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
};
