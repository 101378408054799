export const howHeard = [
  "Google/Online Search",
  "MPRE Course",
  "Above The Law",
  "The National Jurist",
  "Email/Newsletter",
  "Friend/Word of Mouth",
  "Phi Alpha Delta",
  "Social Media (Please Specify The Platform Below)",
  "YouTube",
  "Professor/Law School Faculty (Please Specify Below)",
  "Used Services In The Past",
  "Other (Please Specify Below)",
];

export default [
  "Abraham Lincoln University School of Law",
  "Albany Law School of Union University",
  "American University, Washington College of Law",
  "Appalachian School of Law",
  "Arizona State University—Sandra Day O'Connor College of Law",
  "Atlanta's John Marshall Law School",
  "Ave Maria School of Law",
  "Barry University Dwayne O. Andreas School of Law",
  "Baylor University School of Law",
  "Benjamin N. Cardozo School of Law, Yeshiva University",
  "Birmingham School of Law",
  "Boston College Law School",
  "Boston University School of Law",
  "Brigham Young University—J. Reuben Clark Law School",
  "Brooklyn Law School",
  "CAL Northern School of Law",
  "California Desert Trial Academy College of Law",
  "California School of Law",
  "California Western School of Law",
  "Campbell University, Norman Adrian Wiggins School of Law",
  "Capital University Law School",
  "Case Western Reserve University School of Law",
  "Catholic University of America, Columbus School of Law",
  "Chapman University School of Law",
  "Charleston School of Law",
  "Chicago-Kent College of Law, Illinois Institute of Technology",
  "City University of New York School of Law",
  "Cleveland State University—Cleveland-Marshall College of Law",
  "Columbia University School of Law",
  "Concord Law School at Purdue University Global",
  "Concordia University School of Law",
  "Cornell Law School",
  "Creighton University School of Law",
  "DePaul University College of Law",
  "Drake University Law School",
  "Drexel University, Earle Mack School of Law",
  "Duke University School of Law",
  "Duquesne University School of Law",
  "Elon University School of Law",
  "Emory University School of Law",
  "Empire College School of Law",
  "Faulkner University, Thomas Goode Jones School of Law",
  "Florida A&M University College of Law",
  "Florida Coastal School of Law",
  "Florida International University College of Law",
  "Florida State University College of Law",
  "Fordham University School of Law",
  "George Mason University School of Law",
  "George Washington University Law School",
  "Georgetown University Law Center",
  "Georgia State University College of Law",
  "Glendale University College of Law",
  "Golden Gate University School of Law",
  "Gonzaga University School of Law",
  "Hamline University School of Law",
  "Harvard Law School",
  "Hofstra University School of Law",
  "Howard University School of Law",
  "Humphreys College School of Law",
  "Indiana University Maurer School of Law—Bloomington",
  "Indiana University School of Law—Indianapolis",
  "Irvine University College of Law",
  "John F. Kennedy University College of Law",
  "John Marshall Law School",
  "Judge Advocate Generals Legal Center",
  "Lewis & Clark Law School",
  "Liberty University School of Law",
  "Lincoln Law School of Sacramento",
  "Lincoln Law School of San Jose",
  "Louisiana State University, Paul M. Hebert Law Center",
  "Loyola Law School, Loyola Marymount University",
  "Loyola University Chicago School of Law",
  "Loyola University New Orleans College of Law",
  "Marquette University Law School",
  "Massachusetts School of Law at Andover",
  "McGeorge School of Law",
  "Mercer University—Walter F. George School of Law",
  "Michigan State University College of Law",
  "Miles Law School",
  "Mississippi College School of Law",
  "Monterey College of Law",
  "Nashville School of Law",
  "New England Law",
  "New York Law School",
  "New York University School of Law",
  "North Carolina Central School of Law",
  "North Carolina Central University School of Law",
  "Northeastern University School of Law",
  "Northern Illinois University College of Law",
  "Northern Kentucky University—Salmon P. Chase College of Law",
  "Northwestern University School of Law",
  "Notre Dame Law School",
  "Nova Southeastern University—Shepard Broad Law Center",
  "Oak Brook College of Law",
  "Ohio Northern University—Claude W. Pettit College of Law",
  "Ohio State University Moritz College of Law",
  "Oklahoma City University School of Law",
  "Pace University School of Law",
  "Pacific Coast University School of Law",
  "Pacific West College of Law",
  "Penn State University, Dickinson School of Law",
  "Pepperdine University School of Law",
  "Phoenix School of Law",
  "Quinnipiac University School of Law",
  "Regent University School of Law",
  "Roger Williams University School of Law",
  "Rutgers University School of Law—Newark",
  "Rutgers—State University of New Jersey—School of Law—Camden",
  "Saint Louis University School of Law",
  "Samford University, Cumberland School of Law",
  "San Francisco International University College of Law",
  "San Joaquin College of Law",
  "Santa Barbara College of Law",
  "Santa Clara University School of Law",
  "Seattle University School of Law",
  "Seton Hall University School of Law",
  "SMU Dedman School of Law",
  "South Texas College of Law",
  "Southern California Institute of Law",
  "Southern Illinois University School of Law",
  "Southern University Law Center",
  "Southwestern Law School",
  "St. Francis School of Law",
  "St. John's University School of Law",
  "St. Mary's University School of Law",
  "St. Thomas University School of Law",
  "Stanford University Law School",
  "Stetson University College of Law",
  "Suffolk University Law School",
  "Syracuse University College of Law",
  "Taft Law School",
  "Temple University—James E. Beasley School of Law",
  "Texas Southern University—Thurgood Marshall School of Law",
  "Texas Tech University School of Law",
  "Texas Wesleyan University School of Law",
  "Thomas Jefferson School of Law",
  "Touro College—Jacob D. Fuchsberg Law Center",
  "Trinity Law School, Trinity International University",
  "Tulane University Law School",
  "University at Buffalo Law School,  State University of New York (SUNY)",
  "University of Akron School of Law",
  "University of Alabama School of Law",
  "University of Arizona James E. Rogers College of Law",
  "University of Arkansas at Little Rock, William H. Bowen School of Law",
  "University of Arkansas School of Law",
  "University of Baltimore School of Law",
  "University of California, Berkeley, School of Law",
  "University of California, Davis School of Law (King Hall)",
  "University of California, Hastings College of the Law",
  "University of California, Los Angeles School of Law",
  "University of Chicago Law School",
  "University of Cincinnati College of Law",
  "University of Colorado Law School",
  "University of Connecticut School of Law",
  "University of Dayton School of Law",
  "University of Denver Sturm College of Law",
  "University of Detroit Mercy School of Law",
  "University of Florida, Fredric G. Levin College of Law",
  "University of Georgia School of Law",
  "University of Hawai‘i at Mānoa—William S. Richardson School of Law",
  "University of Houston Law Center",
  "University of Idaho College of Law",
  "University of Illinois College of Law",
  "University of Iowa College of Law",
  "University of Kansas School of Law",
  "University of Kentucky College of Law",
  "University of La Verne College of Law",
  "University of Louisville's Brandeis School of Law",
  "University of Maine School of Law",
  "University of Maryland School of Law",
  "University of Memphis—Cecil C. Humphreys School of Law",
  "University of Miami School of Law",
  "University of Michigan Law School",
  "University of Minnesota Law School",
  "University of Mississippi School of Law",
  "University of Missouri School of Law",
  "University of Missouri—Kansas City School of Law",
  "University of Montana School of Law",
  "University of Nebraska College of Law",
  "University of Nevada, Las Vegas, William S. Boyd School of Law",
  "University of New Hampshire",
  "University of New Mexico School of Law",
  "University of North Carolina School of Law",
  "University of North Dakota School of Law",
  "University of Oklahoma College of Law",
  "University of Oregon School of Law",
  "University of Pennsylvania Law School",
  "University of Pittsburgh School of Law",
  "University of Richmond School of Law",
  "University of San Diego School of Law",
  "University of San Francisco School of Law",
  "University of South Carolina School of Law",
  "University of South Dakota School of Law",
  "University of Southern California, Gould School of Law",
  "University of St. Thomas School of Law—Minneapolis",
  "University of Tennessee College of Law",
  "University of Texas School of Law",
  "University of the District of Columbia—David A. Clarke School of Law",
  "University of Toledo College of Law",
  "University of Tulsa College of Law",
  "University of Utah S.J. Quinney College of Law",
  "University of Virginia School of Law",
  "University of Washington School of Law",
  "University of West Los Angeles School of Law - West Los Angeles",
  "University of Wisconsin Law School",
  "University of Wyoming College of Law",
  "Valparaiso University School of Law",
  "Vanderbilt University Law School",
  "Ventura College of Law",
  "Vermont Law School",
  "Villanova University School of Law",
  "Wake Forest University School of Law",
  "Washburn University School of Law",
  "Washington and Lee University School of Law",
  "Washington University School of Law",
  "Wayne State University Law School",
  "West Virginia University College of Law",
  "Western Michigan University Thomas M. Cooley Law School",
  "Western New England College School of Law",
  "Western Sierra Law School",
  "Western State University—College of Law",
  "Whittier Law School",
  "Widener University School of Law",
  "Willamette University College of Law",
  "William & Mary Law School",
  "William Mitchell College of Law",
  "Yale Law School",
  "Other",
];
