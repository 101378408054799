import React from 'react';
import format from 'date-fns/format';
import isDate from 'date-fns/is_date';
import styled from 'styled-components/macro';
import { color, fontWeight } from '../../styles/style';

const DateStyle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5em;
  letter-spacing: 0.5px;
  color: ${color.blueDarkest};
  text-align: left;
  font-weight: ${fontWeight.bold};
`;

const DateTitle = props => {
  if (props.children === undefined) {
    return <DateStyle>&#8216;error&#8216;</DateStyle>;
  }
  const raw = props.children.toDate();
  let time;
  if (isDate(raw)) {
    time = raw;
  } else {
    time = raw.toDate();
  }
  return <DateStyle>{format(time, 'MMM Do, YYYY')}</DateStyle>;
};

DateTitle.propTypes = {};

export default DateTitle;
