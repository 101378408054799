// @ts-nocheck
import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlert } from '../../store/actions/alertActions';
import { RootState } from '../../store/createReduxStore';

type TProps = {};

const Alert: React.FC<TProps> = (props) => {
  const dispatch = useDispatch();
  const { open, message, title } = useSelector((state: RootState) => state.alertReducer);

  const onClose = () => {
    dispatch(hideAlert());
  }

  return (
    <MuiDialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      role="dialog"
      {...props}
    >
      <DialogTitle> {title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          key='ok'
          variant='text'
          color='default'
          data-testid={'ok'}
          onClick={onClose}
        >OK</Button>
      </DialogActions>
    </MuiDialog>
  )
}

export default Alert;
