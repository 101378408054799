import React from 'react';
import styled from 'styled-components/macro';

import QuestionCopy from '../QuestionCopy';
import FeedbackCard from '../FeedbackCard';
import FeedbackCopy from '../FeedbackCopy';
import FeedbackTitle from '../FeedbackTitle';
import QuestionTitle from '../QuestionTitle';
import Loading from '../../../components/Loading';
import AnswerItemReview from '../AnswerItemReview';
import { mediaMinDevice } from '../../../styles/responsive';

const AnswersArea = styled.div`
  padding: 2rem 0 9rem;
  ${mediaMinDevice.tabletL} {
    padding: 3rem 0;
  }
`;
const FeedbackArea = styled.div`
  margin: 2rem 0 0;
`;
const answerItems = ['A', 'B', 'C', 'D'];

const QuestionReview = ({ content }) => {
  if (!content) {
    return <Loading />;
  }
  const qobj = content.qdata;

  return (
    <>
      <QuestionTitle>{`Question #${content.qnum}`}</QuestionTitle>
      <QuestionCopy dangerouslySetInnerHTML={{ __html: qobj.question }} />
      <FeedbackArea>
        <FeedbackCard>
          <FeedbackTitle>Explanation</FeedbackTitle>
          <FeedbackCopy
            className='correctFeedback'
            dangerouslySetInnerHTML={{
              __html: qobj.correctFeedback,
            }}
          />
          <FeedbackCopy
            className='feedback1'
            dangerouslySetInnerHTML={{
              __html: qobj.feedback1,
            }}
          />
          {qobj.feedback2 && (
            <FeedbackCopy
              className='feedback2'
              dangerouslySetInnerHTML={{
                __html: qobj.feedback2,
              }}
            />
          )}
          {qobj.feedback3 && (
            <FeedbackCopy
              className='feedback3'
              dangerouslySetInnerHTML={{
                __html: qobj.feedback3,
              }}
            />
          )}
        </FeedbackCard>
      </FeedbackArea>
      <AnswersArea>
        {answerItems.map(itemKey => (
          <AnswerItemReview
            key={itemKey}
            itemLetter={itemKey}
            itemCopy={qobj[itemKey]}
            userLetter={content.userAnswer}
            correctLetter={content.correctAnswer}
          />
        ))}
      </AnswersArea>
    </>
  );
};

QuestionReview.propTypes = {};

export default QuestionReview;
