import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpExpandPanels from '../../../components/HelpExpandPanels';

class Help extends React.Component {
  state = {
    open: false,
    scroll: 'paper',
  };

  handleDialogOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleDialogClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleDialogClose}
          scroll={this.state.scroll}
          fullWidth={true}
          maxWidth={'md'}
          aria-labelledby='scroll-dialog-title'
        >
          <DialogTitle id='scroll-dialog-title'>FAQ&#8216;s</DialogTitle>
          <DialogContent>
            <HelpExpandPanels />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color='primary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <IconButton color='inherit' onClick={this.handleDialogOpen('paper')}>
          <HelpIcon />
        </IconButton>
      </div>
    );
  }
}

export default Help;
