import { QuestionsActions } from "../actions/questionActions";

const initState = {
  status: 'init',
  section: null,
  level: null,
  view: null,
  qbank: null,
  qid: '',
  errors: null,
  questions: null,
  queries: null,
  isDraft: true,
};

const questionStatus = (state = initState, action) => {
  const { payload, type } = action;

  switch (type) {
    case 'SET_QUESTION':
      return {
        ...state,
        section: action.section,
        level: action.level,
        view: action.view,
        qbank: action.qbank,
        status: action.status,
        qid: action.qid,
        questions: action.questions,
        isDraft: action.isDraft,
      };

    case QuestionsActions.RESET:
      return {
        ...state,
        qbank: payload.qbank,
        section: payload.section,
        level: payload.level,
        view: payload.view,
      };

    case QuestionsActions.ADD:
      return {
        ...state,
        status: payload.status,
        section: payload.section,
        level: payload.level,
        qbank: payload.qbank,
        view: payload.view,
        qid: payload.qid,
      };

    case 'SET_QUESTION_VIEW':
      return {
        ...state,
        view: action.view,
      };

    case QuestionsActions.PUBLISH:
      return {
        ...state,
        status: payload.status,
      };

    case QuestionsActions.REMOVE:
      return {
        ...state,
        status: payload.status,
      };

    case QuestionsActions.UNPUBLISH:
      return {
        ...state,
        status: payload.status,
      };

    case 'SET_QBANK':
      return {
        ...state,
        section: action.section,
        level: action.level,
        qbank: action.qbank,
        isDraft: action.isDraft,
        view: action.view,
      };
    case 'SET_QUESTION_BY_ID':
      return {
        ...state,
        qid: action.qid,
      };

    case 'QUESTION_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default questionStatus;
