// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Button from '../../elements/Button';
import { Card, CardHeader, CardContent } from '../../containers/Card';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router';
import getSectionFromUrl from '../../utils/getSectionFromUrl';

type TStudentFAQ = {
  buttonLabel: string
};

const StudentFAQ: React.FC<TStudentFAQ> = ({
  buttonLabel = 'Learn More!',
  ...props
}) => {
  const { location } = useHistory();
  const urlSection = getSectionFromUrl(location.pathname, 0);

  return (
    <Grid item xs={12} {...props}>
      <Card>
        <CardHeader
          action={
            <IconButton component={Link} to={`/faq`}>
              <ChevronRight />
            </IconButton>
          }
          title="Frequently Asked Questions"
        />
        <Divider />

        <CardContent>
          <Typography gutterBottom>Thank you for enrolling in our law school study aids premium option!</Typography>
          <Typography variant='subtitle1' gutterBottom>Below are some frequently asked questions:</Typography>

          <ul>
            <li><Typography gutterBottom>How many questions are included for each subject?</Typography></li>
            <li><Typography gutterBottom>Can I answer questions on specific topics?</Typography></li>
            <li><Typography gutterBottom>How often are new questions added?</Typography></li>
          </ul>

          <Grid container spacing={3} justify={'center'}>
            <Grid item>
              <Button
                color="success"
                component={Link}
                to={`${urlSection}/faq`}
              >
                {buttonLabel}
              </Button>
            </Grid>
          </Grid>
        </CardContent>

      </Card>
    </Grid >
  )
}

export default StudentFAQ;
