export const getTopicsFromClassification = (classification: string | string[]): string[] => {
  if (!classification) return [];

  const input = typeof classification === 'string' ? [classification] : classification
  const topics = input.map((item) => {
    const end = item.indexOf('-') >= 0 ? item.indexOf('-') : item.length;

    return item.substr(0, end)
  })

  return topics.filter((item, index, arr) => index === arr.indexOf(item));
}
