import styled from 'styled-components/macro';

import { color, fontWeight, fontSize } from '../../../../../styles/style';
export const QuestionTitle = styled.h1`
  font-family: 'Open Sans', sans-serif;
  font-size: ${fontSize.questionTitle};
  font-weight: ${fontWeight.bold};
  line-height: 1.125;
  margin-bottom: 0.5em;
  letter-spacing: 0;
  color: ${color.darkest};
`;

export default QuestionTitle;
