import styled from 'styled-components/macro';
import { color } from '../../styles/style';

export const QuestionSetBox = styled.div``;

export const QiBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const QI = styled.div``;

export const QiBox = styled.div`
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
`;

export const HLine = styled.hr`
  background-color: ${color.blueLightest};
  border: 0;
  height: 1px;
  width: 100%;
`;
