export type TValidator = (value: any) => ({
  isValid: boolean,
  error: string
})

export type TRules = {
  [field: string]: TValidator[]
}

export const validate = (fields: object, rules: TRules) => {
  const result = {};

  Object.keys(rules).forEach((field) => {
    for (let i = 0; i < rules[field].length; i++) {
      const validated = rules[field][i](fields[field]);

      if (!validated.isValid) {
        result[field] = validated.error;
        break;
      }
    }
  })

  return result;
}

export const validateAccountType = (type: string) => {
  const types = ['pro', 'trial'];

  return {
    isValid: types.includes(type),
    error: 'Invalid account type'
  }
}

export const validateAccountLevel = (level: string) => {
  const levels = ['free', 'paid'];

  return {
    isValid: levels.includes(level),
    error: 'Invalid account level'
  }
}

export const validateName = (name: string) => {
  return {
    isValid: /^[\w-]{1,255}$/.test(name),
    error: 'Invalid name'
  }
}

export const validatePercentage = (value: number | string) => {
  let input: any = (value + '').replace(/\D/g, '');
  let isValid = true;

  if (!(/^\d{1,3}$/.test(input))) {
    isValid = false;
  }

  try {
    input = +input;

    if (input > 100 || input < 0) {
      isValid = false;
    }
  } catch (e) {
    isValid = false;
  }


  return {
    isValid: isValid,
    error: 'Invalid percentage value'
  }
}

export const validateRequired = (value: any) => {
  return {
    isValid: !!value,
    error: 'The field is required'
  }
}

export const validatePassword = (password: string) => {
  return {
    isValid: password.length >= 6,
    error: 'Password is too short'
  }
}

export const validatePhoneNumber = (number: string) => {
  return {
    isValid: /^\d{8,12}$/.test(number.replace(/\D/g, "")),
    error: 'Invalid phone number'
  }
}

export const validateEmail = (email: string) => {
  return {
    isValid: /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email),
    error: 'Invalid email format'
  }
}

export const validateYear = (year: string, min: number = 1900, max: number = 2100) => {
  let message = 'Invalid year format';

  if (!+year) {
    message = 'Year must be a number';
  } else if (+year <= min) {
    message = 'Year must be greater than ' + min;
  } else if (+year >= max) {
    message = 'Year must be less than ' + max;
  }

  return {
    isValid: !!+year && (+year > min) && (+year < max),
    error: message
  }
}

export const validateZip = (zip: string) => {
  return {
    isValid: /^[\w-]{1,11}$/.test(zip),
    error: 'Invalid zip format'
  }
}

export const validateCvv = (cvv: string) => {
  return {
    isValid: /^\d{3,4}$/.test(cvv),
    error: 'Invalid security code'
  }
}

export const validateExpDate = (date: string) => {
  return {
    isValid: /^\d{2}\/\d{2}$/.test(date),
    error: 'Invalid date'
  }
}
