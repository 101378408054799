import styled from 'styled-components/macro';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import { color, fontWeight } from '../../../../../styles/style';
import { mediaMinDevice } from '../../../../../styles/responsive';
import { Link as RouteLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';

export const SubjectCardContent = styled.div`
  padding: 0.5rem 1rem;
  ${mediaMinDevice.mobileL} {
    padding: 0.5rem 1.5rem;
  }
`;

export const ControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const QuestionCounter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const QuestionInput = styled.input`
  font-size: 1.3rem;
  line-height: 1.5;
  color: ${props =>
    props.readOnly ? `${color.blueLightest}` : `${color.darkest}`};
  text-align: center;
  font-weight: ${fontWeight.semiBold};
  border: none;
  width: 4rem;
  margin: 0 0.5rem;
  &&&::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  &&&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  ${mediaMinDevice.mobileL} {
    margin: 0 1rem;
  }
`;

export const CountBtn = styled(IconButton)`
  &&&&&&&&&&&& {
    padding: 1px;
    color: ${color.white};
    background-color: ${props =>
    props.disabled ? `${color.blueLightest}` : `${color.secondary}`};
  }
  &&&&&&&&&&&&:hover {
    background-color: ${color.secondaryDark};
  }
`;

export const SubjectControl = styled(FormControl)`
  max-width: 300px;
  width: 100%;
`;

export const TopicControl = styled(FormControl)`
  max-width: 420px;
  width: 100%;
`;

export const TipSpan = styled.span`
  display: inherit;
`;

export const PromoText = styled(Typography)`
  color: ${color.secondaryDark};
  font-weight: ${fontWeight.bold};
`;

export const Link = styled(RouteLink)`
  color: ${color.secondaryDark};

  &:hover {
    color: ${color.secondaryDarker};
  }
`;
