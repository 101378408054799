import React from 'react';
import PropTypes from 'prop-types';

const ProgressCircle = props => {
  const { stat, clr, fontsize } = props;
  return (
    <svg viewBox='0 0 36 36' className='circular-chart blue'>
      <path
        className='circle-bg'
        fill='none'
        stroke='#CFDAE6'
        strokeWidth={2}
        d='M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831'
      />
      <path
        className='circle'
        stroke={clr}
        fill='none'
        strokeWidth={2}
        strokeLinecap='round'
        strokeDasharray={`${stat}, 100`}
        d='M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831'
      />
      <text
        x='50%'
        y='60%'
        fontSize={fontsize}
        className='percentage'
        fill={clr}
        textAnchor='middle'
      >
        {`${stat.toFixed(1)}%`}
      </text>
    </svg>
  );
};
ProgressCircle.propTypes = {
  fontsize: PropTypes.number,
  clr: PropTypes.string,
  stat: PropTypes.number,
};
ProgressCircle.defaultProps = {
  fontsize: 10,
  clr: '#5C67E5',
  stat: 50,
};

export default ProgressCircle;
