import { TStudent } from '../../../types';
import { actionCreator } from '../../utils/actionCreator';
import { getAsyncActions } from '../../utils/getAsyncActions';
import firebase from '../api/firebaseAdmin';
import { TAsyncAction, TDispatch, TNewStudent } from '../types';
import { showAlert } from './alertActions';

export type TImportRow = {
  'Student First': string,
  'Student Last': string,
  'School Email': string,
  'Zip Code': string,
  'Phone Number': string,
};

type TUserActions = {
  RESET: string,
  CREATE: TAsyncAction,
  DELETE: TAsyncAction,
  UPGRADE: TAsyncAction,
  UPDATE: TAsyncAction,
  SEND_EMAIL: TAsyncAction,
  IMPORT: TAsyncAction,
};

export const UserActions: TUserActions = {
  RESET: 'user/reset',
  ...getAsyncActions({
    CREATE: 'user/create',
    DELETE: 'user/delete',
    UPGRADE: 'user/upgrade',
    UPDATE: 'user/update',
    SEND_EMAIL: 'user/sendEmail',
    IMPORT: 'user/import',
  })
} as TUserActions;

export const resetStudentView = () => ({
  type: UserActions.RESET,
});

export const createNewStudent = (student: TNewStudent) => {
  return async (dispatch: TDispatch) => {
    const createNewStudent = firebase.functions().httpsCallable('createNewStudent');

    dispatch(actionCreator(UserActions.CREATE.REQUESTED));

    return createNewStudent(student)
      .then(({ data: { success, error } }) => {
        ``
        if (!success) {
          throw new Error('The student has not been created.')
        } else {
          dispatch(actionCreator(UserActions.CREATE.SUCCESS));

          dispatch(showAlert({
            title: 'Success',
            message: 'The Student has been successfully created.',
          }));
        }
      })
      .catch((error) => {
        dispatch(actionCreator(UserActions.CREATE.FAILURE, error.message || JSON.stringify(error), true));

        dispatch(showAlert({
          title: 'Error',
          message: error.message || 'The student has not been created.',
        }));
      });
  };
};

export const removeStudent = (uid: string) => {
  return async (dispatch: TDispatch) => {
    dispatch(actionCreator(UserActions.DELETE.REQUESTED));

    try {
      const removeStudent = firebase.functions().httpsCallable('removeStudent');
      const { data } = await removeStudent(uid);

      if (data.success) {
        dispatch(actionCreator(UserActions.DELETE.SUCCESS));

        dispatch(showAlert({
          title: 'Success',
          message: 'The Student has been successfully deleted.',
        }));
      } else {
        throw (new Error(data.error || JSON.stringify(data.error)))
      }
    } catch (e) {
      dispatch(actionCreator(UserActions.DELETE.FAILURE, e.message || JSON.stringify(e), true));

      dispatch(showAlert({
        title: 'Error',
        message: 'The Student has not been deleted.',
      }));
    }
  };
};

export const sendOutNewUserEmail = (student: TStudent) => {
  return async (dispatch: TDispatch) => {
    dispatch(actionCreator(UserActions.SEND_EMAIL.REQUESTED));

    try {
      await firebase.auth().sendPasswordResetEmail(student.email);

      dispatch(actionCreator(UserActions.SEND_EMAIL.SUCCESS));
    } catch (err) {
      dispatch(actionCreator(UserActions.SEND_EMAIL.FAILURE, err.message, true));
    }
  };
};

export const updateStudent = (student: Partial<TStudent>, hideAlert = false) => {
  return async (dispatch: TDispatch) => {
    const updateStudentRecord = firebase.functions().httpsCallable('updateStudentRecord');
    dispatch(actionCreator(UserActions.UPDATE.REQUESTED));

    try {
      const result = await updateStudentRecord(student);

      if (result.data.success) {
        dispatch(actionCreator(UserActions.UPDATE.SUCCESS));

        if (!hideAlert) {
          dispatch(showAlert({
            title: 'Success',
            message: 'The Student has been successfully updated.',
          }));
        }
      } else {
        throw result.data.error;
      }
    } catch (err) {
      dispatch(actionCreator(UserActions.UPDATE.FAILURE, typeof err === 'string' ? err : JSON.stringify(err), true));

      if (!hideAlert) {
        dispatch(showAlert({
          title: 'Error',
          message: 'The Student was not updated.',
        }));
      }
    }
  };
};

export const bulkStudentsUpdate = (studentIds: string[], changes: { accountType?: string, isActive?: boolean }) => {
  return async (dispatch: TDispatch) => {
    const bulkStudentEdit = firebase.functions().httpsCallable('bulkStudentsUpdate');
    dispatch(actionCreator(UserActions.UPDATE.REQUESTED));

    bulkStudentEdit({ studentIds, changes })
      .then(({ data }) => {
        const { success } = data;
        if (success) {
          dispatch(actionCreator(UserActions.UPDATE.SUCCESS));

          dispatch(showAlert({
            title: 'Success',
            message: 'The Students have been successfully updated.',
          }));
        } else {
          throw new Error('The Students have not been successfully updated.');
        }
      })
      .catch(err => {
        dispatch(actionCreator(UserActions.UPDATE.FAILURE, err.message || 'Server error happened, try again later', true));

        dispatch(showAlert({
          title: 'Error',
          message: 'Server error happened, try again later.',
        }));
      });
  };
};
