import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { color } from '../../styles/style';

const NavBtn = styled(NavLink)`
  text-decoration: none;
  margin: 0 0.25rem;
  padding: 0.5rem 1rem;
  border-radius: 290486px;
  letter-spacing: 1.7px;
  font-size: 0.85em;
  text-transform: uppercase;
  font-weight: 700;
  color: ${color.blueLight};
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &.active {
    color: ${color.primary};
    background-color: ${color.grey};
  }
  &:hover {
    color: ${color.primary};
  }
`;

export default NavBtn;
