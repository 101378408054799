import React, { Component } from 'react';
import styled from 'styled-components';
import { mediaMinDevice } from '../../styles/responsive';
import msToString from '../../utils/msToString';

const QuestionTimer = styled.div`
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  ${mediaMinDevice.tabletL} {
    font-size: 2.1rem;
    text-align: center;
  }
`;

const QuestionTimerDone = styled(QuestionTimer)`
  color: #ff0000;
`;

/**
 * @param duration - number of milliseconds with which the timer is initialized
 * @param timeTotal - the max number of milliseconds given for test
 */

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: props.duration,
      start: Date.now() - props.duration,
      isOn: true,
    };
    this.intervalHandler = null;
    this.tick = this.tick.bind(this);
    this.startTimer = this.startTimer.bind(this);
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    const { time } = this.state;
    this.props.onTimerChange(time);
    if (this.intervalHandler) clearInterval(this.intervalHandler);
  }

  startTimer() {
    this.setState({
      time: this.props.duration,
      start: Date.now() - this.props.duration,
      isOn: true,
    });
    this.intervalHandler = setInterval(this.tick, 100);
  }

  tick() {
    const { totalTime, overTime } = this.props;
    const { start } = this.state;
    const time = Date.now() - start;
    this.setState({ time });
    this.props.onTimerChange(time);

    if (totalTime > 0 && time >= totalTime && !overTime) {
      this.intervalHandler = clearInterval(this.intervalHandler);

      this.timerComplete();
    }
  }

  pauseTimer() {
    this.props.onTimerChange(this.state.time);
    this.setState({ isOn: false });
    if (this.intervalHandler) clearInterval(this.intervalHandler);
  }

  resetTimer() {
    this.setState({ time: 0 });
  }

  getTimerTime() {
    return this.state.time;
  }

  timerComplete() {
    this.props.onTimerComplete();
  }

  render() {
    const { totalTime, invisible } = this.props;
    if (invisible) {
      return null;
    } else if (this.state.time > totalTime) {
      return <QuestionTimerDone>{msToString(this.state.time)}</QuestionTimerDone>;
    } else {
      return <QuestionTimer>{msToString(this.state.time)}</QuestionTimer>;
    }
  }
}

export default Timer;
