import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
// import { withStyles } from '@material-ui/core/styles';

import { color } from '../../../styles/style';

const QuestionFooterWrapper = styled.footer`
  background: ${color.greyLight};
  width: 100%;
  position: fixed;
  bottom: 0;
`;

const QuestionControls = styled.div`
  display: flex;
  justify-content: space-between;
  height: 6rem;
  align-items: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
`;

const MainFooter = props => {
  return (
    <QuestionFooterWrapper>
      <QuestionControls>{props.children}</QuestionControls>
    </QuestionFooterWrapper>
  );
};

MainFooter.propTypes = {};

export default MainFooter;
