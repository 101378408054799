import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { TGuide, TGuideQuestion, TStudent } from '../../../../types';
import { TRootState } from '../../../store/reducers';
import { get } from 'lodash';
import { compose } from 'redux';
import subjectTopics from '../../../_CONS/subjectTopics';
import { subjectCodes } from '../../../_CONS/qbanks';
import { Grid, Typography } from '@material-ui/core';
import { List, SubjectInfo, TopicInfo, AccordionSummary, SectionTitle, Accordion, LoaderWrapper } from './StyledStudy';
import Chevron from '@material-ui/icons/KeyboardArrowDown';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Loading from '../../../components/Loading';
import { actionTypes } from 'redux-firestore';
import { getTopicsFromClassification } from '../../../utils/getTopicsFromClassification';
import Button from '../../../elements/Button';
import Dialog from '../../../components/Dialog';
import { deleteStudyGuide, GuideActions } from '../../../store/actions/guideActions';
import { actionCreator } from '../../../utils/actionCreator';

type TSubjectCode = keyof typeof subjectTopics;

type TTopic = {
  label: string,
  total: number,
  used: number,
}

type TSubjectTotals = {
  [key in TSubjectCode]: {
    label: string,
    total: number,
    used: number,
    topics: {
      [key: string]: TTopic
    }
  }
};

type TGuideStat = {
  id: string,
  label: string,
  subjects: {
    [key in TSubjectCode]: {
      label: string,
      number: number,
      topics: {
        [key: string]: {
          label: string,
          number: number,
        }
      }
    }
  }
}

type TAccordion = {
  [key in TSubjectCode]: boolean
}

type TStudy = TPropsFromRedux & {
  student: TStudent,
};

type TPropsFromRedux = ConnectedProps<typeof connector>;

const Study: React.FC<TStudy> = (props: TStudy) => {
  const [subjectTotals, setSubjectTotals] = useState<TSubjectTotals | {}>({});
  const [guidesStat, setGuidesStat] = useState<TGuideStat[] | null>(null);
  const [qAccordion, setQAccordion] = useState<TAccordion | {}>({});
  const [guidesAccordion, setGuidesAccordion] = useState<TAccordion | {}>({});
  const [showDialog, setShowDialog] = useState(false);
  const [guideToReset, setGuideToReset] = useState(null);

  useEffect(() => {
    Object.keys(subjectTopics).reduce((result, item) => {
      return {
        ...result,
        [item]: false
      }
    }, {});

    return () => {
      props.resetOrdered()
    }
  }, []);

  useEffect(() => {
    const { qbanks } = props;

    if (Object.keys(qbanks).length === Object.keys(subjectCodes).length) {
      const subjects = Object.keys(qbanks).reduce((result, shortCode) => {
        const qbank = qbanks[shortCode as TSubjectCode];

        if (!subjectTopics[shortCode]) {
          return result;
        } else {
          const topics = Object.entries(subjectTopics[shortCode].topics).reduce((result, [key, topic]: [string, TTopic]) => {
            let total = 0;
            let used = 0;

            qbank.forEach((question: TGuideQuestion) => {
              const topics = question.topics || getTopicsFromClassification(question.classification);

              if (topics.includes(key)) {
                total++;
                if (question.selected) {
                  used++;
                }
              }
            })

            return {
              ...result,
              [key]: {
                label: topic.label,
                total,
                used,
              }
            }
          }, {})

          return {
            ...result,
            [shortCode]: {
              label: subjectTopics[shortCode].label,
              total: qbank.length,
              used: 0,
              topics: topics
            },
          }
        }
      }, {});

      props.sgquestions.forEach((item) => {
        if (item.subjectCode) {
          subjects[item.subjectCode].used += 1
        }
      })

      setSubjectTotals(subjects)
    }
  }, [props.qbanks, props.sgquestions]);

  useEffect(() => {
    if (Object.keys(props.qbanks).length === Object.keys(subjectCodes).length) {
      const guideStat = props.studyguides.map((guide: TGuide) => {
        let subjects = {};

        if (guide.questions) {
          guide.questions.forEach(({ qid, subjectCode }) => {
            const question: TGuideQuestion = props.qbanks[subjectCode].find(({ id }) => id === qid);
            const topics = question.topics || getTopicsFromClassification(question.classification);

            if (subjects[subjectCode]) {
              subjects[subjectCode].number++;
            } else {
              subjects[subjectCode] = {
                number: 1,
                label: subjectTopics[subjectCode].label,
                topics: {}
              }
            }

            topics.forEach((topicCode) => {
              if (subjects[subjectCode].topics[topicCode]) {
                subjects[subjectCode].topics[topicCode].number++
              } else {
                subjects[subjectCode].topics[topicCode] = {
                  label: subjectTopics[subjectCode].topics[topicCode].label,
                  number: 1,
                }
              }
            })
          })
        }

        return {
          id: guide.uid,
          label: guide.name,
          subjects
        }
      });

      setGuidesStat(guideStat as TGuideStat[]);
    }
  }, [props.studyguides, props.qbanks])

  const onGuideExpand = (id: string) => (e: any, expanded: boolean) => {
    setGuidesAccordion({
      ...guidesAccordion,
      [id]: expanded
    })
  }

  const onQuestionExpand = (subjectCode: TSubjectCode) => (e: any, expanded: boolean) => {
    setQAccordion({
      ...qAccordion,
      [subjectCode]: expanded
    })
  }

  const cancelReset = () => {
    setShowDialog(false);

    if (props.error) {
      props.resetError();
    }
  }

  const confirmReset = () => {
    props.deleteStudyGuide(props.student.uid, guideToReset, props.student.studentBank)
    setGuideToReset(null);
    setShowDialog(false);
  }

  const onReset = (guideId: string) => () => {
    setShowDialog(true);
    setGuideToReset(guideId);
  }

  const dialog = {
    title: 'Delete Guide',
    type: (props.error ? 'alert' : 'confirm') as ('alert' | 'confirm'),
    content: props.error || 'This action will delete the Study Guide from the Student Account and return questions back to the Study Guide Builder.',
    open: showDialog || props.status === 'loading' || props.error,
    isLoading: props.status === 'loading',
    buttonLabels: props.error ? [] : ['Cancel', 'Confirm'],
  }

  return (
    <div>
      <Dialog
        {...dialog}
        onCancel={cancelReset}
        onConfirm={confirmReset}
      />

      <SectionTitle variant="h6" gutterBottom>All Questions</SectionTitle>
      {
        !Object.keys(subjectTotals).length ? (
          <LoaderWrapper>
            <Loading position={'static'} />
          </LoaderWrapper>
        ) : (
          Object.keys(subjectTotals).map((subjectCode: TSubjectCode) => {
            const subject = subjectTotals[subjectCode];
            const topics = Object.keys(subject.topics).map((topicCode) => {
              const topic = subject.topics[topicCode];

              return (
                <TopicInfo key={subjectCode + topicCode}>
                  <Typography className='title' variant={'body1'}>{topic.label}</Typography>

                  <Typography className={`${topic.used >= topic.total ? 'usedOff' : ''}`} variant={'body1'}>
                    {topic.used} / {topic.total}
                  </Typography>
                </TopicInfo>
              )
            })

            return (
              <Accordion
                key={subjectCode}
                expanded={!!qAccordion[subjectCode]}
                onChange={onQuestionExpand(subjectCode)}
              >
                <AccordionSummary expandIcon={<Chevron />}>
                  <SubjectInfo>
                    <Typography className='title' variant={'body1'}>{subject.label}</Typography>

                    <Typography className={`${subject.used >= subject.total ? 'usedOff' : ''}`} variant={'body1'}>
                      {subject.used} / {subject.total}
                    </Typography>
                  </SubjectInfo>
                </AccordionSummary>

                <AccordionDetails>
                  <List>
                    {topics}
                  </List>
                </AccordionDetails>
              </Accordion>
            )
          })
        )
      }

      <SectionTitle variant="h6" gutterBottom>Study Guides</SectionTitle>
      {
        !guidesStat ? (
          <LoaderWrapper>
            <Loading position={'static'} />
          </LoaderWrapper>
        ) : (
          guidesStat.map((guide) => {
            return (
              <Accordion
                key={guide.id}
                expanded={!!guidesAccordion[guide.id]}
                onChange={onGuideExpand(guide.id)}
              >
                <AccordionSummary expandIcon={<Chevron />}>
                  <SubjectInfo>
                    <Typography className='title' variant='body1'>{guide.label}</Typography>
                  </SubjectInfo>
                </AccordionSummary>

                <AccordionDetails>
                  <List>
                    {
                      Object.keys(guide.subjects).map((code) => {
                        const { label, number, topics } = guide.subjects[code];

                        return (
                          <>
                            <SubjectInfo>
                              <Typography className='title' variant='body1'>{label} ({number})</Typography>
                            </SubjectInfo>

                            <List>
                              {
                                Object.keys(topics).map((code) => {
                                  const { label, number } = topics[code];

                                  return (
                                    <TopicInfo>
                                      <Typography className='title' variant='body1'>{label} ({number})</Typography>
                                    </TopicInfo>
                                  )
                                })
                              }
                            </List>
                          </>
                        )
                      })
                    }

                    <Grid container justify='flex-end'>
                      <Grid item>
                        <Button color='danger' onClick={onReset(guide.id)} >Reset Guide</Button>
                      </Grid>
                    </Grid>
                  </List>
                </AccordionDetails>
              </Accordion>
            )
          }
          )
        )
      }
    </div>
  )
}

const mapStateToProps = ({ firestore, guideStatus }: TRootState) => {
  const { sgquestions, studyguides, ...qbanks } = firestore.ordered;

  return {
    sgquestions: sgquestions || [],
    studyguides: studyguides || [],
    qbanks: qbanks || {},
    status: guideStatus.status,
    error: guideStatus.error,
  }
};

const mapDispatchToProps = {
  deleteStudyGuide,
  resetOrdered: () => ({ type: actionTypes.CLEAR_DATA }),
  resetError: () => actionCreator(GuideActions.CLEAR),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const firestoreConnector = firestoreConnect((props: any) => {
  const qbanks = Object.keys(subjectTopics).map((key: string) => ({
    collection: get(props, 'student.studentBank'),
    doc: get(props, 'student.uid'),
    subcollections: [
      { collection: `study-${key.toLowerCase()}` }
    ],
    storeAs: key,
  }))

  return [
    {
      collection: get(props, 'student.studentBank'),
      doc: get(props, 'student.uid'),
      subcollections: [
        { collection: 'sgquestions', where: ['selected', '==', true] },
      ],
      storeAs: 'sgquestions'
    },
    {
      collection: get(props, 'student.studentBank'),
      doc: get(props, 'student.uid'),
      subcollections: [
        { collection: 'studyguides' },
      ],
      storeAs: 'studyguides'
    },
    ...qbanks
  ];
})

export default compose(connector, firestoreConnector)(Study);
