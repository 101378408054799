import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { setQuestion } from '../../../store/actions/questionActions';

const ITEM_HEIGHT = 48;

class RowMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  deleteClick = event => {
    const { qid, section, level, qbank } = this.props;

    const qObj = {
      section: section,
      level: level,
      view: 'delete',
      qbank: qbank,
      qid: qid,
      questions: [qid],
    };
    this.props.setQuestion(qObj).then(() => {
      this.props.menuActionDelete(event, 'delete');
    });
    this.setState({ anchorEl: null });
  };

  unpublishClick = event => {
    const { qid, section, level, qbank } = this.props;

    const qObj = {
      section: section,
      level: level,
      view: 'unpublish',
      qbank: qbank,
      qid: qid,
      questions: [qid],
    };
    this.props.setQuestion(qObj).then(() => {
      this.props.menuActionUnpublish(event, 'unpublish');
    });
    this.setState({ anchorEl: null });
  };
  publishClick = event => {
    const { qid, section, level, qbank } = this.props;

    const qObj = {
      section: section,
      level: level,
      view: 'publish',
      qbank: qbank,
      qid: qid,
      questions: [qid],
    };
    this.props.setQuestion(qObj).then(() => {
      this.props.menuActionPublish(event, 'publish');
    });
    this.setState({ anchorEl: null });
  };
  selectClick = (event, option) => {
    const {
      location,
      history,
      qid,
      section,
      level,
      qbank,
      isDraft,
    } = this.props;

    const newpath = option.toLowerCase();
    const qObj = {
      section: section,
      level: level,
      view: newpath,
      qbank: qbank,
      qid: qid,
      questions: [qid],
      isDraft: isDraft,
    };
    const url = `${location.pathname}/${newpath}`;
    this.props.setQuestion(qObj).then(() => {
      history.push(url);
    });
    this.setState({ anchorEl: null });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { isDraft, qIsValid } = this.props;
    const open = Boolean(anchorEl);
    return (
      <div>
        <IconButton
          aria-label='More'
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup='true'
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='long-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
            },
          }}
        >
          {!isDraft && [
            <MenuItem
              key='preview'
              onClick={e => this.selectClick(e, 'preview')}
            >
              Preview
            </MenuItem>,
            <Divider key='divideDel' />,
            <MenuItem
              key='unpublish'
              onClick={e => this.unpublishClick(e, 'unpublish')}
            >
              Unpublish
            </MenuItem>,
          ]}
          {isDraft && [
            <MenuItem key='edit' onClick={e => this.selectClick(e, 'edit')}>
              Edit
            </MenuItem>,
            <MenuItem
              key='preview'
              onClick={e => this.selectClick(e, 'preview')}
            >
              Preview
            </MenuItem>,
            <Divider key='dividePub' />,
            <MenuItem
              disabled={!qIsValid}
              key='publish'
              onClick={e => this.publishClick(e, 'publish')}
            >
              Publish
            </MenuItem>,
            <Divider key='divideDel' />,
            <MenuItem key='delete' onClick={e => this.deleteClick(e, 'delete')}>
              Delete
            </MenuItem>,
          ]}
        </Menu>
      </div>
    );
  }
}

RowMenu.propTypes = {
  qid: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  qbank: PropTypes.string.isRequired,
  isDraft: PropTypes.bool.isRequired,
  setQuestion: PropTypes.func.isRequired,
  menuActionPublish: PropTypes.func.isRequired,
  menuActionUnpublish: PropTypes.func.isRequired,
  menuActionDelete: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setQuestion: qObj => dispatch(setQuestion(qObj)),
});

export default compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps
  )
)(RowMenu);
