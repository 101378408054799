import { GuideActions } from "../actions/guideActions";
import { TStatus, TAction } from "../types";

type TGuideState = {
  status: TStatus,
  guideType: string | null,
  guideId: string | null,
  error: string,
  questions: any[] | null,
  queries: any[] | null,
  qid: string | null,
  subjectCode: string | null,
};

const initState: TGuideState = {
  status: '',
  guideType: null,
  guideId: null,
  error: null,
  questions: null,
  queries: null,
  qid: null,
  subjectCode: null,
};

const guideStatus = (state = initState, action: any): TGuideState => {
  const { payload, type, error } = action;
  let newState = {};
  const errorState = {
    status: 'failure' as TStatus,
    error: payload,
  }

  switch (type) {
    case GuideActions.RESET.REQUESTED:
    case GuideActions.UPDATE.REQUESTED:
    case GuideActions.DELETE.REQUESTED:
    case GuideActions.FINISH.REQUESTED:
      return {
        ...state,
        status: 'loading',
        error: ''
      }

    case GuideActions.UPDATE.SUCCESS:
    case GuideActions.DELETE.SUCCESS:
    case GuideActions.FINISH.SUCCESS:
      return {
        ...state,
        status: 'success',
        error: ''
      }
    case GuideActions.RESET.SUCCESS:
      return {
        ...state,
        status: '',
        error: ''
      }

    case GuideActions.RESET.FAILURE:
    case GuideActions.DELETE.FAILURE:
    case GuideActions.FINISH.FAILURE:
      return {
        ...state,
        status: 'failure',
        error: payload
      }

    case GuideActions.BUILD:
      newState = error ? {
        ...errorState,
      } : {
        guideType: payload.guideType,
        guideId: payload.guideId,
        status: payload.status,
        error: ''
      };

      return { ...state, ...newState }

    case GuideActions.UPDATE:
      return {
        ...state,
        status: payload.status,
        error: ''
      };

    case GuideActions.CLEAR:
      return {
        ...state,
        status: '',
        guideType: null,
        guideId: null,
        error: '',
        questions: null,
        queries: null,
        qid: null,
        subjectCode: null,
      };

    case GuideActions.VIEW:
      newState = error ? {
        ...errorState,
      } : {
        action: payload.action,
        status: payload.status,
        guideType: payload.guideType,
        guideId: payload.guideId,
        error: ''
      };

      return { ...state, ...newState }

    case GuideActions.REVIEW:
      newState = error ? {
        ...errorState,
      } : {
        status: payload.status,
        guideType: payload.guideType,
        queries: payload.queries,
        questions: payload.questions,
        error: ''
      };

      return { ...state, ...newState }

    case GuideActions.FINISH:
      newState = error ? {
        ...errorState,
        //@ts-ignore
        status: 'error',
        error: payload,
      } : {
        status: payload.status,
        error: ''
      };

      return { ...state, ...newState }

    default:
      return state;
  }
};

export default guideStatus;
