import { FaqActions } from "../actions/faqActions";
import { TFaqQuestion, TStatus } from "../types";

export type TFaqState = {
  questions: TFaqQuestion[],
  status: TStatus,
  error: string,
};

const initState: TFaqState = {
  error: '',
  status: '',
  questions: [],
};

const faqReducer = (state: TFaqState = initState, action: any): TFaqState => {
  const { payload, type } = action;

  switch (type) {
    case FaqActions.FETCH.REQUESTED:
    case FaqActions.CREATE.REQUESTED:
    case FaqActions.UPDATE.REQUESTED:
    case FaqActions.DELETE.REQUESTED:
      return {
        ...state,
        status: 'loading',
      }

    case FaqActions.FETCH.SUCCESS:
      return {
        ...state,
        status: 'success',
        questions: payload.questions,
      }

    case FaqActions.CREATE.SUCCESS:
      return {
        ...state,
        status: 'success',
        questions: [payload.question, ...state.questions],
      }

    case FaqActions.UPDATE.SUCCESS:
      return {
        ...state,
        status: 'success',
        questions: state.questions.map((item) => (
          item.id === payload.question.id ? {
            ...item,
            ...payload.question
          } : item
        )),
      }

    case FaqActions.DELETE.SUCCESS:
      return {
        ...state,
        status: 'success',
        questions: state.questions.filter((item) => item.id !== payload),
      }

    case FaqActions.FETCH.FAILURE:
    case FaqActions.CREATE.FAILURE:
    case FaqActions.UPDATE.FAILURE:
    case FaqActions.DELETE.FAILURE:
      return {
        ...state,
        status: 'failure' as TStatus,
        error: payload
      }

    default:
      return state;
  }
}

export default faqReducer;
