import { createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducers';
import thunk from 'redux-thunk';

const initialState = {};
const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(
  reducer,
  initialState,
  enhancer
);

export type RootState = ReturnType<typeof store.getState>

export default store;
