import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { MainScreen, MainFooter } from '../../../containers/Main';
import { FormControl } from '../../../containers/Form';
import CardFieldNew from '../../../components/CardFieldNew';
import CardSelectNew from '../../../components/CardSelectNew';
import CardSelectClassification from '../../../components/CardSelectClassification';
import { ToggleBtn, ToggleBtnGroup } from '../../../components/ToggleBtn';
import SimpleBtn from '../../../elements/SimpleBtn';
import Button from '../../../elements/Button';
import { saveQuestion } from '../../../store/actions/questionActions';
import { color } from '../../../styles/style';
import BackButton from '../../../elements/BackButton';

const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;

const FooterLeft = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: flex-start;
  align-items: center;
`;

const FooterRight = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: space-between;
  align-items: center;
`;

const FeedbackButton = styled(Button)`
  &&&&&& {
    background-color: transparent;
    color: ${color.blueDarkest};
    border-color: ${color.blueLight};
    border-width: 1px;
    border-radius: 3px;
    border-style: dashed;
    height: 80px;
    &:hover {
      border-color: ${color.primary};
      background-color: transparent;
      color: ${color.primary};
    }
  }
`;

class NewQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSection: '',
      type: props.section === 'free' ? '' : props.section,
      testCode: props.section === 'study' ? 'guide' : '',
      isSaved: false,
      qbank: null,
      level: '',
      published: false,
      subject: '',
      subjectCode: '',
      classification: '',
      question: '',
      A: '',
      B: '',
      C: '',
      D: '',
      correctAnswer: '',
      correctFeedback: '',
      feedback1: '',
      feedback2: '',
      feedback3: '',
      showFeedback2: false,
      showFeedback3: false,
    };
    this.goBackScreen = this.goBackScreen.bind(this);
    this.preSave = this.preSave.bind(this);
    this.newQuestionCancel = this.newQuestionCancel.bind(this);
    this.afterSaveEdit = this.afterSaveEdit.bind(this);
    this.afterSavePreview = this.afterSavePreview.bind(this);
  }

  afterSavePreview(e) {
    const { currentSection } = this.state;
    this.props.history.push(`/admin/${currentSection}/preview`);
    e.preventDefault();
  }

  afterSaveEdit(e) {
    const { currentSection } = this.state;
    this.props.history.push(`/admin/${currentSection}/edit`);
    e.preventDefault();
  }

  preSave(e) {
    const { type, testCode, subjectCode, classification } = this.state;
    const { section, saveQuestion } = this.props;
    const level = section === 'free' ? 'free' : 'paid';
    const bank = type === 'study' ? subjectCode.toLowerCase() : testCode;
    const qbank = `${type}-${bank}`;
    const sobj = this.state;
    const qObj = {
      ...sobj,
      section: section,
      subjectCode: subjectCode,
      classification: [classification],
      qbank,
      testCode,
      level,
      type,
    };
    saveQuestion(level, qbank, qObj).then(() => {
      this.props.history.push(`/admin/${section}/edit`);
    });

    e.preventDefault();
  }

  goBackScreen(e) {
    const { section } = this.props;
    this.props.history.push(`/admin/${section}`);

    e.preventDefault();
  }

  newQuestionCancel(e) {
    const { section } = this.props;
    this.props.history.push(`/admin/${section}`);
    e.preventDefault();
  }

  handleSelectChange = (event, correctAnswer) =>
    this.setState({ correctAnswer });

  handleListChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubjectChange = () => obj => {
    this.setState({
      subject: obj.subject,
      subjectCode: obj.subjectCode,
      classification: obj.classification,
    });
  };

  handleCardAction = name => event => {
    this.setState({
      [name]: event,
    });
  };

  handleFeedbackState = name => () => {
    this.setState({
      [name]: true,
    });
  };

  render() {
    const { section } = this.props;
    const {
      type,

      subject,
      subjectCode,
      classification,
      question,
      A,
      B,
      C,
      D,
      correctAnswer,
      correctFeedback,
      feedback1,
      feedback2,
      feedback3,
      showFeedback2,
      showFeedback3,
      isSaved,
    } = this.state;

    const isInvalid =
      subject === '' ||
      subjectCode === '' ||
      classification === '' ||
      question === '' ||
      A === '' ||
      B === '' ||
      C === '' ||
      D === '' ||
      correctAnswer === '' ||
      correctFeedback === '' ||
      feedback1 === '';
    return (
      <Screen>
        <MainScreen
          title='New Question'
          leftSideItem={<BackButton onClick={this.goBackScreen} />}
          spacing={3}
        >
          {section !== 'study' && (
            <CardSelectNew>
              <Grid container justify='flex-start' alignItems='center'>
                {section === 'free' && (
                  <Grid item>
                    <FormControl>
                      <InputLabel shrink={true} htmlFor='select-question-type'>
                        Question Type
                      </InputLabel>
                      <Select
                        value={this.state.type}
                        onChange={this.handleListChange('type')}
                      >
                        <MenuItem value=''>Select Type</MenuItem>
                        <MenuItem value='test'>test</MenuItem>
                        <MenuItem value='study'>study</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {type === 'test' && (
                  <Grid item>
                    <FormControl>
                      <InputLabel shrink={true} htmlFor='select-question-type'>
                        Question Type
                      </InputLabel>
                      <Select
                        value={this.state.testCode}
                        onChange={this.handleListChange('testCode')}
                      >
                        <MenuItem value=''>Select Test Code</MenuItem>
                        <MenuItem value='ope01'>Practice Test 1</MenuItem>
                        <MenuItem value='ope02'>Practice Test 2</MenuItem>
                        <MenuItem value='ope03'>Practice Test 3</MenuItem>
                        <MenuItem value='ope04'>Practice Test 4</MenuItem>
                        <MenuItem value='final01'>Final Test 1</MenuItem>
                        <MenuItem value='final02'>Final Test 2</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </CardSelectNew>
          )}

          <CardSelectClassification
            label='Subject and Classification'
            isEditMode={true}
            subjectCode={subjectCode}
            classification={classification}
            fieldChange={this.handleSubjectChange('subjectClassif')}
          />

          <CardSelectNew label='Correct Answer' content={correctAnswer}>
            <ToggleBtnGroup
              value={correctAnswer}
              exclusive
              onChange={this.handleSelectChange}
            >
              <ToggleBtn selected={correctAnswer === 'A'} value='A'>
                A
              </ToggleBtn>
              <ToggleBtn selected={correctAnswer === 'B'} value='B'>
                B
              </ToggleBtn>
              <ToggleBtn selected={correctAnswer === 'C'} value='C'>
                C
              </ToggleBtn>
              <ToggleBtn selected={correctAnswer === 'D'} value='D'>
                D
              </ToggleBtn>
            </ToggleBtnGroup>
          </CardSelectNew>

          <CardFieldNew
            inputProps={{ maxLength: 10000 }}
            label='Question'
            name='question'
            isMultiline={true}
            cardAction={this.handleCardAction('question')}
            content={question}
          />

          <CardFieldNew
            inputProps={{ maxLength: 2000 }}
            label='Answer A'
            name='A'
            cardAction={this.handleCardAction('A')}
            content={A}
          />

          <CardFieldNew
            inputProps={{ maxLength: 2000 }}
            name='B'
            label='Answer B'
            cardAction={this.handleCardAction('B')}
            content={B}
          />
          <CardFieldNew
            inputProps={{ maxLength: 2000 }}
            name='C'
            label='Answer C'
            cardAction={this.handleCardAction('C')}
            content={C}
          />
          <CardFieldNew
            inputProps={{ maxLength: 2000 }}
            name='D'
            label='Answer D'
            cardAction={this.handleCardAction('D')}
            content={D}
          />
          <CardFieldNew
            inputProps={{ maxLength: 5000 }}
            name='correctFeedback'
            label='Correct Feedback'
            isMultiline={true}
            cardAction={this.handleCardAction('correctFeedback')}
            content={correctFeedback}
          />
          <CardFieldNew
            inputProps={{ maxLength: 5000 }}
            name='feedback1'
            label='Feedback 1'
            isMultiline={true}
            cardAction={this.handleCardAction('feedback1')}
            content={feedback1}
          />
          {showFeedback2 ? (
            <CardFieldNew
              inputProps={{ maxLength: 5000 }}
              name='feedback2'
              label='Feedback 2'
              isMultiline={true}
              cardAction={this.handleCardAction('feedback2')}
              content={feedback2}
            />
          ) : (
            <Grid item xs={12}>
              <FeedbackButton
                variant='contained'
                fullWidth
                color='default'
                onClick={this.handleFeedbackState('showFeedback2')}
              >
                <AddIcon />
                Add Feedback 2
              </FeedbackButton>
            </Grid>
          )}

          {showFeedback3 && (
            <CardFieldNew
              inputProps={{ maxLength: 5000 }}
              name='feedback3'
              label='Feedback 3'
              isMultiline={true}
              cardAction={this.handleCardAction('feedback3')}
              content={feedback3}
            />
          )}
          {showFeedback2 === true &&
            showFeedback3 === false && (
              <Grid item xs={12}>
                <FeedbackButton
                  variant='contained'
                  fullWidth
                  color='default'
                  onClick={this.handleFeedbackState('showFeedback3')}
                >
                  <AddIcon />
                  Add Feedback 3
                </FeedbackButton>
              </Grid>
            )}
        </MainScreen>
        <MainFooter>
          {isSaved ? (
            <>
              <FooterLeft>
                <SimpleBtn
                  variant='contained'
                  type='remove'
                  onClick={this.afterSaveRemove}
                >
                  DELETE
                </SimpleBtn>
              </FooterLeft>
              <FooterRight>
                <SimpleBtn
                  variant='contained'
                  type='publish'
                  onClick={this.afterSaveEdit}
                >
                  EDIT
                </SimpleBtn>
                <SimpleBtn
                  variant='contained'
                  type='publish'
                  onClick={this.afterSavePreview}
                >
                  PREVIEW
                </SimpleBtn>
              </FooterRight>
            </>
          ) : (
            <>
              <SimpleBtn
                variant='outlined'
                type='default'
                onClick={this.newQuestionCancel}
              >
                CANCEL
              </SimpleBtn>
              <SimpleBtn
                disabled={isInvalid}
                variant='contained'
                type='primary'
                onClick={this.preSave}
              >
                SAVE
              </SimpleBtn>
            </>
          )}
        </MainFooter>
      </Screen>
    );
  }
}

NewQuestion.propTypes = {
  section: PropTypes.string,
  qbank: PropTypes.string,
  status: PropTypes.string,
  qid: PropTypes.string,
  view: PropTypes.string,
};

const mapStateToProps = state => ({
  qbank: state.questionStatus.qbank,
  status: state.questionStatus.status,
  qid: state.questionStatus.qid,
  section: state.questionStatus.section,
  view: state.questionStatus.view,
});

const mapDispatchToProps = dispatch => ({
  saveQuestion: (level, qbank, qObj) =>
    dispatch(saveQuestion(level, qbank, qObj)),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewQuestion);
