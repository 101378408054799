import { TStateAction, TStatus } from "../types";
import { AuthAction } from "../actions/authActions";

export type TAuthState = {
  action: 'login' | 'logout' | 'signup' | 'send-email' | '',
  error: string
  status: TStatus
};

const initState: TAuthState = {
  action: '',
  error: '',
  status: '',
};

const authReducer = (state = initState, action: TStateAction): TAuthState => {
  const { type, payload, error } = action;

  switch (type) {
    case AuthAction.LOGOUT:
      return {
        ...state,
        status: '',
        action: 'logout',
        error: error ? payload : '',
      };

    case AuthAction.SEND_EMAIL:
      return {
        ...state,
        status: '',
        action: 'send-email',
        error: error ? payload : '',
      };

    case AuthAction.LOGIN['REQUESTED']:
      return {
        ...state,
        action: 'login',
        error: '',
        status: 'loading'
      };

    case AuthAction.SIGNUP['REQUESTED']:
      return {
        ...state,
        status: 'loading',
        action: 'signup',
        error: '',
      };

    case AuthAction.LOGIN['SUCCESS']:
      return {
        ...state,
        action: 'login',
        error: '',
        status: ''
      };

    case AuthAction.SIGNUP['SUCCESS']:
      return {
        ...state,
        status: '',
        action: 'signup',
        error: '',
      };

    case AuthAction.LOGIN['FAILURE']:
      return {
        ...state,
        action: 'login',
        error: payload || '',
        status: 'failure'
      };

    case AuthAction.SIGNUP['FAILURE']:
      return {
        ...state,
        status: 'failure',
        action: 'signup',
        error: payload || '',
      };

    default:
      return state;
  }
};

export default authReducer;
