import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, getVal } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { withStyles } from '@material-ui/core/styles';
import { MainScreen, MainFooter } from '../../../containers/Main';
import DialogModal from '../../../components/DialogModal';
import EditForm from './EditForm';
import SimpleBtn from '../../../elements/SimpleBtn';
import {
  setQuestionView,
  removeQuestion,
  publishQuestion,
} from '../../../store/actions/questionActions';
import BackButton from '../../../elements/BackButton';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;
const FooterLeft = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: flex-start;
  align-items: center;
`;
const FooterRight = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: space-between;
  align-items: center;
`;

class EditQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      dialogType: 'preview',
      dialogStatus: 'ready',
      view: 'edit',
    };

    this.goBackScreen = this.goBackScreen.bind(this);
    this.preDelete = this.preDelete.bind(this);
    this.prePublish = this.prePublish.bind(this);
  }

  goBackScreen(e) {
    const { section } = this.props;
    this.props.history.push(`/admin/${section}`);

    e.preventDefault();
  }

  handleDialogCancel = () => {
    this.setState({ isDialogOpen: false });
  };

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  handleDialogDone = () => {
    const { section } = this.props;
    this.props.history.push(`/admin/${section}`);
    this.setState({ isDialogOpen: false });
  };

  handleDialogAction = () => {
    const { section } = this.props;
    this.props.history.push(`/admin/${section}/new`);
    this.setState({ isDialogOpen: false });
  };

  loadPreview = event => {
    const { section, history } = this.props;
    event.preventDefault();
    const url = `/admin/${section}/preview`;
    this.props.setQuestionView('preview').then(() => {
      history.push(url);
    });
  };

  loadNew = event => {
    const { section, history } = this.props;
    event.preventDefault();
    const url = `/admin/${section}/new`;
    this.props.setQuestionView('new').then(() => {
      history.push(url);
    });
  };

  preDelete() {
    const { qid } = this.props;
    this.setState({
      qid,
      questions: [qid],
      dialogType: 'delete',
      dialogStatus: 'ready',
      isDialogOpen: true,
    });
  }

  prePublish() {
    this.setState({
      dialogType: 'publish',
      dialogStatus: 'ready',
      isDialogOpen: true,
    });
  }

  render() {
    const { qid, currentQuestion } = this.props;
    const questions = [qid];
    const { isDialogOpen, dialogType, dialogStatus } = this.state;

    return (
      <>
        <DialogModal
          type={dialogType}
          open={isDialogOpen}
          status={dialogStatus}
          qid={qid}
          questions={questions}
          handleClose={this.handleDialogClose}
          handleCancel={this.handleDialogCancel}
          handleAction={this.handleDialogAction}
          handleDone={this.handleDialogDone}
        />
        <Screen>
          <MainScreen
            title='Edit Question'
            leftSideItem={<BackButton onClick={this.goBackScreen} />}
            spacing={3}
          >
            {currentQuestion && <EditForm currentQuestion={currentQuestion} />}
          </MainScreen>
          <MainFooter>
            <FooterLeft>
              <SimpleBtn
                variant='contained'
                type='remove'
                onClick={this.preDelete}
              >
                DELETE
              </SimpleBtn>
            </FooterLeft>
            <FooterRight>
              <SimpleBtn
                variant='outlined'
                type='default'
                onClick={this.loadPreview}
              >
                PREVIEW
              </SimpleBtn>
              <SimpleBtn
                variant='contained'
                type='primary'
                onClick={this.loadNew}
              >
                ADD NEW
              </SimpleBtn>
              <SimpleBtn
                variant='contained'
                type='secondary'
                onClick={this.prePublish}
              >
                PUBLISH
              </SimpleBtn>
            </FooterRight>
          </MainFooter>
        </Screen>
      </>
    );
  }
}

EditQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  qpath: `${state.questionStatus.level}/${state.questionStatus.qbank}/${state.questionStatus.qid}`,
  qbank: state.questionStatus.qbank,
  level: state.questionStatus.level,
  status: state.questionStatus.status,
  qid: state.questionStatus.qid,
  section: state.questionStatus.section,
});

const mapDispatchToProps = {
  removeQuestion,
  publishQuestion,
  setQuestionView,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firebaseConnect(props => {
    if (!props.qpath) return [];
    return [
      {
        path: get(props, 'qpath'),
      },
    ];
  }),
  connect(({ firebase }, props) => ({
    currentQuestion: getVal(firebase, `data/${props.qpath}`),
  })),
  withStyles(styles)
)(EditQuestion);
