import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { subjectCodes } from '../../_CONS/qbanks';
// import subjectTopics from '../../_CONS/subjectTopics';
import Loading from '../../components/Loading';
// import ReactBar from '../ReactBar';
import ChartSideBySide from '../ChartSideBySide';
class BySubjectBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphdata: null,
    };
  }
  componentDidMount() {
    const gdata = this.createChartData();
    this.setState({ graphdata: gdata });
  }
  createChartData() {
    const { data } = this.props;

    const bySubjectCode = d3
      .nest()
      .key(function (d) {
        return d.subjectCode;
      })
      .entries(data);

    const mymp = bySubjectCode.map(function (item) {
      const innerarray = item.values;
      const total = innerarray.length;

      const correct = innerarray.filter(q => q.isCorrect === true).length;

      const wrong = total - correct;

      const subjectObject = subjectCodes[item.key];
      const rawLabel = subjectObject.label;
      const rawLabelArray = rawLabel.split(' ');
      let subjectLabel;
      const word1 = rawLabelArray[0];
      if (rawLabelArray.length > 1) {
        const word2 = rawLabelArray[1];
        subjectLabel = `${word1} ${word2}`;
      } else {
        subjectLabel = `${word1}`;
      }

      return {
        subject: item.key,
        rawLabel,
        subjectLabel: subjectLabel,
        total: total,
        incorrect: wrong,
        correct,
        topArea: 1,
      };
    });

    return mymp;
  }

  render() {
    const { width, height, minWidth } = this.props;
    const { graphdata } = this.state;
    return (
      <>
        {graphdata ? (
          <>
            {
              // <ReactBar
              //  data={graphdata}
              //  width={width}
              //  height={height}
              //  minWidth={minWidth}
              // />
            }
            <ChartSideBySide
              data={graphdata}
              width={width}
              height={height}
              minWidth={minWidth}
            />
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

BySubjectBar.propTypes = {};

export default BySubjectBar;
