// @ts-nocheck
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import { TDialog } from '../../../types';
import Loading from '../Loading';
import styled from 'styled-components/macro';

type TProps = TDialog & {
  isLoading?: boolean,
  fullWidth?: boolean,
  scroll?: 'paper' | 'body',
  alternativeClose?: boolean,
  maxWidth?: 'xs' | 'md' | 'lg' | 'xl',
  onCloseRequest?: (args: any[]) => void,
  onCancelAction?: (type: string) => void,
  onConfirmAction?: (type: string, payload?: any) => void,
}

const Container = styled(({ isLoading, ...props }: TProps) => <Dialog {...props} />)`
  & .MuiDialog-paper {
    min-width: 10rem;
    min-height: ${({ isLoading }) => isLoading ? '10rem' : 'unset'} 
  }
`;

const BaseDialog: React.FC<TProps> = ({
  title = '',
  content = '',
  isLoading,
  open,
  buttons = [],
  type = '',
  payload,
  alternativeClose,
  onCloseRequest,
  onCancelAction,
  onConfirmAction,
  ...props
}) => {
  const handleAction = (action: string) => {
    if (action === 'cancel') {
      onCancelAction(type);
    } else if (action === 'confirm' && onConfirmAction) {
      onConfirmAction(type, payload);
    }
  }

  return (
    <Container
      open={open}
      disableBackdropClick={!alternativeClose}
      disableEscapeKeyDown={!alternativeClose}
      onClose={onCloseRequest}
      isLoading={isLoading}
      role="dialog"
      data-dialog-type={type}
      {...props}
    >
      {
        isLoading && <Loading overflow={true} />
      }
      <DialogTitle> {title}</DialogTitle>

      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>

      <DialogActions>
        {
          buttons.map((button) => (
            <Button
              variant={'text'}
              key={button.label}
              color={button.color}
              aria-label={button.label}
              data-testid={button.action}
              onClick={() => handleAction(button.action)}
            >
              {button.label}
            </Button>
          ))
        }
      </DialogActions>
    </Container>
  )
}

export default BaseDialog;
