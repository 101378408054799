// @ts-nocheck
import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CheckCircle from '@material-ui/icons/CheckCircle';

import Button from '../../elements/Button';
import { TestCard, Stat, Label, TimeStat, TimeLabel } from './StyledStudyGridBox';
import { Card, CardHeader, CardContent } from '../../containers/Card';
import { TGuide } from '../../../types';
import { minutesToTime } from '../../utils/formatDateTime';
import theme from '../../styles/theme';

type TProps = {
  title: string,
  item: TGuide,
  onClick: Function,
  isComplete: boolean
}

const StudyGridBox: React.FC<TProps> = ({
  title,
  isComplete,
  item,
  onClick
}) => {

  if (item.hasOwnProperty('questionsTotal')) {
    return (
      <Grid item xs={12} sm={6}>
        <Card>
          <TestCard isComplete={isComplete}>
            <CardHeader
              title={title}
              action={isComplete && (
                <IconButton>
                  <CheckCircle />
                </IconButton>
              )}
            />
            <CardContent paddingVertical={item.customTime > 0 ? 0 : theme.spacing(2)}>
              <Grid container>
                <Grid item xs={6} container direction="column" justify="center" >
                  <Stat>{item.questionsTotal}</Stat>
                  <Label>Questions</Label>
                </Grid>
                {
                  item.customTime > 0 ? (
                    <Grid container item xs={6} direction="column" justify="space-between" spacing={2}>
                      <Grid item >
                        <TimeStat>
                          {minutesToTime(item.customTime)}
                        </TimeStat>
                        <TimeLabel>TIME ALLOTTED</TimeLabel>
                      </Grid>
                      <Grid item>
                        <TimeStat>
                          {minutesToTime((item.customTime * 60000 - item.duration) / 60000)}
                        </TimeStat>
                        <TimeLabel>TIME REMAINING</TimeLabel>
                      </Grid>
                    </Grid>
                  ) : null
                }
              </Grid>
            </CardContent>
            <CardContent>
              <Button
                color="default"
                variant="outlined"
                onClick={onClick}
              >
                CONTINUE
              </Button>
            </CardContent>
          </TestCard>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sm={6}>
      <Card>
        <TestCard isComplete={false} isDisabled={true}>
          <CardHeader title={title} />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Label>Problem</Label>
              </Grid>
            </Grid>
          </CardContent>
        </TestCard>
      </Card>
    </Grid>
  );
};

export default StudyGridBox;
