import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import Grid from '@material-ui/core/Grid';
import { MainScreen } from '../../../../containers/Main';
import Loading from '../../../../components/Loading';
import InfoCard from '../../../../components/InfoCard';
import TestGridBox from '../../../../components/TestGridBox';
import { buildTestGuide } from '../../../../store/actions/builderActions';
import { openGuide, reviewGuide } from '../../../../store/actions/guideActions';
import appCopy from '../../../../_CONS/appCopy';
import { Typography } from '@material-ui/core';

export const StepHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

class TestGuideList extends Component {
  constructor(props) {
    super(props);
    this.state = { guideId: null, isBuilding: false };
  }

  buildNewGuide = (item, e) => {
    this.setState({ isBuilding: true });
    e.preventDefault();
    const gObj = {
      guideType: 'testguides',
      status: 'loading',
    };
    this.props.buildTestGuide(item, gObj);
  };

  continueGuide = (item, e) => {
    e.preventDefault();
    const obj = {
      guideType: 'testguides',
      guideId: item.id,
      status: 'loading',
    };
    this.props.openGuide(obj).then(() => {
      this.props.history.push(`${this.props.location.pathname}/guide`);
    });
  };

  reviewGuide = (item, e) => {
    e.preventDefault();
    const obj = {
      guideType: 'testguides',
      guideId: item.id,
      status: '',
      queries: null,
    };
    this.props.reviewGuide(obj).then(() => {
      this.props.history.push(
        `${this.props.location.pathname}/testguides/overview`
      );
    });
  };

  render() {
    const { buildStatus, practiceTests, finalTests, accountType, action } = this.props;
    const { isBuilding } = this.state;

    const completedFinalTests = finalTests.filter(test => test.completed);
    const readyFinalTests = completedFinalTests.length < 1 ? 5 : 6;
    const screenCopy = appCopy.TEST[accountType.toUpperCase()];

    if (buildStatus === 'success' && action === 'build') {
      return <Redirect to={{ pathname: '/student/test/guide' }} />;
    }

    if (isBuilding)
      return (
        <MainScreen title='Tests'>
          <Grid item xs={12}>
            <Loading />
          </Grid>
        </MainScreen>
      );
    return (
      <MainScreen title='Tests'>
        <Grid item xs={12}>
          <InfoCard
            title={screenCopy.OPE.title}
            txt={
              <>
                <Typography paragraph>{screenCopy.OPE.copy}</Typography>
                <Typography variant='subtitle1' display='inline'>Please note: </Typography>
                <Typography display='inline'>{screenCopy.OPE.note}</Typography>
              </>
            }
          />
        </Grid>
        {practiceTests &&
          practiceTests.map(test => {
            return (
              <TestGridBox
                key={test.uid}
                item={test}
                title={test.name}
                completed={test.completed}
                guideActionBuild={e => this.buildNewGuide(test, e)}
                guideActionContinue={e => this.continueGuide(test, e)}
                guideActionReview={e => this.reviewTestGuide(test, e)}
              />
            );
          })}

        <Grid item xs={12}>
          <InfoCard
            title={screenCopy.FINAL.title}
            txt={screenCopy.FINAL.copy}

          />
        </Grid>
        {finalTests &&
          finalTests.map(test => {
            if (test.accountType !== this.props.accountType && this.props.accountType !== 'pro') {
              return null;
            }
            return (
              <TestGridBox
                key={test.uid}
                item={test}
                title={test.name}
                completed={test.completed}
                ready={readyFinalTests >= test.guideNum}
                guideActionBuild={e => this.buildNewGuide(test, e)}
                guideActionContinue={e => this.continueGuide(test, e)}
                guideActionReview={e => this.reviewGuide(test, e)}
              />
            );
          })}
      </MainScreen>
    );
  }
}

const mapStateToProps = ({ firebase, guideStatus, auth, firestore }) => {
  const testGuides = firestore.ordered.testGuides || [];

  return {
    accountType: firebase.profile.accountType,
    buildStatus: guideStatus.status,
    action: guideStatus.action,
    newGuideId: guideStatus.guideId ? guideStatus.guideId : null,
    studentId: auth.uid,
    practiceTests: testGuides.filter(test => test.testType === 'ope'),
    finalTests: testGuides.filter(test => test.testType === 'final'),
  };
};

const mapDispatchToProps = {
  buildTestGuide,
  openGuide,
  reviewGuide,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestGuideList);
