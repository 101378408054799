import React from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
/*** ROUTER  ***/
/*** STYLE  ***/
/*** UI LIBS  ***/
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const Content = props => {
  const {
    children,
    title,
    leftLabel,
    hasActions,
    leftAction,
    rightLabel,
    rightAction,
  } = props;

  return (
    <>
      <DialogTitle id='scroll-dialog-title'>{title}</DialogTitle>
      {children}
      {hasActions && (
        <DialogActions>
          <Button color='default' onClick={leftAction}>
            {leftLabel}
          </Button>
          {rightAction && (
            <Button color='secondary' onClick={rightAction}>
              {rightLabel}
            </Button>
          )}
        </DialogActions>
      )}
    </>
  );
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  leftLabel: PropTypes.string,
  hasActions: PropTypes.bool.isRequired,
  leftAction: PropTypes.func,
  rightLabel: PropTypes.string,
  rightAction: PropTypes.func,
};
export default Content;
