import styled from 'styled-components/macro';
import { color, fontSize } from '../../../styles/style';
import { mediaMinDevice } from '../../../styles/responsive';

export const QuestionTitle = styled.h1`
  font-size: 20px;
  color: ${color.darkest};
  ${mediaMinDevice.tabletL} {
    font-size: ${fontSize.questionTitle};
  }
`;

export default QuestionTitle;
