import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import Toolbar from '@material-ui/core/Toolbar';
import { color } from '../../styles/style';

export const ToolbarWide = styled(Toolbar)`
  justify-content: space-between;
`;

export const MobileLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavBtn = styled(NavLink)`
  text-decoration: none;
  margin: 0 0.25rem;
  padding: 0.5rem 1rem;
  border-radius: 290486px;
  letter-spacing: 1.7px;
  font-size: 0.75em;
  text-transform: uppercase;
  font-weight: 700;
  color: ${color.blueLight};
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &.active {
    color: ${color.primary};
    background-color: ${color.grey};
  }
  &:hover {
    color: ${color.primary};
    text-decoration: none;
  }
`;

export const NavMobile = styled.nav`
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 1rem;
`;

export const NavBtnLink = styled(NavLink)`
  text-decoration: none;
  margin: 0 0 1rem;
  padding: 0.5rem 1rem;
  border-radius: 290486px;
  letter-spacing: 1.7px;
  font-size: 0.75em;
  text-transform: uppercase;
  font-weight: 700;
  color: ${color.blueLight};
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &.active {
    color: ${color.primary};
    background-color: ${color.grey};
  }
  &:hover {
    color: ${color.primary};
  }
`;

export const LogoImage = styled.img`
  width: 155px;
`;

export const Nav = styled.nav`
  display: flex;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const NavRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Container = styled.div`
  max-height: 100vh;
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin: 0;
`;
