import React from 'react';
import styled from 'styled-components/macro';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { color } from '../../../styles/style';

export const StyledToolbar = styled(({ isActive, ...other }) => (
  <Toolbar {...other} />
))`
  padding-left: 8px;
  padding-right: 8px;
  color: ${props => (props.isActive ? '#01df9a' : color.dark)};
  background-color: ${props =>
    props.isActive ? color.secondaryLight : 'inherit'};
`;

export const FilterInputLabel = styled(({ isActive, ...other }) => (
  <Typography classes={{ root: 'root' }} {...other} />
))`
  &&&&&&&& .root {
    color: ${props => (props.isActive ? color.secondary : color.dark)};
    margin-right: 10rem;
  }
`;

export const FilterInputTextField = styled(({ isActive, ...other }) => (
  <TextField {...other} />
))`
  background: #ffffff;
  &&&&&&&&& .marginDense {
    font-size: 0.8rem;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

export const FilterInputLabelArea = styled.div`
  color: ${props => (props.isActive ? color.secondary : color.dark)};
  margin-right: 1rem;
`;

export const BatchArea = styled.div`
  display: flex;
  align-items: center;
  color: ${color.secondary};
`;

export const FilterArea = styled.div`
  color: ${props => (props.isActive ? color.secondary : color.dark)};
  display: flex;
`;

export const FilterInputArea = styled.div`
  color: ${color.secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
