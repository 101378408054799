import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components/macro';
import NavAppBar from '../../components/NavAppBar';
import NavBtn from '../../elements/NavBtn';
import Dashboard from './Dashboard';
import Students from './Students';
import Study from './Study';
import Profile from './Profile';
import Users from './Users';
import Preview from './Preview';
import EditQuestion from './EditQuestion';
import NewQuestion from './NewQuestion';
import { Redirect } from 'react-router';
import StudentFAQ from '../../screens/StudentFAQ';
import { AdminRoutes } from '../../_CONS/routes';

const Container = styled.div`
  max-height: 100vh;
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin: 0;
`;

const AdminPathway = props => {
  const auth = useSelector(state => state.firebase.auth);
  const { match } = props;

  return (
    <>
      <NavAppBar auth={auth}>
        <NavBtn exact to={AdminRoutes.DASHBOARD}>DASHBOARD</NavBtn>
        <NavBtn to={AdminRoutes.STUDENTS}>STUDENTS</NavBtn>
        <NavBtn to={AdminRoutes.STUDY}>STUDY</NavBtn>
      </NavAppBar>

      <Container>
        <Switch>
          <Route path={AdminRoutes.STUDENTS} component={Students} />
          <Route exact path={AdminRoutes.PROFILE} component={Profile} />

          <Route path={AdminRoutes.USERS} component={Users} />

          <Route exact path={`${match.url}/study/preview`} component={Preview} />
          <Route exact path={`${match.url}/study/edit`} component={EditQuestion} />
          <Route exact path={`${match.url}/study/new`} component={NewQuestion} />
          <Route exact path={`${match.url}/study`} component={Study} />
          <Redirect from={`${match.url}/study`} to={`${match.url}/study`} />

          <Route exact path={`${match.url}/faq`} component={StudentFAQ} />

          <Route path={`${match.url}`} component={Dashboard} />
        </Switch>
      </Container>
    </>
  );
};

export default AdminPathway;
