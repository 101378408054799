import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import { MainScreen } from '../../../containers/Main';
import { Card, CardHeader, CardContent } from '../../../containers/Card';
import Button from '../../../elements/Button';
import { addQuestion } from '../../../store/actions/questionActions';
import Promos from '../../../screens/Promos';
import StudentFAQ from '../../../components/StudentFAQ';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.handleAddTestQuestion = this.handleAddTestQuestion.bind(this);
    this.handleAddStudyQuestion = this.handleAddStudyQuestion.bind(this);
    this.handleAddFreeQuestion = this.handleAddFreeQuestion.bind(this);
  }

  handleAddFreeQuestion(e) {
    const { history } = this.props;
    e.preventDefault();
    const sObj = {
      status: 'loading',
      section: 'free',
      level: 'free',
      qbank: '',
      view: 'new',
      qid: '',
    };
    this.props.addQuestion(sObj).then(() => history.push('/admin/free/new'));
  }

  handleAddStudyQuestion(e) {
    const { history } = this.props;
    e.preventDefault();
    const sObj = {
      status: 'loading',
      section: 'study',
      level: 'paid',
      qbank: '',
      view: 'new',
      qid: '',
    };
    this.props.addQuestion(sObj).then(() => history.push('/admin/study/new'));
  }

  handleAddTestQuestion(e) {
    const { history } = this.props;
    e.preventDefault();
    const sObj = {
      status: 'loading',
      section: 'test',
      level: 'paid',
      qbank: '',
      view: 'new',
      qid: '',
    };
    this.props.addQuestion(sObj).then(() => history.push('/admin/test/new'));
  }

  render() {
    const { match } = this.props;

    return (
      <MainScreen title="Dashboard">
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <IconButton component={Link} to={`${match.url}/study`}>
                  <ChevronRight />
                </IconButton>
              }
              title='Study Questions'
            />
            <Divider />

            <CardContent>
              <Button
                onClick={this.handleAddStudyQuestion}
                color='primary'
                variant='contained'
                aria-label='Add Question'
              >
                Add Question
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <StudentFAQ buttonLabel={'Manage Questions'} />
        </Grid>

        <Grid item xs={12}>
          <Promos />
        </Grid>
      </MainScreen>
    );
  }
}

Dashboard.propTypes = {
  addQuestion: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.firebase.profile,
});

const mapDispatchToProps = { addQuestion };

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
