import React from 'react';
import styled from 'styled-components/macro';
import { color, fontWeight } from '../../styles/style';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button as MuiButton, ButtonProps } from '@material-ui/core';

const Button = styled(MuiButton)`
  &&&& {
    color: ${color.primary};
    text-transform: uppercase;
    font-weight: ${fontWeight.bold};
    padding-left: 8px;
  }
`;

type TProps = ButtonProps & {
  label?: string,
}

const BackButton: React.FC<TProps> = ({
  label = 'BACK',
  children,
  ...props
}) => (
  <Button {...props}>
    <ArrowBackIcon />
    {label}
  </Button>
)



export default BackButton;
