import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Loading from '../../components/Loading';
import Button from '../../elements/Button';
import { updatePromo } from '../../store/actions/promoActions';
import PromoImageFile from '../../assets/images/PromoImage.png';
import { TPromo } from '../../store/types';
import {
  EditBannerWrapper,
  EditBannerContainer,
  EditBannerActions,
  BannerImageArea,
  BannerContentArea,
  BannerButtonArea,
  ButtonLabel,
  ButtonUrl,
  PromoImage,
} from './StyledEditBanner';

type TPropsFromRedux = ConnectedProps<typeof connector>;

type TProps = TPropsFromRedux & {
  pid: string,
  promo: TPromo,
  onCancel: () => void,
  onUpdate: () => void,
}

type TState = Partial<TPromo> & {
  isDirty: boolean,
}

class EditBanner extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      isDirty: false,
      title: props.promo.title,
      content: props.promo.content,
      btnLabel: props.promo.btnLabel,
      btnUrl: props.promo.btnUrl,
    };
  }

  handleChange = (e: any) => {
    this.setState({
      [e.target.id]: e.target.value,
      isDirty: true,
    });
  };

  cancelUpdate = () => {
    this.props.onCancel();
  }

  updatePromo = () => {
    const { pid } = this.props;
    const { title, content, btnLabel, btnUrl } = this.state;
    const promo = {
      title,
      content,
      btnLabel,
      btnUrl,
      type: pid,
    };

    this.props.updatePromo(pid, promo).then(() => this.props.onUpdate());
  }

  render() {
    const { isDirty, title, content, btnLabel, btnUrl } = this.state;
    const isLoading = this.props.status === 'loading';

    if (isLoading) {
      return (
        <EditBannerWrapper>
          <Loading />
        </EditBannerWrapper>
      );
    }

    return (
      <form>
        <EditBannerWrapper>
          <EditBannerContainer>
            <BannerImageArea>
              <PromoImage alt="promo" src={PromoImageFile} />
            </BannerImageArea>
            <BannerContentArea>
              <FormControl margin="none" fullWidth>
                <InputLabel htmlFor="title">Title</InputLabel>
                <Input
                  type="text"
                  name="title"
                  value={title}
                  id="title"
                  onChange={this.handleChange}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="content">Content</InputLabel>
                <Input
                  type="content"
                  name="content"
                  value={content}
                  id="content"
                  multiline
                  onChange={this.handleChange}
                />
              </FormControl>
              <BannerButtonArea>
                <ButtonLabel>
                  <FormControl margin="normal">
                    <InputLabel htmlFor="btnLabel">Button Label</InputLabel>

                    <Input
                      type="btnLabel"
                      name="btnLabel"
                      value={btnLabel}
                      id="btnLabel"
                      onChange={this.handleChange}
                    />
                  </FormControl>
                </ButtonLabel>
                <ButtonUrl>
                  <FormControl margin="normal" fullWidth>
                    <InputLabel htmlFor="btnUrl">URL</InputLabel>

                    <Input
                      type="btnUrl"
                      name="btnUrl"
                      value={btnUrl}
                      id="btnUrl"
                      onChange={this.handleChange}
                    />
                  </FormControl>
                </ButtonUrl>
              </BannerButtonArea>
            </BannerContentArea>
          </EditBannerContainer>
          <EditBannerActions>
            <Button color="default" variant="text" onClick={this.cancelUpdate}>
              CANCEL
            </Button>
            <Button
              color="primary"
              variant="text"
              onClick={this.updatePromo}
              disabled={!isDirty}
            >
              SAVE
            </Button>
          </EditBannerActions>
        </EditBannerWrapper>
      </form>
    );
  }
}

const mapDispatchToProps = { updatePromo };

const mapsStateToProps = ({ promoReducer }) => ({
  status: promoReducer.status,
});

const connector = connect(mapsStateToProps, mapDispatchToProps);

export default connector(EditBanner);
