import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MuiToggleButton from '@material-ui/lab/ToggleButton';

import { color } from '../../../styles/style';

const ToggleBtnArea = styled(MuiToggleButton)`
  &&&&&& {
    color: ${color.blueDarkest};
    border-color: ${color.blueLightest};
    border-width: 1px;
    height: 100%;
    width: 100%;
    max-width: 217px;
    border-style: solid;
    border-radius: 3px;
    padding: 10px;
    margin: 10px;
    line-height: 2rem;
    font-size: 2rem;
    transition: all 200ms;
    &:hover {
      border-color: ${color.primary};
      background-color: transparent;
      color: ${color.primary};
    }
  }
`;

const ToggleBtn = ({ children, ...others }) => (
  <ToggleBtnArea {...others}>{children}</ToggleBtnArea>
);

ToggleBtn.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ToggleBtn;
