import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Button from '../../../../../elements/Button';
import { reviewGuide } from '../../../../../store/actions/guideActions';
import {
  styles,
  SubjectRow,
  TopicRow,
  SubjectTitles,
  TopicTitles,
  SubjectBtn,
  TopicBtn,
} from './StyledSubjectListCard';

class SubjectListCard extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, questions: null };
    this.reviewWithQuery = this.reviewWithQuery.bind(this);
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  reviewWithQuery(subjectCode) {
    const { guideId, guideType, questions } = this.props;

    const queries = [
      ['guideId', '==', guideId],
      ['subjectCode', '==', subjectCode],
    ];

    const guideObj = {
      guideType,
      questions,
      status: '',
      queries,
    };

    this.props.reviewGuide(guideObj).then(() => {
      this.props.history.push(`${this.props.location.pathname}/guide`);
    });
  }

  render() {
    const { qgroup, classes } = this.props;

    const { open } = this.state;
    return (
      <SubjectRow>
        <Grid
          container
          spacing={2}
          alignItems='center'
          className={classes.subjectRow}
          justify='space-between'
        >
          <Grid item xs={8} sm={3}>
            <SubjectTitles>{qgroup.subjectLabel}</SubjectTitles>
            <TopicBtn onClick={this.handleClick}>
              {open ? 'Hide Topics' : 'Show Topics'}{' '}
            </TopicBtn>
          </Grid>
          <Grid item xs={4} sm={1}>
            <SubjectTitles textalign='center'>{qgroup.overall}</SubjectTitles>
          </Grid>
          <Grid item xs={4} sm={1}>
            <SubjectTitles textalign='center'>{qgroup.total}</SubjectTitles>
          </Grid>
          <Grid item xs={4} sm={1}>
            <SubjectTitles textalign='center'>{qgroup.correct}</SubjectTitles>
          </Grid>
          <Grid item xs={4} sm={1}>
            <SubjectTitles textalign='center'>{qgroup.wrong}</SubjectTitles>
          </Grid>
          <Grid item xs={7} sm={3}>
            <SubjectBtn>
              <Button
                size='small'
                color='secondary'
                onClick={() => this.reviewWithQuery(qgroup.subject)}
              >
                REVIEW
              </Button>
            </SubjectBtn>
          </Grid>
        </Grid>

        <Collapse in={open} timeout='auto' unmountOnExit>
          <TopicRow>
            {qgroup.topics.map((q, ind) => (
              <Grid
                key={ind}
                container
                spacing={2}
                alignItems='center'
                className={classes.topicRow}
                justify='space-between'
              >
                <Grid item xs={8} sm={3}>
                  <TopicTitles>{`${q.topicLabel}`}</TopicTitles>
                </Grid>
                <Grid item xs={4} sm={1}>
                  <TopicTitles textalign='center'>{q.overall}</TopicTitles>
                </Grid>
                <Grid item xs={4} sm={1}>
                  <TopicTitles textalign='center'>{q.total}</TopicTitles>
                </Grid>
                <Grid item xs={4} sm={1}>
                  <TopicTitles textalign='center'>{q.correct}</TopicTitles>
                </Grid>
                <Grid item xs={4} sm={1}>
                  <TopicTitles textalign='center'>{q.wrong}</TopicTitles>
                </Grid>
                <Grid item xs={7} sm={3} textalign='center' />
              </Grid>
            ))}
          </TopicRow>
        </Collapse>
      </SubjectRow>
    );
  }
}

SubjectListCard.propTypes = {};

const mapDispatchToProps = { reviewGuide };

const mapStateToProps = ({ guideStatus }) => {
  return {
    guideType: guideStatus.guideType,
    questions: guideStatus.questions,
    guideId: guideStatus.guideId,
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(SubjectListCard);
