import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { withStyles } from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { color } from '../../styles/style';
const SwitchRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const styles = theme => ({
  colorBar: {},
  colorChecked: {},
  iOSSwitchBase: {
    '&$iOSChecked': {
      color: theme.palette.common.white,
      '& + $iOSBar': {
        backgroundColor: `${color.secondary}`,
      },
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: 'translateX(15px)',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none',
    },
  },
  iOSBar: {
    borderRadius: 13,
    width: 42,
    height: 26,
    marginTop: -13,
    marginLeft: -21,
    border: 'solid 1px',
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  iOSIcon: {
    width: 24,
    height: 24,
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1],
  },
});

class ToggleSwitch extends React.Component {
  state = {
    checkedA: true,
    checkedB: true,
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes, isChecked, onPress, labelOn, labelOff } = this.props;

    return (
      <SwitchRow>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                classes={{
                  switchBase: classes.iOSSwitchBase,
                  checked: classes.iOSChecked,
                }}
                onClick={onPress}
                disableRipple
                checked={isChecked}
              />
            }
            label={isChecked ? labelOn : labelOff}
          />
        </FormGroup>
      </SwitchRow>
    );
  }
}

ToggleSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
  isChecked: PropTypes.bool,
  onPress: PropTypes.func,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  isChecked: false,
  onPress: null,
  labelOn: 'on',
  labelOff: 'off',
};

export default withStyles(styles)(ToggleSwitch);
