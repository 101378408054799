import styled from 'styled-components/macro';

export const Nav = styled.nav`
  display: flex;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const NavRight = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoImage = styled.img`
  width: 155px;
`;
