import styled from 'styled-components/macro';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordion from '@material-ui/core/Accordion';
import { color } from '../../../styles/style';
import Typography from '@material-ui/core/Typography';

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const List = styled.div`
  padding: 1rem;
`;

export const SubjectInfo = styled.div`
  display: flex;

  .title {
    font-weight: bold;
    padding-right: 1.5rem;
  }

  .usedOff {
    color: ${color.redMain};
    font-weight: bold;
  }
`;

export const TopicInfo = styled(SubjectInfo)`
  padding: .5rem 0;
  border-bottom: 1px solid #e8e8e8;
`;

export const Accordion = styled(MuiAccordion)`
  &.Mui-expanded {
    margin: 0
  }
  
  &.Mui-expanded:before {
    opacity: 1
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  min-height: 64px;
`;

export const SectionTitle = styled(Typography)`
  padding: 1rem;
  margin-top: 1em;
  font-weight: bold;
`;