// import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { color, fontWeight } from '../../styles/style';

const TextEmptyState = styled.div`
  text-align: center;
  padding: 2rem;
  color: ${color.blueLight};
  font-weight: ${fontWeight.bold};
`;

TextEmptyState.propTypes = {};

export default TextEmptyState;
