import React from 'react';
import format from 'date-fns/format';
import isDate from 'date-fns/is_date';

const SimpleDate = props => {
  const raw = props.children;

  let time;
  if (isDate(raw)) {
    return <span>{format(time, 'MM/DD/YY')}</span>;
  } else {
    const date0 = new Date(null);
    let secs;
    if (raw.hasOwnProperty('_seconds')) {
      secs = raw._seconds;
    } else if (raw.hasOwnProperty('seconds')) {
      secs = raw.seconds;
    } else {
      secs = 1550607540;
    }
    const newraw = date0.setTime(secs * 1000);

    return <span>{format(newraw, 'MM/DD/YY')}</span>;
  }
};

export default SimpleDate;
