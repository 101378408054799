import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components/macro';
import { BarChart, ResponsiveContainer, Bar, Legend, XAxis } from 'recharts';

import { color } from '../../styles/style';

const RenderLabel = props => {
  const { x, y, width, value, data, index, dataKey } = props;
  const dataObj = data[index];
  const ybuffer = 20;

  const fillColor = dataKey === 'incorrect' ? color.redDark : color.secondary;
  if (value > 0) {
    return (
      <g>
        <text
          x={x + width / 2}
          y={y + ybuffer}
          fill={fillColor}
          textAnchor='middle'
          dominantBaseline='middle'
          fontSize='13px'
          fontWeight='bold'
        >
          {dataObj[dataKey]}
        </text>
      </g>
    );
  }
  return null;
};
class ChartSideBySide extends Component {
  render() {
    const { data, width, height, minWidth } = this.props;

    return (
      <ResponsiveContainer width={width} height={height} minWidth={minWidth}>
        <BarChart
          data={data}
          margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
        >
          <XAxis
            axisLine={false}
            tickLine={true}
            stroke={color.dark}
            height={15}
            style={{ fontSize: 13, fontWeight: 600 }}
            dataKey='subjectLabel'
          />

          {/* <CartesianGrid strokeDasharray="1 1" /> */}
          {/* <XAxis dataKey="rawLabel" axisLine={false} tickLine={false} /> */}

          {/* // allowDataOverflow={true}
            axisLine={false}
            tickLine={false}
            dataKey="subjectLabel"
            position="bottom"
            fontSize="13px"
            fontWeight={600}
            fill={color.dark}
          /> */}
          <Legend
            verticalAlign='top'
            align='right'
            iconType='circle'
            iconSize={10}
            height={56}
          />

          <Bar
            stackId='a'
            type='monotone'
            dataKey='correct'
            fill={color.secondaryLight}
            label={<RenderLabel dataKey='correct' data={data} />}
            radius={[8, 8, 8, 8]}
          >
            {/* <LabelList
              dataKey="rawLabel"
              position="bottom"
              fontSize="13px"
              fontWeight={600}
              fill={color.dark}
            /> */}
          </Bar>

          <Bar
            stackId='b'
            type='monotone'
            dataKey='incorrect'
            fill={color.redLightest}
            label={<RenderLabel dataKey='incorrect' data={data} />}
            radius={[8, 8, 8, 8]}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

ChartSideBySide.propTypes = {};

export default ChartSideBySide;
