import React from 'react';
import * as d3 from 'd3';
import { get } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';

import Button from '../../../../elements/Button';
import Loading from '../../../../components/Loading';
import { MainScreen } from '../../../../containers/Main';
import subjectTopics from '../../../../_CONS/subjectTopics';
import * as routes from '../../../../_CONS/routes';
import StatsRowFlat from '../../../../components/StatsRowFlat';
import { Card, CardContent } from '../../../../containers/Card';
import msToStringHuman from '../../../../utils/msToStringHuman';
import { reviewGuide } from '../../../../store/actions/guideActions';
import SubjectListCard from './SubjectListCard';
import { Redirect } from 'react-router';
import getSectionFromUrl from '../../../../utils/getSectionFromUrl';
import {
  OverviewTableWrapper,
  TableTitles,
  TableSubjectTitle,
  DurationText,
  TableFooter,
  styles,
} from './StyledGuideOverview';
import BackButton from '../../../../elements/BackButton';

class GuideOverview extends React.Component {
  constructor(props) {
    super(props);
    this.reviewTheCurrentList = this.reviewTheCurrentList.bind(this);
    this.goBackScreen = this.goBackScreen.bind(this);
    this.reviewIncorrectList = this.reviewIncorrectList.bind(this);
  }

  reviewIncorrectList() {
    const { guideType, guideId } = this.props;
    const queries = [['guideId', '==', guideId], ['isCorrect', '==', false]];

    const guideObj = {
      guideType,
      guideId,
      queries,
      status: '',
    };
    this.props.reviewGuide(guideObj).then(() => {
      this.props.history.push(`${this.props.location.pathname}/guide`);
    });
  }

  reviewTheCurrentList() {
    const { guideType, guideId } = this.props;
    const queries = [['guideId', '==', guideId]];

    const guideObj = {
      guideType,
      guideId,
      queries,
    };
    this.props.reviewGuide(guideObj).then(() => {
      this.props.history.push(`${this.props.location.pathname}/guide`);
    });
  }

  goBackScreen(e) {
    this.props.history.push(`/student/review`);

    e.preventDefault();
  }

  buildStats() {
    const { currentQuestions } = this.props;
    const grandTotal = d3
      .nest()
      .rollup(function (leaves) {
        return leaves.length;
      })
      .entries(currentQuestions);

    const isCorrectBreakdown = d3
      .nest()
      .key(function (d) {
        return d.isCorrect;
      })
      .rollup(function (leaves) {
        return leaves.length;
      })
      .map(currentQuestions);
    const correct = isCorrectBreakdown.get('true')
      ? isCorrectBreakdown.get('true')
      : 0;
    const wrong = isCorrectBreakdown.get('false')
      ? isCorrectBreakdown.get('false')
      : 0;
    const total = parseInt(grandTotal);
    const overallRaw = (correct / total) * 100;
    const overall = isNaN(overallRaw) ? 0 : overallRaw.toFixed(1);
    const stats = {
      correct,
      wrong,
      overall,
      total,
    };
    return stats;
  }

  arrayToObject = (array, keyField) => array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

  buildQuestionRow() {
    const { currentQuestions } = this.props;
    const bySubjectCode = d3
      .nest()
      .key(function (d) {
        return d.subjectCode;
      })
      .entries(currentQuestions);
    return bySubjectCode.map(function (item) {
      const innerarray = item.values;
      const total = innerarray.length;
      const correct = innerarray.filter(q => q.isCorrect === true).length;
      const wrong = total - correct;
      const overallRaw = (correct / total) * 100;
      const overall = `${overallRaw.toFixed(1)}%`;
      const subjectObject = subjectTopics[item.key];
      const subjectLabel = subjectObject.label;
      const subclass = d3.nest()
        .key(d => d.topicCode)
        .entries(innerarray);
      return {
        subject: item.key,
        subjectLabel,
        total: total,
        correct: correct,
        wrong: wrong,
        overall: overall,
        questions: innerarray,
        topics: subclass.map(function (qs) {
          const groupArray = qs.values;
          const groupTotal = groupArray.length;
          const groupCorrect = groupArray.filter(q => q.isCorrect === true)
            .length;
          const groupWrong = groupTotal - groupCorrect;
          const groupOverallRaw = (groupCorrect / groupTotal) * 100;
          const groupOverall = `${groupOverallRaw.toFixed(1)}%`;

          const topicLabel = subjectTopics[item.key].topics[qs.key]
            ? subjectTopics[item.key].topics[qs.key].label
            : 'qa';
          return {
            topic: qs.key,
            topicLabel: topicLabel,
            total: groupTotal,
            correct: groupCorrect,
            wrong: groupWrong,
            overall: groupOverall,
          };
        }),
      };
    });
  }
  render() {
    const { currentGuide, currentQuestions, classes, guideId, match } = this.props;

    if (!guideId) {
      return <Redirect to={`${routes.STUDENT}/${getSectionFromUrl(match.path)}`} />;
    }

    if (isLoaded(currentGuide, currentQuestions)) {
      const subjectList = this.buildQuestionRow();
      return (
        <MainScreen
          title={currentGuide.name}
          leftSideItem={<BackButton label={'REVIEW'} onClick={this.goBackScreen} />}
          rightSideItem={
            <DurationText>
              Duration: {msToStringHuman(currentGuide.duration)}
            </DurationText>
          }
          spacing={5}
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <StatsRowFlat stats={this.buildStats()} />
              </CardContent>
              <OverviewTableWrapper>
                <Grid
                  container
                  spacing={3}
                  alignItems='center'
                  className={classes.tableTitleRow}
                  justify='space-between'
                >
                  <Grid className={classes.topRow} item xs={8} sm={3}>
                    <TableSubjectTitle>subjects</TableSubjectTitle>
                  </Grid>
                  <Grid className={classes.topRow} item xs={4} sm={1}>
                    <TableTitles textalign='center'>overall</TableTitles>
                  </Grid>
                  <Grid className={classes.topRow} item xs={4} sm={1}>
                    <TableTitles textalign='center'>total</TableTitles>
                  </Grid>
                  <Grid className={classes.topRow} item xs={4} sm={1}>
                    <TableTitles textalign='center'>correct</TableTitles>
                  </Grid>
                  <Grid className={classes.topRow} item xs={4} sm={1}>
                    <TableTitles textalign='center'>incorrect</TableTitles>
                  </Grid>
                  <Grid className={classes.topRow} item xs={6} sm={3} />
                </Grid>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs={12}>
                    {subjectList.map((qgroup, i) => <SubjectListCard key={i} qgroup={qgroup} />)}
                  </Grid>
                </Grid>
              </OverviewTableWrapper>
              <CardContent>
                <TableFooter>
                  <Button
                    className={classes.buttonRed}
                    disabled={!currentGuide.questionsWrong}
                    onClick={() => this.reviewIncorrectList(currentGuide.id)}
                  >
                    REVIEW INCORRECT
                  </Button>
                  <Button
                    background='secondary'
                    onClick={() => this.reviewTheCurrentList(currentGuide.id)}
                  >
                    REVIEW ALL
                  </Button>
                </TableFooter>
              </CardContent>
            </Card>
          </Grid>
        </MainScreen>
      );
    }

    return <Loading />;
  }
}

GuideOverview.propTypes = {};

const mapStateToProps = ({ guideStatus, firebase }) => ({
  guideId: guideStatus.guideId,
  guideType: guideStatus.guideType,
  levelQbank: firebase.profile.studentBank,
  qColl: guideStatus.guideType === 'testguides' ? 'tgquestions' : 'sgquestions',
  studentId: firebase.auth.uid,
});

const mapDispatchToProps = { reviewGuide };

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => {
    if (!props.guideId) return [];
    return [
      {
        collection: get(props, 'levelQbank'),
        doc: get(props, 'studentId'),
        subcollections: [
          { collection: get(props, 'guideType'), doc: get(props, 'guideId') },
        ],
        storeAs: 'guideCurrent',
      },
      {
        collection: get(props, 'levelQbank'),
        doc: get(props, 'studentId'),
        subcollections: [
          {
            collection: get(props, 'qColl'),
            where: ['guideId', '==', get(props, 'guideId')],
            orderBy: [['qnum']],
          },
        ],
        storeAs: 'guideCurrentQuestions',
      },
    ];
  }),
  connect(state => ({
    currentGuide: get(state, 'firestore.data.guideCurrent'),
    currentQuestions: get(state, 'firestore.ordered.guideCurrentQuestions'),
  })),
  withStyles(styles)
)(GuideOverview);
