import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import firebase from '../../../../../store/api/firebaseAdmin';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TRootState } from '../../../../../store/reducers/index';

import subjectTopics from '../../../../../_CONS/subjectTopics';
import { Card } from '../../../../../containers/Card';
import {
  SubjectCardContent,
  ControlContainer,
  QuestionCounter,
  QuestionInput,
  CountBtn,
  TopicControl,
  SubjectControl,
  TipSpan,
  Link,
  PromoText,
} from './StyledSubjectSelector';
import { TGuideQuestion } from '../../../../../../types';
import { getTopicsFromClassification } from '../../../../../utils/getTopicsFromClassification';

type TProps = TPropsFromRedux & {
  collection: string,
  studentId: string,
  data: {
    label: string,
    value: string
  },
  questions: any[],
  changeNum: (data: {
    subject: string,
    number: number,
    topics: {
      topic: string,
      number: number
    }[]
  }) => void,
};

type TTopic = {
  value: string,
  label: string,
  questionsNumber: number
}

type TState = {
  isReady: boolean,
  questions: any[],
  checked: boolean,
  checkedTopics: string[],
  value: number,
  total: number,
  defValue: number,
  subjectCode: string,
  topics: {
    [key: string]: TTopic
  }
}

class SubjectSelector extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    const subjectCode = props.data.value.split("-")[1].toUpperCase();
    const topics = subjectTopics[subjectCode]['topics'];

    this.state = {
      isReady: false,
      questions: [],
      checked: false,
      checkedTopics: [],
      value: 0,
      total: 0,
      subjectCode: subjectCode,
      defValue: props.collection === 'students-free' ? 2 : 10,
      topics: Object.keys(topics)
        .reduce((result, topicKey) => {
          return {
            ...result,
            [topicKey]: {
              value: topicKey,
              label: topics[topicKey].label,
              questionsNumber: 0
            }
          };
        }, {})
    };
  }

  componentDidMount = () => {
    this.getQuestions();
  }

  componentDidUpdate = (props: TProps, state: TState) => {
    if (!state.questions.length && this.state.questions.length) {
      this.calculateQuestionsPerTopic(this.state.questions);
    }
  }

  getQuestions = () => {
    const { collection, studentId, data } = this.props;
    const studentLevelCollection = collection;
    const qBankCollection = data.value;

    if (studentLevelCollection && studentId && qBankCollection) {
      firebase.firestore()
        .collection(studentLevelCollection)
        .doc(studentId)
        .collection(qBankCollection)
        .where('selected', '==', false)
        .where("qdata.published", "==", true)
        .get()
        .then((querySnapshot) => {
          const questions = [];

          querySnapshot.forEach((doc) => {
            questions.push(doc.data())
          })

          this.setState({
            questions,
            total: questions.length,
            isReady: !questions.length,
          });
        })

    }
  }

  calculateQuestionsPerTopic = (questions: TGuideQuestion[]) => {
    let topics = { ...this.state.topics };


    questions.forEach((question) => {
      const questionTopics = question.topics || getTopicsFromClassification(question.classification);

      questionTopics.forEach((topic) => {
        if (topics[topic]) {
          topics[topic].questionsNumber += 1;
        }
      })
    });

    this.setState({ topics, questions: [], isReady: true })
  }

  addQuestion = (e) => {
    const { value, total } = this.state;
    const { data, changeNum } = this.props;
    const max = total;
    const newValue = value + 1 >= max ? max : value + 1;
    const newTotal = max - newValue;

    changeNum({
      subject: data.value,
      number: newValue,
      topics: []
    });
    this.setState({ value: newValue, total: newTotal });
  }

  minusQuestion = (e) => {
    const { value, total } = this.state;
    const { data, changeNum } = this.props;
    const max = total;
    const min = 0;
    const newValue = value - 1 <= min ? min : value - 1;
    const newTotal = max - newValue;

    changeNum({
      subject: data.value,
      number: newValue,
      topics: []
    });
    this.setState({ value: newValue, total: newTotal });
  }

  changeValue = (e) => {
    const val = e.target.value.replace(/\D/, "");

    const { total } = this.state;
    const { data, changeNum } = this.props;
    const max = total;
    const inputValue = Math.abs(val);

    const newValue = inputValue >= max ? max : inputValue;
    const newTotal = max - newValue;

    changeNum({
      subject: data.value,
      number: newValue,
      topics: []
    });
    this.setState({ value: newValue, total: newTotal <= 0 ? 0 : newTotal });
  }

  handleCheckBoxChange = (name) => (event) => {
    const { checked, defValue, total } = this.state;
    const { data, changeNum } = this.props;

    if (!checked) {
      var defVal = defValue >= total ? total : defValue;
      this.setState({
        checked: true,
        value: defVal,
        total: total,
      });
      changeNum({
        subject: data.value,
        number: defVal,
        topics: []
      });
    } else {
      changeNum({
        subject: data.value,
        number: 0,
        topics: []
      });
      this.setState({ checked: false, value: 0 });
    }
  };

  handleTopicCheck = (name: string) => (event) => {
    if (this.props.accountType === 'pro') {
      const { data, changeNum } = this.props;
      const { checkedTopics, value, topics, total } = this.state;

      let newValue = value;
      let newChecked = null;

      if (event.target.checked) {
        newChecked = [...checkedTopics, name];
      } else {
        newChecked = checkedTopics.filter((topic) => topic !== name);
      }

      newValue = newChecked.reduce((result: number, topic: string) =>
        newChecked.includes(topic) ? result += topics[topic].questionsNumber : result, 0);

      if (newValue > total) {
        newValue = total;
      }

      changeNum({
        subject: data.value,
        number: newValue,
        topics: newChecked.map((topicKey) => ({
          topic: topicKey,
          number: topics[topicKey].questionsNumber
        }))
      });

      this.setState({
        checkedTopics: newChecked,
        value: newValue
      });
    }
  }

  render() {
    const { data, accountType } = this.props;
    const { checked, value, topics, checkedTopics, subjectCode, isReady, total } = this.state;

    return (
      <Grid item data-testid={'subjectSelector'}>
        <Card>
          <SubjectCardContent>
            {!isReady ? (
              <LinearProgress color="secondary" variant="query" />
            ) : (
              <ControlContainer>
                <SubjectControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={total < 1}
                        checked={checked}
                        onChange={this.handleCheckBoxChange('checked')}
                        value="checked"
                        color="primary"
                      />
                    }
                    label={data.label}
                    aria-label="Subject Checkbox"
                  />
                </SubjectControl>

                <QuestionCounter>
                  <CountBtn
                    disabled={!checked || value <= 0 || !!checkedTopics.length}
                    aria-label="Minus"
                    onClick={this.minusQuestion}
                  >
                    <MinusIcon fontSize="small" />
                  </CountBtn>
                  <QuestionInput
                    max={total}
                    min={0}
                    value={value}
                    onChange={this.changeValue}
                    readOnly={!checked}
                    title="Number of Questions"
                  />
                  {checked ? (
                    value >= total ? (
                      <Tooltip title="You've added all available questions on this topic">
                        <TipSpan>
                          <CountBtn disabled={true} aria-label="Add">
                            <AddIcon fontSize="small" />
                          </CountBtn>
                        </TipSpan>
                      </Tooltip>
                    ) : (
                      <CountBtn
                        disabled={!checked || !!checkedTopics.length}
                        aria-label="Add"
                        onClick={this.addQuestion}
                      >
                        <AddIcon fontSize="small" />
                      </CountBtn>
                    )
                  ) : (
                    <CountBtn disabled={true} aria-label="Add">
                      <AddIcon fontSize="small" />
                    </CountBtn>
                  )}
                </QuestionCounter>
              </ControlContainer>
            )}
          </SubjectCardContent>
        </Card>

        {
          checked && <Card style={{ marginTop: '.5rem', marginBottom: '1rem', paddingLeft: '2rem', paddingBottom: '1rem' }}>
            <SubjectCardContent>
              {
                Object.keys(topics).map((topicKey) => (
                  <TopicControl key={`${subjectCode}-${topicKey}`} data-testid={"topic"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={accountType !== 'pro'}
                          checked={checkedTopics.includes(topicKey)}
                          onChange={this.handleTopicCheck(topicKey)}
                          color="primary"
                        />
                      }
                      label={`${topics[topicKey].label}`}
                    />
                  </TopicControl>
                ))
              }
            </SubjectCardContent>
          </Card>
        }
      </Grid>
    );
  }
}

const mapStateToProps = (state: TRootState) => ({
  accountType: state.firebase.profile.accountType
});

const connector = connect(mapStateToProps);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SubjectSelector);
