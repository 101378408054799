import styled from 'styled-components/macro';
import MuiAvatar from '@material-ui/core/Avatar';

export const Avatar = styled(MuiAvatar)`
  width: 90px;
  height: 90px;
  `;

export const UploadArea = styled.div`
  width: 90px;
  margin: auto;
  margin-bottom: 20px;
`;
