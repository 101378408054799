import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components/macro';
import { color, fontWeight } from '../../styles/style';

const StatWrapper = styled.div`
  padding: 1.5rem 0;
  text-align: center;
  border: 1px solid rgba(224, 224, 224, 1);

  ${({ type }) => (type === 'correct' && css`
    background-color: ${color.secondaryLight};
    border-color: ${color.secondaryLight};
  `)};
  ${({ type }) => (type === 'wrong' && css`
    background-color: ${color.redLightest};
    border-color: ${color.redLightest};
  `)};
`;

const Stat = styled.div`
  color: ${color.blueDarkest};
  font-size: 1.25rem;
  font-weight: ${fontWeight.bold};

  ${props => props.type === 'correct' && css`
    color: ${color.secondaryDark};
  `};
  ${props => props.type === 'wrong' && css`
    color: ${color.redMain};
  `};

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Label = styled.div`
  margin-bottom: .5rem;
  color: ${color.blueDarkest};
  font-size: 1rem;
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;

  ${props => props.type === 'correct' && css`
    color: ${color.secondaryDark};
  `};
  ${props => props.type === 'wrong' && css`
    color: ${color.redMain};
  `};

  @media (min-width: 768px) {
    font-size: 1.3rem;
  }
`;

const ReviewResultBox = props => {
  const stattext = props.percent ? `${props.stat}%` : `${props.stat}`;
  return (
    <>
      <StatWrapper type={props.type} >
        <Stat type={props.type}>{stattext}</Stat>
        <Label type={props.type}>{props.label}</Label>
        {props.children}
      </StatWrapper>
    </>
  );
};

ReviewResultBox.propTypes = {
  type: PropTypes.oneOf(['correct', 'wrong', 'default']),
};

ReviewResultBox.defaultProps = {
  type: 'default',
};

export default ReviewResultBox;
