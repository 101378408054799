import firebase from '../api/firebaseAdmin';
import {GuideActions} from './guideActions';
import {actionCreator} from '../../utils/actionCreator';

type TStudyGuideConfig = {
  subjectCodes: {
    [key: string]: {
      label: string,
      value: string,
      num: 10
    }
  },
  showNotes: boolean,
  showFeedback: boolean,
  showTimer: boolean,
}

type TStudyGuide = {
  guideType: string,
  section: string
  status: string
}

export const createStudyGuide = (guide: TStudyGuideConfig, gObj: TStudyGuide) => {
  return async (dispatch, getState) => {
    dispatch(actionCreator(GuideActions.BUILD, {
      status: 'loading',
      guideType: 'studyguides'
    }))

    const createdDate = new Date();
    const { subjectCodes, ...guideFields } = guide;

    const firestore = firebase.firestore();
    const studentId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;

    const questionsPerSubject = Object.values(subjectCodes).filter(subject => subject.num > 0);
    const totalQuestions = Object.keys(subjectCodes).reduce((sum, next) => (
      sum + subjectCodes[next].num
    ), 0);

    const studentRef = firestore.collection(levelQbank).doc(studentId);

    let studyGuideId: string;

    await firestore.runTransaction(function (transaction) {
      return transaction.get(studentRef).then(function (studentDoc) {
        if (!studentDoc.exists) {
          throw new Error('No Student Doc!');
        }
        const currentCount = studentDoc.data().sgCount ? studentDoc.data().sgCount : 0;
        const newCount = currentCount + 1;
        transaction.update(studentRef, { sgCount: newCount });
        return newCount;
      });
    }).then(function (newCount) {
      const studyGuides = firestore.collection(levelQbank).doc(studentId).collection('studyguides');

      const studyGuideDoc = studyGuides.doc();
      studyGuideId = studyGuideDoc.id;
      const sgObj = {
        ...guideFields,
        uid: studyGuideId,
        name: `Study Aid ${newCount}`,
        guideNum: newCount,
        type: 'study',
        createdDate,
        completed: false,
        questionsPerSubject,
        questionsCorrect: 0,
        questionsWrong: 0,
        time: -1,
        questionsTotal: Number(totalQuestions),
      };
      studyGuideDoc
        .set(sgObj)
        .then(() => {
          dispatch(_getNewGuideQuestions(studyGuideId, sgObj)).then(() => {
            dispatch(actionCreator(GuideActions.BUILD, {
              status: 'success',
              guideId: studyGuideId,
              guideType: 'studyguides'
            }))
          });
        })
        .catch(err => {
          dispatch(actionCreator(GuideActions.BUILD, err.message || JSON.stringify(err), true));
        });
    })
      .catch(function (err) {
        dispatch(actionCreator(GuideActions.BUILD, err.message || JSON.stringify(err), true));
      });
  };
};

const _getNewGuideQuestions = (guideId, guide) => {
  return async (dispatch, getState) => {
    const firestore = firebase.firestore();

    const authId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;
    const questionsPerSubject = guide.questionsPerSubject.map((question) => ({
      ...question,
      topics: question.topics.filter((topic, index) => topic.number > 0 && index < 10)
    }))
    try {
      let queryResults = questionsPerSubject.map(function (d, i) {
        let query = firestore.collection(levelQbank).doc(authId)
        .collection(d.value)
        .where('selected', '==', false)
        .where("qdata.published", "==", true)

        if (d.topics.length) {
          query = query.where('topics', 'array-contains-any', d.topics.map((item) => item.topic));
        }

        return query.limit(d.num).get();
      });
      await Promise.all(queryResults).then(function (querySnapshots) {
        return dispatch(_migrateResults(guideId, querySnapshots));
      });
    } catch (error) {
      console.error('_getNewGuideQuestions',error);
    }
  };
};
const _migrateResults = (guideId, qSets) => {
  return async (dispatch, getState) => {
    try {
      const setProm = await qSets.map(qs => {
        return dispatch(_migrateQuestions(guideId, qs.docs));
      });
      await Promise.all(setProm).then(() => {
        return dispatch(_updateGuidesQuestions(guideId));
      });
    } catch (error) {
      console.error( '_migrateResults', error);
    }
  };
};
const _updateGuidesQuestions =( guideId) => {
  return async (dispatch, getState) => {
    try{
      const firestore = firebase.firestore();
      const authId = getState().firebase.auth.uid;

      const levelQbank = getState().firebase.profile.studentBank;
      const studentRef = firestore.collection(levelQbank).doc(authId);
      const sgqRef = studentRef.collection('sgquestions');
      var guideRef = firestore
          .collection(levelQbank)
          .doc(authId)
          .collection('studyguides')
          .doc(guideId);
      var batch = firestore.batch();
      const querySnapshot = await sgqRef
          .where('guideId', '==', guideId)
          .get()
        let qcount = 0;
        let guideQuestions = [];

      const shuffledArray = shuffleArray(querySnapshot)
      shuffledArray.forEach(function (doc) {
          qcount = qcount + 1;
          var docRef = sgqRef.doc(doc.id);
          const { classification, subjectCode, qdata } = doc.data();
          const correctAnswer = qdata.correctAnswer;
          const classRaw = classification[0].split('-');
          const topicCode = classRaw[0].trim();
          let subTopicCode = '';
          if (classRaw.length > 1) {
            subTopicCode = classRaw[1].trim();
          }

          guideQuestions.push({
            qid: doc.id,
            qnum: qcount,
            subjectCode: subjectCode,
            classification: classification[0],
            answered: false,
            userAnswer: null,
            userNotes: null,
            correctAnswer,
            isCorrect: false,
          });
          batch.update(docRef, {
            guideId: guideId,
            qnum: qcount,
            selected: true,
            selectedDate: new Date(),
            topicCode,
            subTopicCode,
          });
        });
        batch.update(guideRef, {
          questions: guideQuestions,
        });
        return batch.commit();
      // }
    } catch (e) {
      console.error('_updateGuidesQuestions Error',e)
    }

  };
};
const _migrateQuestions = (guideId, questions) => {
  return (dispatch, getState) => {
    const firestore = firebase.firestore();
    const authId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;
    const studentRef = firestore.collection(levelQbank).doc(authId);
    const sgqRef = studentRef.collection('sgquestions');
    var batch = firestore.batch();

    questions.forEach(function (q) {
      const { qbank } = q.data();
      var bankRef = studentRef.collection(qbank).doc(q.id);
      batch.update(bankRef, {
        selected: true,
        selectedDate: new Date(),
      });
      var docRef = sgqRef.doc(q.id);

      batch.set(docRef, {
        ...q.data(),
        guideId: guideId,
        selected: true,
        selectedDate: new Date(),
      });
    });

    return batch.commit();
  };
};

export const buildTestGuide = (guide, gObj) => {
  return async (dispatch, getState) => {
    const firestore = firebase.firestore();
    const authId = getState().firebase.auth.uid;
    const levelQbank = getState().firebase.profile.studentBank;
    const guideId = guide.uid;

    if (guide.created) {
      return dispatch(actionCreator(GuideActions.BUILD, {
        guideId,
        status: 'success',
        guideType: 'testguides'
      }, true));
    }

    dispatch(actionCreator(GuideActions.BUILD, {
      status: 'loading',
      guideType: 'testguides'
    }));

    const guideCollectionRef = await firestore
      .collection(levelQbank)
      .doc(authId)
      .collection('testguides');

    const guideDocRef = guideCollectionRef.doc(guideId);

    await dispatch(_getNewTestGuideQuestions(guideId, guide, levelQbank));
    await guideDocRef
      .update({ ...guide, created: true, createdDate: new Date() })
      .then(() => {
        dispatch(actionCreator(GuideActions.BUILD, {
          guideId,
          status: 'success',
          guideType: 'testguides'
        }));
      })
      .catch((err) => {
        dispatch(actionCreator(GuideActions.BUILD, err.message || JSON.stringify(err), true));
      });
  };
};

const _getNewTestGuideQuestions = (guideId, testGuide, levelQbank) => {
  return async (dispatch, getState) => {
    const firestore = firebase.firestore();
    const authId = getState().firebase.auth.uid;
    await firestore
      .collection(levelQbank)
      .doc(authId)
      .collection(testGuide.qbank)
      .orderBy('random')
      .limit(testGuide.questionsTotal)
      .get()
      .then(function (querySnapshot) {
        return dispatch(
          _convertQuestionsForTestGuide(guideId, querySnapshot, levelQbank)
        );
      })
      .catch((err) => {
        dispatch(actionCreator(GuideActions.BUILD, err.message || JSON.stringify(err), true));
      });
  };
};
const _convertQuestionsForTestGuide = (guideId, questions, levelQbank) => {
  return async (dispatch, getState) => {
    const firestore = firebase.firestore();
    const authId = getState().firebase.auth.uid;
    const tgQuestionsRef = await firestore
      .collection(levelQbank)
      .doc(authId)
      .collection('tgquestions');

    var guideRef = firestore
      .collection(levelQbank)
      .doc(authId)
      .collection('testguides')
      .doc(guideId);
    var batch = firestore.batch();
    let qcount = 0;
    let guideQuestions = [];
    await questions.forEach(function (element) {
      qcount = qcount + 1;
      var docRef = tgQuestionsRef.doc(element.id);
      const { classification, subjectCode, qdata } = element.data();
      const correctAnswer = qdata.correctAnswer;
      const classRaw = classification[0].split('-');
      const topicCode = classRaw[0].trim();
      let subTopicCode = '';
      if (classRaw.length > 1) {
        subTopicCode = classRaw[1].trim();
      }

      guideQuestions.push({
        qid: element.id,
        qnum: qcount,
        subjectCode: subjectCode,
        classification: classification[0],
        answered: false,
        userAnswer: null,
        userNotes: null,
        correctAnswer,
        isCorrect: false,
      });
      batch.set(docRef, {
        ...element.data(),
        guideId: guideId,
        qnum: qcount,
        selected: true,
        selectedDate: new Date(),
        topicCode,
        subTopicCode,
      });
    });
    batch.update(guideRef, {
      questions: guideQuestions,
    });
    return batch.commit().then(async function () {
      return await Promise.resolve();
    });
  };
};

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

