/*** ui and style ***/
import styled from 'styled-components/macro';

export const Guide = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
  &&:focus {
    outline: none;
  }
`;
export default Guide;
