import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { FormControl } from '@material-ui/core';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import appCopy from '../../_CONS/appCopy';
import Button from '../../elements/Button';
import { MainScreen } from '../../containers/Main';
import { TimerCheckbox, TimeLabel } from './StyledQuesstionStart';
import { Card, CardContent, CardFooter } from '../../containers/Card';

const QuestionStart = props => {
  const [customTimeFlag, setCustomTimeFlag] = useState(false);
  const [customTime, setCustomTime] = useState(null);

  const { guide } = props;
  const title = `${guide.name}`;
  const type = guide.type;
  let guideType, startText, note;
  const isReturn = guide.started ? true : false;

  if (type === 'test') {
    guideType = guide.testType === 'final' ? 'Practice Final Exam' : 'Practice Exam';
    startText = `You are about to begin a ${guideType}. Please ensure that you have ample time to complete the exam as your exam will be scored automatically upon exiting the online exam environment.`;
    note = guide.testType === 'final' ? '' : appCopy.TEST.TRIAL.OPE.note;
  } else {
    guideType = 'Study Aid';
    startText = isReturn
      ? `You are about to continue with a ${guideType}. We recommend reviewing each question slowly to ensure that you understand the law and reasoning behind it. These questions are not timed.`
      : `You are about to begin a ${guideType}. We recommend reviewing each question slowly to ensure that you understand the law and reasoning behind it. These questions are not timed.`;
    note = appCopy.STUDY.note;
  }

  useEffect(() => {
    if (!customTimeFlag) {
      setCustomTime(null);
    }
  }, [customTimeFlag]);

  return (
    <MainScreen title={title}>
      <Grid container justify='center' alignItems='center' item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="body2" paragraph>{startText}</Typography>
            {note && <Typography display="inline" variant="subtitle2">Please note: </Typography>}
            <Typography display="inline" variant="body2">{note}</Typography>
          </CardContent>

          {
            !isReturn && (
              <CardFooter>
                <Grid container>
                  <Grid item xs={12}>
                    <TimerCheckbox
                      checked={customTimeFlag}
                      label={`I’d like to change the ${guideType} time`}
                      onChange={(event, checked) => { setCustomTimeFlag(checked); }}
                      control={<Checkbox />}
                    />
                  </Grid>
                  {
                    customTimeFlag && (
                      <Grid item container alignItems='center'>
                        <TimeLabel display='inline' variant='body1'>Time:</TimeLabel>

                        <FormControl>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardTimePicker
                              ampm={false}
                              placeholder={'00:00:00'}
                              format={"HH:mm:ss"}
                              KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                              views={["hours", "minutes", "seconds"]}
                              value={customTime}
                              onChange={setCustomTime}
                              invalidDateMessage={'Invalid time format'}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </Grid>
                    )
                  }
                </Grid>
              </CardFooter>
            )
          }
        </Card>
      </Grid>
      <Grid container justify='center' alignItems='center' item xs={12}>
        <Button
          disabled={customTimeFlag && customTime && !customTime.isValid()}
          onClick={() => props.guideAction(customTimeFlag && customTime ? customTime.format("HH:mm:ss") : null)}>
          {isReturn ? 'CONTINUE' : 'START'}
        </Button>
      </Grid>
    </MainScreen>
  );
};

QuestionStart.propTypes = {
  guide: PropTypes.object,
  guideAction: PropTypes.func,
};

export default QuestionStart;
