import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';

var config = {
  apiKey: "AIzaSyC8sVCr89kCJkBW_BMb0Mz6mylbKVwBq60",
  authDomain: "mc-jdadvising-31f77.firebaseapp.com",
  databaseURL: "https://mc-jdadvising-31f77-default-rtdb.firebaseio.com",
  projectId: "mc-jdadvising-31f77",
  storageBucket: "mc-jdadvising-31f77.appspot.com",
  messagingSenderId: "753181215351",
  appId: "1:753181215351:web:890b26e3bed7b32aa5d472",
  measurementId: "G-3677QRNXPJ"
};

firebase.initializeApp(config);

export default firebase;
