import React from 'react';
import { compose } from 'redux';

import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';

import Loading from '../components/Loading';

const userIsAuthenticatedDefaults = {
  authenticatingSelector: ({ firebase: { auth } }) => !auth.isLoaded && auth.isEmpty,
  authenticatedSelector: ({ firebase: { auth } }) => auth.isLoaded && !auth.isEmpty,
  wrapperDisplayName: 'UserIsAuthenticated',
};
export const userIsAuthenticated = connectedAuthWrapper(
  userIsAuthenticatedDefaults
);

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  AuthenticatingComponent: () => <Loading overflow={true} />,
  redirectPath: '/',
});

export const userIsAdmin = connectedRouterRedirect({
  redirectPath: '/student',
  allowRedirectBack: false,
  authenticatedSelector: ({ firebase: { profile } }) =>
    profile !== null && profile.role === 'admin',
  wrapperDisplayName: 'UserIsAdmin',
  authenticatingSelector: ({ firebase: { profile } }) => !profile.isLoaded,
  AuthenticatingComponent: () => <Loading overflow={true} />,
});

const userIsNotAuthenticatedDefaults = {
  authenticatedSelector: ({ firebase: { auth } }) =>
    !auth.isLoaded && auth.isEmpty,

  wrapperDisplayName: 'UserIsNotAuthenticated',
};

export const userIsNotAuthenticated = connectedAuthWrapper(
  userIsNotAuthenticatedDefaults
);

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  ...userIsNotAuthenticatedDefaults,
  redirectPath: '/admin',
  allowRedirectBack: false,
});

export const userIsStudent = connectedRouterRedirect({
  redirectPath: '/admin',
  allowRedirectBack: false,
  authenticatedSelector: ({ firebase: { profile } }) =>
    profile !== null && profile.role === 'student',
  wrapperDisplayName: 'UserIsStudent',
  authenticatingSelector: ({ firebase: { profile } }) => !profile.isLoaded,
  AuthenticatingComponent: () => <Loading overflow={true} />,
});

export const userIsCurrent = connectedRouterRedirect({
  redirectPath: '/setup',
  allowRedirectBack: false,
  authenticatedSelector: ({ firebase: { profile } }, { location }) => {
    if (profile !== null) {
      if (profile.status === 'current') {
        return true;
      } else if (location && location.pathname.indexOf('/upgrade') !== -1) {
        return true;
      }
    }

    return false;
  },
  wrapperDisplayName: 'UserIsCurrent',
  authenticatingSelector: ({ firebase: { profile } }) => !profile.isLoaded,
  AuthenticatingComponent: () => <Loading overflow={true} />,
});

export const userIsAdminChain = compose(
  userIsAuthenticatedRedir,
  userIsAdmin
);

export const userStudentChain = compose(
  userIsAuthenticatedRedir,
  userIsStudent,
  userIsCurrent
);
