const subjectTopics = {
  CRI: {
    label: 'Criminal Law',
    topics: {
      I: {
        label: 'Homicide',
        subtopics: {
          A: {
            label: 'Intended killings',
            subsubtopics: {
              1: {
                label: 'Premeditation, deliberation',
              },
              2: {label: 'Provocation'},
            },
          },
          B: {
            label: 'Unintended killings',
            subsubtopics: {
              1: {label: 'Intent to injure'},
              2: {label: 'Reckless and negligent killings'},
              3: {label: 'Felony murder'},
              4: {label: 'Misdemeanor manslaughter'},
            },
          },
        },
      },
      II: {
        label: 'Other crimes',
        subtopics: {
          A: {
            label: 'Theft and receiving stolen goods',
          },
          B: {
            label: 'Robbery',
          },
          C: {
            label: 'Burglary',
          },
          D: {
            label: 'Assault and battery',
          },
          E: {
            label: 'Rape; statutory rape',
          },
          F: {
            label: 'Kidnapping',
          },
          G: {
            label: 'Arson',
          },
          H: {
            label: 'Possession offenses',
          },
        },
      },
      III: {
        label: 'Inchoate crimes; parties',
        subtopics: {
          A: {
            label: 'Inchoate offenses',
            subsubtopics: {
              1: {label: 'Attempts'},
              2: {label: 'Conspiracy'},
              3: {label: 'Solicitation'},
            },
          },
          B: {label: 'Parties to crime'},
        },
      },
      IV: {
        label: 'General principles',
        subtopics: {
          A: {
            label: 'Acts and omissions',
          },
          B: {
            label: 'State of mind',
            subsubtopics: {
              1: {label: 'Required mental state'},
              2: {label: 'Strict liability'},
              3: {label: 'Mistake of fact or law'},
            },
          },
          C: {
            label: 'Responsibility',
            subsubtopics: {
              1: {label: 'Mental disorder'},
              2: {label: 'Intoxication'},
            },
          },
          D: {label: 'Causation'},
          E: {label: 'Justification and excuse'},
          F: {label: 'Jurisdiction'},
        },
      },
    },
  },
  CIV: {
    label: 'Civil Procedure',
    topics: {
      I: {
        label: 'Jurisdiction and venue',
        subtopics: {
          A: {
            label:
              'Federal subject-matter jurisdiction (federal question, diversity, supplemental, and removal)',
          },
          B: {
            label: 'Personal jurisdiction',
          },
          C: {
            label: 'Service of process and notice',
          },
          D: {
            label: 'Venue, forum non conveniens, and transfer',
          },
        },
      },
      II: {
        label: 'Law applied by federal courts',
        subtopics: {
          A: {
            label: 'State law in federal court',
          },
          B: {
            label: 'Federal common law',
          },
        },
      },
      III: {
        label: 'Pretrial procedures',
        subtopics: {
          A: {
            label: 'Preliminary injunctions and temporary restraining orders',
          },
          B: {
            label: 'Pleadings and amended and supplemental pleadings',
          },
          C: {
            label: 'Rule 11',
          },
          D: {
            label: 'Joinder of parties and claims (including class actions)',
          },
          E: {
            label:
              'Discovery (including e-discovery), disclosure, and sanctions',
          },
          F: {
            label: 'Adjudication without a trial',
          },
          G: {
            label: 'Pretrial conference and order',
          },
        },
      },
      IV: {
        label: 'Jury trials',
        subtopics: {
          A: {
            label: 'Right to jury trial',
          },
          B: {
            label: 'Selection and composition of juries',
          },
          C: {
            label: 'Requests for and objections to jury instructions',
          },
          D: {
            label: 'ZXZXZXZXZXZXZXZXX Missing',
          },
        },
      },
      V: {
        label: 'Motions',
        subtopics: {
          A: {
            label:
              'Pretrial motions, including motions addressed to face of pleadings, motions to dismiss, and summary judgment motions',
          },
          B: {
            label:
              'Motions for judgments as a matter of law (directed verdicts and judgments notwithstanding the verdict)',
          },
          C: {
            label:
              'Posttrial motions, including motions for relief from judgment and for new trial',
          },
        },
      },
      VI: {
        label: 'Verdicts and judgments',
        subtopics: {
          A: {label: 'Defaults and dismissals'},
          B: {label: 'Jury verdicts—types and challenges'},
          C: {label: 'Judicial findings and conclusions'},
          D: {label: 'Effect; claim and issue preclusion'},
        },
      },
      VII: {
        label: 'Appealability and review',
        subtopics: {
          A: {label: 'Availability of interlocutory review'},
          B: {label: 'Final judgment rule'},
          C: {label: 'Scope of review for judge and jury'},
        },
      },
    },
  },
  EVD: {
    label: 'Evidence',
    topics: {
      I: {
        label: 'Presentation of evidence',
        subtopics: {
          A: {
            label: 'Introduction of evidence',
            subsubtopics: {
              1: {label: 'Requirement of personal knowledge'},
              2: {label: 'Refreshing recollection'},
              3: {label: 'Objections and offers of proof'},
              4: {label: 'Lay opinions'},
              5: {label: 'Competency of witnesses'},
              6: {label: 'Judicial notice'},
              7: {label: 'Roles of judge and jury'},
              8: {label: 'Limited admissibility'},
            },
          },
          B: {
            label: 'Presumptions',
          },
          C: {
            label: 'Mode and order',
            subsubtopics: {
              1: {label: 'Control by court'},
              2: {label: 'Scope of examination'},
              3: {label: 'Form of questions'},
              4: {label: 'Exclusion of witnesses'},
            },
          },
          D: {
            label: 'Impeachment, contradiction, and rehabilitation',
            subsubtopics: {
              1: {label: 'Inconsistent statements and conduct'},
              2: {label: 'Bias and interest'},
              3: {label: 'Conviction of crime'},
              4: {label: 'Specific instances of conduct'},
              5: {label: 'Character for truthfulness'},
              6: {
                label: 'Ability to observe, remember, or relate accurately admissibility',
              },
              7: {label: 'Impeachment of hearsay declarants admissibility'},
              8: {label: 'Rehabilitation of impeached witnesses admissibility'},
              9: {label: 'Contradiction admissibility'},
            },
          },
          E: {
            label: 'Proceedings to which evidence rules apply',
          },
        },
      },
      II: {
        label: 'Relevancy and reasons for excluding relevant evidence',
        subtopics: {
          A: {
            label: 'Probative value',
            subsubtopics: {
              1: {
                label: 'Relevancy',
              },
              2: {
                label:
                  'Exclusion for unfair prejudice, confusion, or waste of time',
              },
            },
          },
          B: {
            label: 'Authentication and identification',
          },
          C: {
            label: 'Character and related concepts',
            subsubtopics: {
              1: {label: 'Admissibility of character'},
              2: {label: 'Methods of proving character'},
              3: {label: 'Habit and routine practice'},
              4: {label: 'Other crimes, acts, transactions, and events'},
              5: {label: 'Prior sexual misconduct of a defendant'},
            },
          },
          D: {
            label: 'Expert testimony',
            subsubtopics: {
              1: {label: 'Qualifications of witnesses'},
              2: {label: 'Bases of testimony'},
              3: {label: 'Ultimate issue rule'},
              4: {label: 'Reliability and relevancy'},
              5: {label: 'Proper subject matter for expert testimony'},
            },
          },
          E: {
            label: 'Real, demonstrative, and experimental evidence',
          },
        },
      },
      III: {
        label: 'Privileges and other policy exclusions',
        subtopics: {
          A: {
            label: 'Spousal immunity and marital communications',
          },
          B: {label: 'Attorney-client and work product'},
          C: {label: 'Physician/psychotherapist-patient'},
          D: {label: 'Other privileges'},
          E: {label: 'Insurance coverage'},
          F: {label: 'Remedial measures'},
          G: {
            label:
              'Compromise, payment of medical expenses, and plea negotiations',
          },
          H: {label: 'Past sexual conduct of a victim'},
        },
      },
      IV: {
        label: 'Writings, recordings, and photographs',
        subtopics: {
          A: {label: 'Requirement of original'},
          B: {label: 'Summaries'},
          C: {label: 'Completeness rule'},
        },
      },
      V: {
        label: 'Hearsay and circumstances of its admissibility',
        subtopics: {
          A: {
            label: 'Definition of hearsay',
            subsubtopics: {
              1: {label: 'What is hearsay'},
              2: {label: 'Prior statements by witness'},
              3: {label: 'Statements attributable to party-opponent'},
              4: {label: 'Multiple hearsay'},
            },
          },
          B: {
            label: 'Present sense impressions and excited utterances',
          },
          C: {
            label: 'Statements of mental, emotional, or physical condition',
          },
          D: {
            label: 'Statements for purposes of medical diagnosis and treatment',
          },
          E: {label: 'Past recollection recorded'},
          F: {label: 'Business records'},
          G: {label: 'Public records and reports'},
          H: {label: 'Learned treatises'},
          I: {label: 'Former testimony; depositions'},
          J: {label: 'Statements against interest'},
          K: {label: 'Other exceptions to the hearsay rule'},
          L: {label: 'Right to confront witnesses'},
        },
      },
    },
  },
  TRT: {
    label: 'Torts',
    topics: {
      I: {
        label: 'Intentional torts',
        subtopics: {
          A: {
            label:
              'Harms to the person, such as assault, battery, false imprisonment, and infliction of mental distress; and harms to  property interests, such as trespass to land and chattels, and conversion',
          },
          B: {
            label: 'Defenses to claims for physical harms',
            subsubtopics: {
              1: {label: 'Consent '},
              2: {
                label:
                  'Privileges and immunities: protection of self and others; protection of property interests; parental discipline; protection of public interests; necessity; incomplete privilege',
              },
            },
          },
        },
      },
      II: {
        label: 'Negligence',
        subtopics: {
          A: {
            label:
              'The duty question, including failure to act, unforeseeable plaintiffs, and obligations to control the conduct of third parties',
          },
          B: {
            label: 'The standard of care',
            subsubtopics: {
              1: {
                label:
                  'The reasonably prudent person: including children, physically and mentally impaired individuals, professional people, and other special classes ',
              },
              2: {
                label: 'Rules of conduct derived from statutes and custom ',
              },
            },
          },
          C: {
            label:
              'Problems relating to proof of fault, including res ipsa loquitur',
          },
          D: {
            label: 'Problems relating to causation',
            subsubtopics: {
              1: {label: 'But for and substantial causes'},
              2: {label: 'Harms traceable to multiple causes'},
              3: {
                label:
                  'Questions of apportionment of responsibility among multiple tortfeasors, including joint and several liability',
              },
            },
          },
          E: {
            label: 'Limitations on liability and special rules of liability',
            subsubtopics: {
              1: {
                label:
                  'Problems relating to “remote” or “unforeseeable” causes, “legal” or “proximate” cause, and “superseding” causes',
              },
              2: {label: 'Claims against owners and occupiers of land'},
              3: {
                label:
                  'Claims for mental distress not arising from physical harm; other intangible injuries ',
              },
              4: {label: 'Claims for pure economic loss'},
            },
          },
          F: {
            label: 'Liability for acts of others',
            subsubtopics: {
              1: {label: 'Employees and other agents'},
              2: {label: 'Independent contractors and nondelegable duties'},
            },
          },
          G: {
            label: 'Defenses',
            subsubtopics: {
              1: {
                label:
                  'Contributory fault, including common law contributory negligence and last clear chance, and the various forms of comparative negligence',
              },
              2: {label: 'Assumption of risk'},
            },
          },
        },
      },
      III: {
        label: 'Strict liability and products liability',
      },
      IV: {
        label: 'Other torts',
        subtopics: {
          A: {label: 'Claims based on nuisance, and defenses'},
          B: {
            label:
              'Claims based on defamation and invasion of privacy, defenses, and constitutional limitations',
          },
          C: {label: 'Claims based on misrepresentations, and defenses '},
          D: {
            label:
              'Claims based on intentional interference with business relations, and defenses',
          },
        },
      },
    },
  },

  CON: {
    label: 'Constitutional Law',
    topics: {
      I: {
        label: 'The nature of judicial review',
        subtopics: {
          A: {
            label:
              'Organization and relationship of state and federal courts in a federal system',
          },
          B: {
            label: 'Jurisdiction',
            subsubtopics: {
              1: {
                label: 'Congressional power to define and limit',
              },
              2: {
                label: 'The Eleventh Amendment and state sovereign immunity',
              },
            },
          },
          C: {
            label: 'Judicial review in operation',
            subsubtopics: {
              1: {
                label:
                  'The “case or controversy” requirement, including the prohibition on advisory opinions, standing, ripeness, and mootness',
              },
              2: {label: 'The “adequate and independent state ground” '},
              3: {label: 'Political questions and justiciability'},
            },
          },
        },
      },
      II: {
        label: 'The separation of powers',
        subtopics: {
          A: {
            label: 'The powers of Congress',
            subsubtopics: {
              1: {
                label: 'Commerce, taxing, and spending powers',
              },
              2: {
                label: 'War, defense, and foreign affairs powers',
              },
              3: {
                label: 'Power to enforce the 13th, 14th, and 15th Amendments',
              },
              4: {label: 'Other powers'},
            },
          },
          B: {
            label: 'The powers of the president',
            subsubtopics: {
              1: {
                label: 'As chief executive, including the “take care” clause',
              },
              2: {label: 'As commander in chief '},
              3: {label: 'Treaty and foreign affairs powers '},
              4: {label: 'Appointment and removal of officials '},
            },
          },
          C: {
            label: 'Federal interbranch relationships',
            subsubtopics: {
              1: {label: 'Congressional limits on the executive'},
              2: {
                label:
                  'The presentment requirement and the president’s power to veto or to withhold action',
              },
              3: {label: 'Nondelegation doctrine'},
              4: {label: 'Executive, legislative, and judicial immunities'},
            },
          },
        },
      },
      III: {
        label: 'The relation of nation and states in a federal system',
        subtopics: {
          A: {
            label: 'Intergovernmental immunities',
            subsubtopics: {
              1: {label: 'Federal immunity from state law'},
              2: {
                label:
                  'State immunity from federal law, including the 10th Amendment ',
              },
            },
          },
          B: {
            label: 'Federalism-based limits on state authority',
            subsubtopics: {
              1: {label: 'Negative implications of the commerce clause'},
              2: {label: 'Supremacy clause and preemption '},
              3: {label: 'Authorization of otherwise invalid state action '},
            },
          },
        },
      },
      IV: {
        label: 'Individual rights',
        subtopics: {
          A: {
            label: 'State action',
          },
          B: {
            label: 'Due process',
            subsubtopics: {
              1: {
                label: 'Substantive due process',
                subsubsubtopics: {
                  a: {label: 'Fundamental rights '},
                  b: {label: 'Other rights and interests '},
                },
              },
              2: {
                label: 'Procedural due process',
              },
            },
          },
          C: {
            label: 'Equal protection',
            subsubtopics: {
              1: {label: 'Fundamental rights '},
              2: {label: 'Classifications subject to heightened scrutiny '},
              3: {label: 'Rational basis review '},
            },
          },
          D: {label: 'Takings'},
          E: {
            label:
              'Other protections, including the privileges and immunities clauses, the contracts clause, unconstitutional conditions, bills of attainder, and ex post facto laws',
          },
          F: {
            label: 'First Amendment freedoms',
            subsubtopics: {
              1: {
                label: 'Freedom of religion and separation of church and state',
                subsubsubtopics: {
                  a: {label: 'Free exercise'},
                  b: {label: 'Establishment '},
                },
              },
              2: {
                label: 'Freedom of expression',
                subsubsubtopics: {
                  a: {
                    label: 'Content-based regulation of protected expression ',
                  },
                  b: {
                    label: 'Content-neutral regulation of protected expression',
                  },
                  c: {label: 'Regulation of unprotected expression '},
                  d: {label: 'Regulation of commercial speech'},
                  e: {
                    label:
                      'Regulation of, or impositions upon, public school students, public employment, licenses, or benefits based upon exercise of expressive or associational rights',
                  },
                  f: {label: 'Regulation of expressive conduct'},
                  g: {label: 'Prior restraint, vagueness, and overbreadth'},
                },
              },
              3: {
                label: 'Freedom of the press',
              },
              4: {
                label: 'Freedom of association',
              },
            },
          },
        },
      },
    },
  },
  KSL: {
    label: 'Contracts',
    topics: {
      I: {
        label: 'Formation of contracts',
        subtopics: {
          A: {
            label:
              'Mutual assent (including offer and acceptance, and unilateral, bilateral, and implied-in-fact contracts)',
          },
          B: {label: 'Indefiniteness and absence of terms'},
          C: {label: 'Consideration (bargained-for exchange)'},
          D: {
            label:
              'Obligations enforceable without a bargained-for exchange (including reliance and restitution)',
          },
          E: {label: 'Modification of contracts'},
        },
      },
      II: {
        label: 'Defenses to enforceability',
        subtopics: {
          A: {label: 'Incapacity to contract'},
          B: {label: 'Duress and undue influence'},
          C: {label: 'Mistake and misunderstanding'},
          D: {label: 'Fraud, misrepresentation, and nondisclosure'},
          E: {label: 'Illegality, unconscionability, and public policy'},
          F: {label: 'Statute of frauds'},
        },
      },
      III: {
        label: 'Contract content and meaning',
        subtopics: {
          A: {label: 'Parol evidence'},
          B: {label: 'Interpretation'},
          C: {label: 'Omitted and implied terms'},
        },
      },
      IV: {
        label: 'Performance, breach, and discharge',
        subtopics: {
          A: {label: 'Conditions (express and constructive)'},
          B: {label: 'Excuse of conditions'},
          C: {
            label:
              'Breach (including material and partial breach, and anticipatory repudiation)',
          },
          D: {label: 'Obligations of good faith and fair dealing'},
          E: {
            label: 'Express and implied warranties in sale-of-goods contracts',
          },
          F: {
            label:
              'Other performance matters (including cure, identification, notice, and risk of loss)',
          },
          G: {
            label:
              'Impossibility, impracticability, and frustration of purpose',
          },
          H: {
            label:
              'Discharge of duties (including accord and satisfaction, substituted contract, novation, rescission, and release)',
          },
        },
      },
      V: {
        label: 'Remedies',
        subtopics: {
          A: {
            label:
              'Expectation interest (including direct, incidental, and consequential damages)',
          },
          B: {label: 'Causation, certainty, and foreseeability'},
          C: {
            label:
              'Liquidated damages and penalties, and limitation of remedies',
          },
          D: {label: 'Avoidable consequences and mitigation of damages'},
          E: {label: 'Rescission and reformation '},
          F: {label: 'Specific performance and injunction '},
          G: {label: 'Reliance and restitution interests'},
          H: {label: 'Remedial rights of breaching parties'},
        },
      },
      VI: {
        label: 'Third-party rights',
        subtopics: {
          A: {label: 'Third-party beneficiaries '},
          B: {label: 'Assignment of rights and delegation of duties'},
        },
      },
    },
  },
  RPL: {
    label: 'Real Property',
    topics: {
      I: {
        label: 'Ownership of real property',
        subtopics: {
          A: {
            label: 'Present estates and future interests',
            subsubtopics: {
              1: {
                label: 'Present estates',
                subsubsubtopics: {
                  a: {label: 'Fees simple '},
                  b: {label: 'Defeasible fees'},
                  c: {label: 'Life estates'},
                },
              },
              2: {
                label: 'Future interests ',
                subsubsubtopics: {
                  a: {label: 'Reversions '},
                  b: {label: 'Remainders, vested and contingent '},
                  c: {label: 'Executory interests '},
                  d: {
                    label: 'Possibilities of reverter, powers of termination ',
                  },
                  e: {
                    label:
                      'Rules affecting these interests (including survivorship, class gifts, waste, and cy pres) ',
                  },
                },
              },
            },
          },
          B: {
            label: 'Cotenancy',
            subsubtopics: {
              1: {label: 'Types: tenancy in common and joint tenancy'},
              2: {
                label: 'Rights and obligations of cotenants',
                subsubsubtopics: {
                  a: {label: 'Partition'},
                  b: {label: 'Severance'},
                  c: {label: 'Relations among cotenants '},
                },
              },
            },
          },
          C: {
            label: 'Landlord-tenant law',
            subsubtopics: {
              1: {label: 'Types of tenancies'},
              2: {label: 'Possession and rent'},
              3: {label: 'Transfers by landlord or tenant'},
              4: {
                label:
                  'Termination (including surrender, mitigation of damages, anticipatory breach, and security deposits)',
              },
              5: {label: 'Habitability and suitability '},
            },
          },
          D: {
            label: 'Special problems',
            subsubtopics: {
              1: {
                label:
                  'Rule against perpetuities: common law rule and statutory reforms',
              },
              2: {
                label:
                  'Alienability, descendibility, and devisability of present and future interests',
              },
              3: {label: 'Fair housing/discrimination'},
              4: {
                label:
                  'Conflicts of law related to disputes involving real property',
              },
            },
          },
        },
      },
      II: {
        label: 'Rights in real property',
        subtopics: {
          A: {
            label: 'Restrictive covenants',
            subsubtopics: {
              1: {label: 'Nature and type'},
              2: {label: 'Creation'},
              3: {label: 'Scope'},
              4: {label: 'Transfer'},
              5: {label: 'Termination'},
              6: {
                label:
                  'Property owners’ associations and common interest ownership communities ',
              },
            },
          },
          B: {
            label: 'Easements, profits, and licenses',
            subsubtopics: {
              1: {
                label: 'Nature and type ',
              },
              2: {
                label: 'Methods of creation',
                subsubsubtopics: {
                  a: {label: 'Express '},
                  b: {label: 'Implied '},
                  c: {label: 'Prescription '},
                },
              },
              3: {
                label: 'Scope and apportionment',
              },
              4: {
                label: 'Transfer',
              },
              5: {
                label: 'Termination',
              },
            },
          },
          C: {
            label: 'Fixtures ',
          },
          D: {
            label: 'Zoning (fundamentals other than regulatory taking)',
            subsubtopics: {
              1: {label: 'Zoning laws'},
              2: {label: 'Protection of pre-existing property rights'},
              3: {label: 'Rezoning and other zoning changes'},
            },
          },
        },
      },
      III: {
        label: 'Real estate contracts',
        subtopics: {
          A: {
            label: 'Real estate brokerage',
          },
          B: {
            label: 'Creation and construction',
            subsubtopics: {
              1: {label: 'Statute of frauds and exceptions '},
              2: {label: 'Essential terms'},
              3: {label: 'Time for performance'},
              4: {label: 'Remedies for breach'},
            },
          },
          C: {
            label: 'Marketability of title',
          },
          D: {
            label: 'Equitable conversion (including risk of loss)',
          },
          E: {
            label: 'Options and rights of first refusal',
          },
          F: {
            label: 'Fitness and suitability',
          },
          G: {
            label: 'Merger',
          },
        },
      },
      IV: {
        label: 'Mortgages/security devices ',
        subtopics: {
          A: {
            label: 'Types of security devices',
            subsubtopics: {
              1: {
                label: 'Mortgages (including deeds of trust)',
                subsubsubtopics: {
                  a: {label: 'In general '},
                  b: {label: 'Purchase money mortgages '},
                  c: {label: 'Future advance mortgages '},
                },
              },
              2: {label: 'Installment land contracts '},
              3: {label: 'Absolute deeds as security '},
            },
          },
          B: {
            label: 'Security relationships',
            subsubtopics: {
              1: {
                label: 'Necessity and nature of obligation ',
              },
              2: {
                label: 'Mortgage theories: title, lien, and intermediate ',
              },
              3: {
                label: 'Rights and duties prior to foreclosure ',
              },
              4: {
                label: 'Right to redeem and clogging the equity of redemption ',
              },
            },
          },
          C: {
            label: 'Transfers',
            subsubtopics: {
              1: {
                label: 'By mortgagor',
                subsubsubtopics: {
                  a: {label: 'Assumption and transfer subject to'},
                  b: {label: 'Rights and obligations'},
                  c: {
                    label:
                      'Application of subrogation and suretyship principles',
                  },
                  d: {
                    label:
                      'Restrictions on transfer (including due-on-sale clauses)',
                  },
                },
              },
              2: {
                label: 'By mortgagee ',
              },
            },
          },
          D: {
            label: 'Discharge of the mortgage',
            subsubtopics: {
              1: {
                label: 'Payment (including prepayment)',
              },
              2: {
                label: 'Deed in lieu of foreclosure ',
              },
            },
          },
          E: {
            label: 'Foreclosure',
            subsubtopics: {
              1: {
                label: 'Types',
              },
              2: {
                label: 'Acceleration',
              },
              3: {
                label: 'Parties to the proceeding ',
              },
              4: {
                label: 'Deficiency and surplus ',
              },
              5: {
                label: 'Redemption after foreclosure',
              },
            },
          },
        },
      },
      V: {
        label: 'Titles',
        subtopics: {
          A: {
            label: 'Adverse possession',
          },
          B: {
            label: 'Transfer by deed',
            subsubtopics: {
              1: {
                label: 'Requirements for deed',
              },
              2: {
                label: 'Types of deeds (including covenants for title)',
              },
              3: {
                label: 'Drafting, review, and negotiation of closing documents',
              },
              4: {
                label: 'Persons authorized to execute documents',
              },
            },
          },
          C: {
            label: 'Transfer by operation of law and by will',
            subsubtopics: {
              1: {label: 'In general'},
              2: {label: 'Ademption'},
              3: {label: 'Exoneration'},
              4: {label: 'Lapse'},
            },
          },
          D: {
            label: 'Title assurance systems',
            subsubtopics: {
              1: {
                label: 'Recording acts',
                subsubsubtopics: {
                  a: {label: 'Types'},
                  b: {label: 'Indexes'},
                  c: {label: 'Chain of title'},
                  d: {
                    label: 'Hidden risks (e.g., undelivered or forged deed)',
                  },
                },
              },
              2: {
                label: 'Title insurance ',
              },
            },
          },
          E: {
            label:
              'Special problems (including estoppel by deed and judgment and tax liens)',
          },
        },
      },
    },
  },

  CRP: {
    label: 'Criminal Procedure',
    topics: {
      V: {
        label: 'Constitutional protections of accused persons',
        subtopics: {
          A: {label: 'Arrest, search and seizure'},
          B: {label: 'Confessions and privilege against self-incrimination'},
          C: {label: 'Lineups and other forms of identification'},
          D: {label: 'Right to counsel'},
          E: {label: 'Fair trial and guilty pleas'},
          F: {label: 'Double jeopardy'},
          G: {label: 'Cruel and unusual punishment'},
          H: {label: 'Burdens of proof and persuasion'},
          I: {label: 'Appeal and error'},
        },
      },
    },
  },
  BO: {
    label: "Business Organizations",
    topics: {
      I: {
        label: "Agency relationships",
        subtopics: {
          A: {label: "Creation"},
          B: {label: "Types"},
          C: {label: "Termination"},
        },
      },
      II: {
        label: "Power of agent to bind principal",
        subtopics: {
          A: {
            label: "Authority",
          },
          B: {
            label: "Apparent authority",
          },
          C: {
            label: "Inherent agency power",
          },
        },
      },
      III: {
        label: "Vicarious liability of principal for acts of agent",
      },
      IV: {
        label: "Fiduciary duties between principal and agent",
        subtopics: {
          A: {
            label: "Duty of care",
          },
          B: {
            label: "Duty of loyalty",
          },
          C: {
            label: "Duty of obedience",
          },
          D: {
            label: "Limited partnerships",
          },
          E: {label: "Limited liability partnerships"},
        },
      },
      V: {
        label: "Creation of partnerships",
        subtopics: {
          A: {
            label: "General partnerships",
          },
          B: {
            label: "Limited partnerships",
          },
          C: {
            label: "Limited liability partnerships",
          },
        },
      },
      VI: {
        label: "Power and liability of partners",
      },
      VII: {
        label: "Rights of partners among themselves",
        subtopics: {
          A: {
            label: "Profits and losses",
          },
          B: {
            label: "Management and control",
          },
          C: {
            label: "Duty of care",
          },
          D: {
            label: "Duty of loyalty",
          },
        },
      },
      VIII: {
        label: "Dissolution",
        subtopics: {
          A: {
            label: "Distinguished from winding up and termination",
          },
          B: {
            label: "Rightful versus wrongful",
          },
          C: {
            label:
              "General partnerships, limited partnerships, or limited liability partnerships",
          },
        },
      },
      IX: {
        label: "Special rules concerning limited partnerships",
        subtopics: {
          A: {
            label: "Disclosure requirements",
          },
          B: {
            label: "The control limitation",
          },
          C: {
            label: "Economic rights of limited partners",
          },
        },
      },
      X: {
        label: "Formation of organizations",
        subtopics: {
          A: {
            label: "Articles of incorporation",
          },
          B: {
            label: "Bylaws",
          },
          C: {
            label: "Articles of organization; certificates of formation",
          },
          D: {
            label: "Operating agreements",
          },
        },
      },
      XI: {
        label: "Pre-organization transactions",
        subtopics: {
          A: {
            label: "Promoters: contracts and fiduciary duties",
          },
          B: {
            label: "Subscriptions for shares",
          },
        },
      },
      XII: {
        label: "Piercing the veil",
      },
      XIII: {
        label: "Financing the organization",
        subtopics: {
          A: {
            label: "Sources of finance",
          },
          B: {
            label: "Securities issuance and characteristics",
          },
          C: {
            label: "Dividends and distributions",
          },
          D: {
            label: "Redemptions and repurchases",
          },
        },
      },
      XIV: {
        label: "Management and control",
        subtopics: {
          A: {
            label: "Shareholders",
            subsubtopics: {
              1: {
                label: "Meetings: annual, notice, and quorum",
              },
              2: {
                label:
                  "Voting: eligibility, cumulative voting, proxy voting, class voting, voting trusts, and shareholder voting agreements",
              },
            },
          },
          B: {
            label: "Directors",
            subsubtopics: {
              1: {
                label: "Meetings: quorum and notice",
              },
              2: {
                label: "Action by written consent",
              },
              3: {
                label: "Action by committee",
              },
              4: {
                label: "Director’s objections to actions",
              },
            },
          },
          C: {
            label: "Officers",
            subsubtopics: {
              1: {
                label: "Authority",
              },
              2: {
                label: "Officer’s liability on corporate obligations",
              },
            },
          },
          D: {
            label: "Members and managers",
            subsubtopics: {
              1: {
                label: "Authority",
              },
              2: {
                label: "Liability",
              },
              3: {
                label: "Powers",
              },
            },
          },
        },
      },
      XV: {
        label: "Fiduciary duties",
        subtopics: {
          A: {
            label: "Directors, officers, and shareholders",
          },
          B: {
            label: "Managers and members",
          },
        },
      },
      XVI: {
        label: "Close corporations and special control devices",
        subtopics: {
          A: {
            label: "Share transfer restrictions",
          },
          B: {
            label: "Special agreements allocating authority",
          },
          C: {
            label: "Resolutions of disputes and deadlocks",
          },
          D: {
            label: "Option or buy/sell agreements",
          },
        },
      },
      XVII: {
        label:
          "Organizational structure including relationships between parents and  subsidiaries",
        subtopics: {
          A: {
            label: "Amendments",
            subsubtopics: {
              1: {
                label: "Articles of incorporation and bylaws",
              },
              2: {
                label:
                  "Articles of organization, certificates of formation, and operating agreements",
              },
            },
          },
          B: {
            label: "Mergers and consolidations",
          },
          C: {
            label: "Sales of substantially all assets",
          },
          D: {
            label: "Recapitalizations",
          },
          E: {
            label: "Exchanges of securities",
          },
          F: {
            label: "Dissolution of organization",
          },
        },
      },
      XVIII: {
        label:
          "Shareholder and member litigation: direct, derivative, and class litigation",
      },
    },
  },
  ST: {
    label: "Secured Transactions",
    topics: {
      I: {
        label: "General UCC principles",
        subtopics: {
          A: {label: "Rules of construction and application"},
          B: {label: "General definitions and principles of interpretation"},
          C: {label: "General Rules"},
        },
      },
      II: {
        label: "Applicability and definitions",
        subtopics: {
          A: {label: "Subject matter of Article 9"},
          B: {
            label:
              "Perfection of security interests in multiple state transactions",
          },
          C: {label: "Excluded transactions"},
          D: {
            label:
              "Definitions: “account”; “purchase money security interest”; “control”",
          },
          E: {
            label: "Classification of goods",
          },
          F: {
            label: "Including sufficiency of description",
          },
          G: {
            label: "Including security interests arising under Article 2",
          },
          H: {
            label: "Priority of consignments",
          },
        },
      },
      III: {
        label: "Validity of security agreements and rights of parties",
        subtopics: {
          A: {label: "Title to collateral immaterial"},
          B: {
            label: "Enforceability",
          },
          C: {label: "After-acquired property; future advances"},
          D: {
            label: "Use or disposition of collateral by debtor",
          },
          E: {
            label: "Collateral in secured party’s possession",
          },
          F: {
            label: "Request for accounting",
          },
        },
      },
      IV: {
        label:
          "Rights of third parties; perfected and unperfected security interests; rules of priority",
        subtopics: {
          A: {label: "Priority over unperfected security interests"},
          B: {
            label:
              "Requirement of filing and steps to be taken for perfection, assignment of security interest",
          },
          C: {
            label:
              "Protection of buyers of goods and chattel paper, including protection of holders and purchasers of negotiable instruments",
          },
          D: {
            label: "Priority of liens arising by law",
          },
          E: {
            label: "Alienability of debtor’s rights",
          },
          F: {
            label: "Priority among conflicting security interests",
          },
          G: {
            label: "Fixtures",
          },
          H: {
            label: "Accessions; commingling",
          },
          I: {
            label: "Subordination",
          },
          J: {
            label: "Defenses against assignee; modification of contract",
          },
          K: {
            label: "Termination statement; release of collateral",
          },
        },
      },
      V: {
        label: "Default",
        subtopics: {
          A: {
            label: "Rights and remedies on default",
            subsubtopics: {
              1: {
                label: "Including secured party’s collection rights",
              },
              2: {
                label:
                  "Including secured party’s right to take possession and dispose of collateral",
              },
            },
          },
          B: {
            label: "Debtor’s rights",
          },
        },
      },
    },
  },
  WT: {
    label: "Wills and Trusts",
    topics: {
      I: {
        label: "Intestate succession",
        subtopics: {
          A: {label: "Share of the surviving spouse"},
          B: {
            label: "Share of children and more remote descendants",
            subsubtopics: {
              1: {label: "Adopted children"},
              2: {
                label: "Children born out of wedlock",
              },
              3: {label: "Half-bloods"},
            },
          },
          C: {label: "Share of ancestors and collaterals"},
          D: {label: "Advancements"},
          E: {label: "Simultaneous death"},
        },
      },
      II: {
        label: "Wills",
        subtopics: {
          A: {
            label: "Execution requirements",
            subsubtopics: {
              1: {label: "Governing law"},
              2: {
                label: "Wills complying with law of domicile",
              },
              3: {label: "Foreign wills"},
              4: {label: "Holographic wills"},
              5: {label: "Interested witnesses"},
            },
          },
          B: {
            label: "Integration of wills",
          },
          C: {label: "Codicils"},
          D: {
            label: "Incorporation by reference",
          },
          E: {
            label: "Facts of independent significance",
          },
          F: {
            label: "Revocation",
            subsubtopics: {
              1: {label: "Dependent relative revocation"},
              2: {
                label: "Revocation due to changed circumstances",
              },
              3: {label: "Revocation by physical act"},
              4: {label: "Partial revocation"},
            },
          },
          G: {
            label: "Revival",
          },
          H: {
            label: "Contractual wills",
          },
          I: {
            label: "Construction problems",
            subsubtopics: {
              1: {label: "Lapsed legacies"},
              2: {
                label: "Ademption",
              },
              3: {label: "Accretions"},
              4: {label: "Satisfaction"},
              5: {label: "Exoneration"},
              6: {label: "Slayer statutes"},
              7: {label: "Disclaimers"},
              8: {label: "Simultaneous death"},
              9: {label: "Abatement"},
              10: {label: "Classification of legacies and devises"},
              11: {label: "Gifts to classes"},
              12: {label: "Gifts to children and issue"},
            },
          },
          J: {
            label: "Will contests",
            subsubtopics: {
              1: {label: "Age requirement"},
              2: {
                label: "Mental capacity",
              },
              3: {label: "Undue influence"},
              4: {label: "Fraud"},
              5: {label: "Mistake"},
              6: {label: "No-contest clauses"},
              7: {label: "Standing to contest"},
            },
          },
          K: {
            label: "Nonprobate transfers",
            subsubtopics: {
              1: {label: "Inter vivos gifts"},
              2: {
                label: "Joint tenancy",
              },
              3: {label: "Tentative trusts and pay-on-death accounts"},
              4: {label: "Other nonprobate transfers"},
            },
          },
          L: {
            label: "Powers and duties of personal representative",
          },
        },
      },
      III: {
        label: "Family protection",
        subtopics: {
          A: {
            label: "Spouse’s forced or elective shares",
            subsubtopics: {
              1: {label: "Size"},
              2: {
                label: "Assets subject to share",
              },
            },
          },
          B: {
            label: "Share of after-born or pretermitted child",
          },
        },
      },
      IV: {
        label: "Living wills and durable health care powers",
        subtopics: {
          A: {label: "Execution requirements"},
          B: {
            label: "Revocation",
          },
          C: {
            label: "Individuals eligible to be agent or attorney-in-fact",
          },
          D: {
            label: "Authority of agent or attorney-in-fact",
          },
        },
      },
      V: {
        label: "Trusts",
        subtopics: {
          A: {
            label: "Classification",
          },
          B: {
            label: "Creation",
            subsubtopics: {
              1: {
                label: "Requirement of trust res",
              },
              2: {
                label: "Requirement of beneficiary",
              },
              3: {
                label: "Requirement of trustee",
              },
            },
          },
          C: {
            label: "Types of trusts",
            subsubtopics: {
              1: {
                label: "Revocable",
              },
              2: {
                label: "Irrevocable",
              },
              3: {
                label: "Testamentary",
              },
              4: {
                label: "Pourover",
              },
              5: {
                label: "Charitable",
              },
            },
          },
          D: {
            label: "Alienability of trust interests",
          },
          E: {
            label: "Protective trusts",
            subsubtopics: {
              1: {
                label: "Discretionary trusts",
              },
              2: {
                label: "Support trusts",
              },
              3: {
                label: "Spendthrift trusts",
              },
            },
          },
          F: {
            label: "Powers of invasion",
          },
          G: {
            label: "Modification",
          },
          H: {
            label: "Termination",
          },
          I: {
            label: "Power and duties of trustees",
            subsubtopics: {
              1: {
                label: "Prudent person rule",
              },
              2: {
                label: "Duty of care",
              },
              3: {
                label: "Duty of loyalty",
              },
              4: {
                label: "Duty to act impartially",
              },
              5: {
                label: "Principal and income allocations",
              },
            },
          },
        },
      },
      VI: {
        label: "Future interests",
        subtopics: {
          A: {
            label:
              "Classification of reversions, remainders, and executory interests",
          },
          B: {
            label: "Life estates and terms of years",
          },
          C: {
            label: "Vested and contingent interests",
          },
          D: {
            label: "Powers of appointment",
          },
          E: {
            label: "Acceleration of future interests",
          },
          F: {
            label: "Rule against perpetuities",
            subsubtopics: {
              1: {
                label: "Common law",
              },
              2: {
                label: "Wait-and-see",
              },
              3: {
                label: "Cy pres",
              },
              4: {
                label: "Other reforms",
              },
            },
          },
        },
      },
      VII: {
        label: "Construction problems",
        subtopics: {
          A: {
            label: "Survivorship problems",
          },
          B: {
            label: "Gifts to classes",
          },
          C: {
            label: "Gifts to heirs",
          },
          D: {
            label: "Doctrine of Worthier Title",
          },
          E: {
            label: "Gifts to children and issue",
            subsubtopics: {
              1: {
                label: "Adopted children",
              },
              2: {
                label: "Children born out of wedlock",
              },
            },
          },
          F: {
            label: "Death without issue",
          },
          G: {
            label: "Gifts by implication",
          },
        },
      },
    },
  },
  PR: {
    label: "Professional Responsibility",
    topics: {
      I: {
        label: "Regulation of the legal profession",
        subtopics: {
          A: {label: "Powers of courts and other bodies to regulate lawyers"},
          B: {label: "Admission to the profession",},
          C: {label: "Regulation after admission—lawyer discipline"},
          D: {label: "Mandatory and permissive reporting of professional misconduct"},
          E: {label: "Unauthorized practice of law—by lawyers and nonlawyers"},
          F: {label: "Multijurisdictional practice"},
          G: {label: "Fee division with a nonlawyer"},
          H: {label: "Law firm and other forms of practice"},
          I: {label: "Responsibilities of partners, managers, supervisory and subordinate lawyers"},
          J: {label: "Restrictions on right to practice"},
        },
      },
      II: {
        label: "The client-lawyer relationship",
        subtopics: {
          A: {
            label: "Formation of client-lawyer relationship",
          },
          B: {
            label: "Scope, objective, and means of the representation",
          },
          C: {label: "Decision-making authority—actual and apparent"},
          D: {
            label: "Counsel and assistance within the bounds of the law",
          },
          E: {
            label: "Termination of the client-lawyer relationship",
          },
          F: {
            label: "Client-lawyer contracts",
          },
          G: {
            label: "Communications with the client",
          },
          H: {
            label: "Fees",
          },
        },
      },
      III: {
        label: "Client confidentiality",
        subtopics: {
          A: {
            label: "Attorney-client privilege",
          },
          B: {
            label: "Work-product doctrine",
          },
          C: {
            label: "Professional obligation of confidentiality—general rule",
          },
          D: {
            label: "Disclosures expressly or impliedly authorized by client",
          },
          E: {
            label: "Other exceptions to the confidentiality rule",
          },
        },
      },
      IV: {
        label: "Conflicts of interest",
        subtopics: {
          A: {label: "Current client conflicts—multiple clients and joint representation"},
          B: {
            label: "Current client conflicts—lawyer’s personal interest or duties",
          },
          C: {
            label: "Former client conflicts",
          },
          D: {
            label: "Prospective client conflicts",
          },
          E: {
            label: "Imputed conflicts",
          },
          F: {
            label: "Acquiring an interest in litigation",
          },
          G: {
            label: "Business transactions with clients",
          },
          H: {
            label: "Third-party compensation and influence",
          },
          I: {
            label: "Lawyers currently or formerly in government service",
          },
          J: {
            label: "Former judge, arbitrator, mediator, or other third-party neutral",
          },
        },
      },
      V: {
        label: "Competence, legal malpractice, and other civil liability",
        subtopics: {
          A: {
            label: "Maintaining competence",
          },
          B: {
            label: "Competence necessary to undertake representation",
          },
          C: {
            label: "Exercising diligence and care",
          },
          D: {
            label: "Civil liability to client, including malpractice",
          },
          E: {
            label: "Civil liability to nonclients",
          },
          F: {
            label: "Limiting liability for malpractice",
          },
          G: {
            label: "Malpractice insurance and risk prevention",
          },
        },
      },
      VI: {
        label: "Litigation and other forms of advocacy",
        subtopics: {
          A: {
            label:
              "Meritorious claims and contentions",
          },
          B: {
            label: "Expediting litigation",
          },
          C: {
            label: "Candor to the tribunal",
          },
          D: {
            label: "Fairness to opposing party and counsel",
          },
          E: {
            label: "Impartiality and decorum of the tribunal",
          },
          F: {
            label: "Trial publicity",
          },
          G: {
            label: "Lawyer as witness",
          },
        },
      },
      VII: {
        label: "Transactions and communications with persons other than clients",
        subtopics: {
          A: {
            label: "Truthfulness in statements to others",
          },
          B: {
            label: "Communications with represented persons",
          },
          C: {
            label: "Communications with unrepresented persons",
          },
          D: {
            label: "Respect for rights of third persons",
          },
        },
      },
      VIII: {
        label: "Different roles of the lawyer",
        subtopics: {
          A: {
            label: "Lawyer as advisor",
          },
          B: {
            label: "Lawyer as evaluator",
          },
          C: {
            label: "Lawyer as negotiator",
          },
          D: {
            label: "Lawyer as arbitrator, mediator, or other third-party neutral",
          },
          E: {
            label: "Prosecutors and other government lawyers",
          },
          F: {
            label: "Lawyer appearing in nonadjudicative proceeding",
          },
          G: {
            label: "Lawyer representing an entity or other organization",
          },
        },
      },
      IX: {
        label: "Safekeeping funds and other property",
        subtopics: {
          A: {
            label: "Establishing and maintaining client trust accounts",
          },
          B: {
            label: "Safekeeping funds and other property of clients",
          },
          C: {
            label: "Safekeeping funds and other property of third persons",
          },
          D: {
            label: "Disputed claims",
          },
        },
      },
      X: {
        label: "Communications about legal services",
        subtopics: {
          A: {
            label: "Advertising and other public communications about legal services",
          },
          B: {
            label: "Solicitation—direct contact with prospective clients",
          },
          C: {
            label: "Group legal services",
          },
          D: {
            label: "Referrals",
          },
          E: {
            label: "Communications regarding fields of practice and specialization",
          },
        },
      },
      XI: {
        label: "Lawyers’ duties to the public and the legal system",
        subtopics: {
          A: {
            label: "Voluntary pro bono service",
          },
          B: {
            label: "Accepting appointments",
          },
          C: {
            label: "Serving in legal services organizations",
          },
          D: {
            label: "Law reform activities affecting client interests",
          },
          E: {
            label: "Criticism of judges and adjudicating officials",
          },
          F: {
            label: "Political contributions to obtain engagements or appointments",
          },
          G: {
            label: "Improper influence on government officials",
          },
          H: {
            label: "Assisting judicial misconduct",
          },
        },
      },
      XII: {
        label: "Judicial conduct",
        subtopics: {
          A: {
            label: "Maintaining the independence and impartiality of the judiciary",
          },
          B: {
            label: "Performing the duties of judicial office impartially, competently, and diligently",
          },
          C: {
            label: "Ex parte communications",
          },
          D: {
            label: "Disqualification",
          },
          E: {
            label: "Extrajudicial activities",
          },
        },
      },
    },
  },
};
export default subjectTopics;
