// @ts-nocheck
import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ITEM_HEIGHT = 48;

type TMoreMenu = {
  // items: string[] | {
  //   label: string,
  //   value: string,
  // }[],
  items: any[],
  onMenuAction: (e: any, value: string) => void,
}

const MoreMenu: React.FC<TMoreMenu> = ({
  items,
  onMenuAction,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCLose = () => {
    setAnchorEl(null);
  };

  const onMenuClick = (value: string) => (e: any) => {
    onCLose();
    onMenuAction(e, value);
  }

  const open = !!anchorEl;

  return (
    <div {...props}>
      <IconButton
        aria-label="More"
        aria-owns={open ? 'long-menu' : undefined}
        aria-haspopup="true"
        onClick={onOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onCLose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {
          Array.isArray(items) && items.map((item) => {
            const value = typeof item === 'string' ? item : item.value;
            const label = typeof item === 'string' ? item : item.label;

            return (
              <MenuItem key={value} onClick={onMenuClick(value)}>
                {label}
              </MenuItem>
            )
          })
        }
      </Menu>
    </div>
  )
}

export default MoreMenu;
